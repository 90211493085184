<table mat-table [dataSource]="rows" matSort (matSortChange)="onSortChange($event)">
  <ng-container matColumnDef="InvoiceNumber">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>FSPS Invoice Number</th>
    <td mat-cell *matCellDef="let row">
      {{row.InvoiceNumber}}
    </td>
  </ng-container>
  <ng-container matColumnDef="InvoiceYear">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Invoice Year</th>
    <td mat-cell *matCellDef="let row">
      {{row.InvoiceYear}}
    </td>
  </ng-container>
  <ng-container matColumnDef="VendorInvoiceDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Invoice Date</th>
    <td mat-cell *matCellDef="let row">
      {{row.VendorInvoiceDate | date:'d-LLL-y'}}
    </td>
  </ng-container>
  <ng-container matColumnDef="PurchaseOrder">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>PO Number</th>
    <td mat-cell *matCellDef="let row">
      {{row.PurchaseOrder}}
    </td>
  </ng-container>
  <ng-container matColumnDef="PurchaseRequest">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>PR Number</th>
    <td mat-cell *matCellDef="let row">
      {{row.PurchaseRequest}}
    </td>
  </ng-container>
  <ng-container matColumnDef="count">
    <th mat-header-cell *matHeaderCellDef>Trials/Non-trials</th>
    <td mat-cell *matCellDef="let row">
      {{ row.InvoiceTrialsCount ? row.InvoiceTrialsCount + " Trials" : null }}
      <br *ngIf="row.TrialCount && row.NontrialCount">
      {{ row.InvoiceNonTrialsCount ? row.InvoiceNonTrialsCount + " Non-Trials" : null }}
    </td>
  </ng-container>
  <ng-container matColumnDef="TotalInvoiced">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Invoiced</th>
    <td mat-cell *matCellDef="let row">
      {{row.TotalInvoiced | number}} {{row.Currency}}
    </td>
  </ng-container>
  <ng-container matColumnDef="InvoiceStatus">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
    <td mat-cell *matCellDef="let row">
      {{GetInvoiceStatusDescription(row.InvoiceStatus)}}
    </td>
  </ng-container>
  <ng-container matColumnDef="CheckNumber">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Check Number</th>
    <td mat-cell *matCellDef="let row">
      {{row.CheckNumber}}
    </td>
  </ng-container>
  <ng-container matColumnDef="PaidDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Paid</th>
    <td mat-cell *matCellDef="let row">
      {{row.PaidDate | date:'d-LLL-y'}}
    </td>
  </ng-container>
  <ng-container matColumnDef="VendorName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Vendor Name</th>
    <td mat-cell *matCellDef="let row">
      {{row.VendorName}}
    </td>
  </ng-container>
  <ng-container matColumnDef="VendorCode">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Vendor Code</th>
    <td mat-cell *matCellDef="let row">
      {{row.VendorCode}}
    </td>
  </ng-container>
  <ng-container matColumnDef="VendorCountry">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Vendor Country</th>
    <td mat-cell *matCellDef="let row">
      {{row.VendorCountry}}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="Columns; sticky: true"></tr>
  <tr mat-row (dblclick)="doubleClick(row)"
      (click)="this.selection.toggle(row)"
      [class.selected]="selection.isSelected(row)"
      *matRowDef="let row; columns: Columns"></tr>
</table>
