
<mat-card class="search-criteria-margin">
  <mat-card-header class="mat-card-header-has-actions">
    <mat-card-title style="display: flex">
      <span class="card-header-text">Search Criteria</span>
      <span class="card-header-actions">
        <button mat-icon-button mat-raised-button [matMenuTriggerFor]="addFilterMenu"><mat-icon>playlist_add</mat-icon></button>
        <mat-menu #addFilterMenu="matMenu" class="mat-menu-chip-menu">
          <div *ngIf="areAllFiltersApplied()" style="margin: 5px;">
            <em>
              All filterable properties have been applied.
              Either edit the ones you already have selected, or remove some.
            </em>
          </div>
          <mat-chip-list *ngIf="!areAllFiltersApplied()">
            <ng-container *ngFor="let filterCategory of filterCategories">
              <ng-container *ngIf="doesCategoryHaveAvailableFilters(filterCategory)">
                <mat-hint style="width: 100%; font-size: 75%; margin-left: 6px; margin-right: 4px">{{filterCategory.categoryName}}</mat-hint>
                <mat-divider style="padding-bottom: 5px; width: 100%; margin-left: 4px; margin-right: 4px"></mat-divider>
                <mat-chip class="mat-chip-mini" *ngFor="let filter of filterCategory.filters" [disabled]="filter.disabled" [style.display]="isFilterInUse(filter) ? 'none' : 'initial'" (click)="addFilter(filter)">{{filter.name}}</mat-chip>
              </ng-container>
            </ng-container>
          </mat-chip-list>
        </mat-menu>
      </span>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div *ngIf="selectedFilters == null || selectedFilters.length == 0">
      <em>You have no filters applied. Select the <mat-icon class="mat-icon-inline-text">playlist_add</mat-icon> button above to add some.</em>
    </div>

    <div *ngFor="let selectedFilter of selectedFilters">
      <mat-form-field *ngIf="selectedFilter.filter.inputType !== 'dateRange'">
        <mat-label>{{ selectedFilter.filter.name }}</mat-label>

        <input matInput #SearchField *ngIf="selectedFilter.filter.inputType === 'text'" [(ngModel)]="selectedFilter.searchValue">

        <mat-select *ngIf="selectedFilter.filter.inputType === 'selectSingle' || selectedFilter.filter.inputType === 'selectMulti'"
                    [(value)]="selectedFilter.searchValue"
                    [multiple]="selectedFilter.filter.inputType === 'selectMulti'">
          <mat-option *ngFor="let option of selectedFilter.filter.options" [value]="option.value">
            {{option.display}}
          </mat-option>
        </mat-select>
        <ftms-typeahead name="autocomplete" [items]="selectedFilter.filter.options" multiple *ngIf="selectedFilter.filter.inputType === 'autocomplete'"
                        [(ngModel)]="selectedFilter.searchValue"></ftms-typeahead>

        <mat-chip-list #chipList *ngIf="selectedFilter.filter.inputType === 'species'" (click)="openSpeciesModal(selectedFilter.filter.name)">
          <mat-chip *ngFor="let chip of selectedFilter.searchValue" (removed)="removeChip(chip, selectedFilter.filter.name)" [matTooltip]="chip.Description">
            {{chip.Code}}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <input [ngClass]="{'mat-chip-input-display': selectedFilter.searchValue == undefined || selectedFilter.searchValue.length === 0, 'mat-chip-input-hide': selectedFilter.searchValue != undefined && selectedFilter.searchValue.length > 0}" [matChipInputFor]="chipList">
        </mat-chip-list>
        <button class="chip-add-btn" matSuffix mat-icon-button *ngIf="selectedFilter.filter.inputType === 'species'" (click)="openSpeciesModal(selectedFilter.filter.name)">
          <mat-icon>add</mat-icon>
        </button>

        <mat-chip-list #chipList *ngIf="selectedFilter.filter.inputType === 'chipList'">
          <mat-chip *ngFor="let chip of selectedFilter.searchValue" (removed)="removeChip(chip, selectedFilter.filter.name)" [matTooltip]="chip.Description">
            {{chip}}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <input [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeyCodes" (matChipInputTokenEnd)="addChip($event, selectedFilter.filter.name)" />
        </mat-chip-list>

        <mat-chip-list #contractorChip *ngIf="selectedFilter.filter.inputType === 'contractor'" (click)="openContractorModal(selectedFilter.filter.name)">
          <mat-chip *ngFor="let chip of selectedFilter.searchValue" (removed)="removeChip(chip, selectedFilter.filter.name)" [matTooltip]="chip.Description">
            {{chip.Code}}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <input [ngClass]="{'mat-chip-input-display': selectedFilter.searchValue == undefined || selectedFilter.searchValue.length === 0, 'mat-chip-input-hide': selectedFilter.searchValue != undefined && selectedFilter.searchValue.length > 0}" [matChipInputFor]="contractorChip">
        </mat-chip-list>
        <button class="chip-add-btn" matSuffix mat-icon-button *ngIf="selectedFilter.filter.inputType === 'contractor'" (click)="openContractorModal(selectedFilter.filter.name)">
          <mat-icon>add</mat-icon>
        </button>

        <input matInput [matDatepicker]="picker" *ngIf="selectedFilter.filter.inputType === 'date'" [(ngModel)]="selectedFilter.searchValue" name="picker">
        <mat-datepicker-toggle *ngIf="selectedFilter.filter.inputType === 'date'" matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>

        <input matInput *ngIf="selectedFilter.filter.inputType === 'none'" disabled [(ngModel)]="selectedFilter.searchValue">
        <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="removeFilter(selectedFilter)">
          <mat-icon>close</mat-icon>
        </button>

        <mat-hint *ngIf="selectedFilter.filter.hintText != null" align="end" [innerHTML]="selectedFilter.filter.hintText"></mat-hint>
      </mat-form-field>
      <div>
        <mat-form-field *ngIf="selectedFilter.filter.inputType === 'dateRange'">
          <mat-label>{{ selectedFilter.filter.name }} Start</mat-label>
          <input matInput [matDatepicker]="startDateRangePicker" [(ngModel)]="selectedFilter.StartDateSearchValue" name="startDateRangePicker">
          <mat-datepicker-toggle matSuffix [for]="startDateRangePicker"></mat-datepicker-toggle>
          <mat-datepicker #startDateRangePicker></mat-datepicker>
          <mat-hint *ngIf="selectedFilter.filter.hintText != null" align="end" [innerHTML]="selectedFilter.filter.hintText"></mat-hint>
        </mat-form-field>
        <mat-form-field *ngIf="selectedFilter.filter.inputType === 'dateRange'">
          <mat-label>{{ selectedFilter.filter.name }} End</mat-label>
          <input matInput [matDatepicker]="endDateRangePicker" [(ngModel)]="selectedFilter.EndDateSearchValue" name="endDateRangePicker">
          <mat-datepicker-toggle matSuffix [for]="endDateRangePicker"></mat-datepicker-toggle>
          <mat-datepicker #endDateRangePicker></mat-datepicker>
          <mat-hint *ngIf="selectedFilter.filter.hintText != null" align="end" [innerHTML]="selectedFilter.filter.hintText"></mat-hint>
          <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="removeFilter(selectedFilter)">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>


  </mat-card-content>
  <mat-card-actions style="display: flex">
    <button mat-mini-fab matTooltip="Collapse Search Criteria" (click)="ToggleSearchSection()">
      <mat-icon>unfold_less</mat-icon>
    </button>
    <button class="hidden-sm hidden-xs" mat-icon-button mat-raised-button [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item *ngIf="enableFavorites == true" (click)="clearFavorites()">Clear Favorites</button>
      <button mat-menu-item (click)="clearSearch()" [disabled]="selectedFilters == null || selectedFilters.length == 0">Clear Search Filters</button>
    </mat-menu>
    <span style="flex: 1 1 auto;"></span>
    <button mat-icon-button mat-raised-button matTooltip="Apply last used filters" (click)="fetchPreviousSearchParameters()" [disabled]="isLoading">
      <mat-icon *ngIf="!isLoading">history</mat-icon>
      <mat-spinner *ngIf="isLoading" [diameter]="20"></mat-spinner>
    </button>
   
    <button class="hidden-md hidden-lg" mat-raised-button (click)="clearSearch()" [disabled]="selectedFilters == null || selectedFilters.length == 0">Clear</button>
    <button class="hidden-xs hidden-md" mat-raised-button color="primary" (click)="OnSearchButtonClick()">Search</button>
    <button class="hidden-sm hidden-lg" mat-icon-button mat-raised-button color="primary" matTooltip="Search" (click)="OnSearchButtonClick()">
      <mat-icon>search</mat-icon>
    </button>
  </mat-card-actions>
</mat-card>
