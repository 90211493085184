export enum AppRoles {
  User = 1,
  PaymentAdmin = 4
}

export var GetRoleName = (role: AppRoles): string => {
  switch (role) {
    case AppRoles.User:
      return "FTMS User";
    case AppRoles.PaymentAdmin:
      return "Payment Admin";
    default:
      return "Unknown role: " + role;
  }
};
