import { Component, Injectable, Injector, OnInit, Type } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { IModalComponent } from "./Types/IModalComponent";

export interface DirectModalLinkComponentData {
  modal: Type<IModalComponent>
}

@Injectable()
export class DirectModalLinkService {
  // If user goes to a standalone modal, then pastes in the URL of another standalone modal in the same browser window,
  // the page won't "refresh"; instead Angular will handle this navigation by updating the paramMap, which will result
  // in the new modal trying to open, but the old one trying to close and navigate to the home page, which results in
  // a "Unsaved Changes" modal displaying.
  public ModalDisplayCount: number = 0;
}

@Component({
  selector: 'direct-modal-link',
  template: '<!-- Direct modal link -->'
})
export class DirectModalLinkComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private injector: Injector,
    private router: Router,
    private directModalLinkService: DirectModalLinkService
  ) {
  }

  ngOnInit(): void {

    this.route.data.subscribe((data : DirectModalLinkComponentData) => {
      if (!data || !data.modal)
        this.returnToHomepage();

      var modal = this.getModal(data.modal);
      this.route.paramMap.subscribe((params) => {
        this.directModalLinkService.ModalDisplayCount++;

        modal.ShowStandaloneModal(data, params).subscribe(() => {
          this.directModalLinkService.ModalDisplayCount--;

          if (this.directModalLinkService.ModalDisplayCount <= 0) {
            this.directModalLinkService.ModalDisplayCount = 0;
            this.returnToHomepage();
          }
        });
      });
    });
  }

  getModal = (componentClass: Type<IModalComponent>): IModalComponent => {
    var modalComponent = this.injector.get(componentClass, null);

    return modalComponent;
  }

  returnToHomepage(): void {
    this.router.navigate(["/"]);
  }
}
