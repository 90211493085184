import { SelectionModel } from "@angular/cdk/collections";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { AgreementType } from "../../../modals/vendor-agreement-modal/types/VendorAgreement";
import { AgreementSearchRow } from "../types/AgreementSearchRow";

@Component({
  selector: 'agreement-search-results',
  templateUrl: 'agreement-search-results-grid.component.html'
})
export class AgreementSearchResultsGridComponent {
  public selection = new SelectionModel<AgreementSearchRow>(false);

  @Input() agreements: AgreementSearchRow;

  @Output() public onEdit = new EventEmitter<AgreementSearchRow>();

  @Output() public sortChanged = new EventEmitter<MatSort>();

  public Columns = [
    "VendorName",
    "VendorCode",
    "VendorCountry",
    "AgreementYear",
    "AgreementNumber",
    "AgreementTypeName",
    "AgreementStart",
    "AgreementEnd",
    "PaymentPlan",
    "TimelineDays",
    "AgreementActive"
  ];

  public doubleClick = (row: AgreementSearchRow) => {
    if (!this.selection.isSelected(row))
      this.selection.toggle(row);

    this.onEdit.next(row);
  }

  onSortChange = (evt: MatSort) => {
    this.sortChanged.next(evt);
  }
}
