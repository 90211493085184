<table mat-table [dataSource]="quotes" matSort (matSortChange)="onSortChange($event)">
  <ng-container matColumnDef="number">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="QuoteNumber">Quote FSPS Number</th>
    <td mat-cell *matCellDef="let row">
      {{row.QuoteNumber}}
    </td>
  </ng-container>
  <ng-container matColumnDef="year">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="QuoteYear">Quote Year</th>
    <td mat-cell *matCellDef="let row">
      {{row.QuoteYear}}
    </td>
  </ng-container>
  <ng-container matColumnDef="person">
    <th mat-header-cell *matHeaderCellDef matTooltip="Trial/Non-trial Responsible" mat-sort-header="QuotePersonResponsible">Trial/Non-trial Investigator</th>
    <td mat-cell *matCellDef="let row">
      {{row.QuotePersonResponsible}}
    </td>
  </ng-container>
  <ng-container matColumnDef="budgetYear">
    <th mat-header-cell *matHeaderCellDef>Budget Years</th>
    <td mat-cell *matCellDef="let row">
      {{row.BudgetYears}}
    </td>
  </ng-container>
  <ng-container matColumnDef="counts">
    <th mat-header-cell *matHeaderCellDef>Quote Trial Non-Trial</th>
    <td mat-cell *matCellDef="let row">
      {{ row.TrialCount ? row.TrialCount + " Trials" : null }}
      <br *ngIf="row.TrialCount && row.NontrialCount">
      {{ row.NonTrialCount ? row.NonTrialCount + " Non-Trials" : null }}
    </td>
  </ng-container>
  <ng-container matColumnDef="committed">
    <th mat-header-cell style="text-align: right" *matHeaderCellDef  mat-sort-header="QuoteTotalCommitted">Quote Total-Committed</th>
    <td mat-cell *matCellDef="let row" class="text-right">
      {{row.QuoteTotalCommitted | number}}
    </td>
  </ng-container>
  <ng-container matColumnDef="currency">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="CommittedCurrencyName">Currency</th>
    <td mat-cell *matCellDef="let row">
      {{row.CommittedCurrencyName}}
    </td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="QuoteStatus">Quote Status</th>
    <td mat-cell *matCellDef="let row">
      {{QuoteStatusDescription(row.QuoteStatus)}}
    </td>
  </ng-container>
  <ng-container matColumnDef="pr">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="PurchaseRequisitionNumber">Purchase Request</th>
    <td mat-cell *matCellDef="let row">
      {{row.PurchaseRequisitionNumber}}
    </td>
  </ng-container>
  <ng-container matColumnDef="purchaseOrder">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="PurchaseOrderNumber">Purchase Order</th>
    <td mat-cell *matCellDef="let row">
      {{row.PurchaseOrderNumber}}
    </td>
  </ng-container>
  <ng-container matColumnDef="docs">
    <th mat-header-cell style="text-align: right; padding-right:15px" *matHeaderCellDef mat-sort-header="DocumentsCount">Quote Documents</th>
    <td mat-cell *matCellDef="let row" style="text-align: right; padding-right:15px">
      {{row.DocumentsCount}}
    </td>
  </ng-container>
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="VendorName">Vendor Name</th>
    <td mat-cell *matCellDef="let row">
      {{row.VendorName}}
    </td>
  </ng-container>
  <ng-container matColumnDef="code">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="VendorCode">Vendor Code</th>
    <td mat-cell *matCellDef="let row">
      {{row.VendorCode}}
    </td>
  </ng-container>
  <ng-container matColumnDef="country">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="VendorCountry">Vendor Country</th>
    <td mat-cell *matCellDef="let row">
      {{row.VendorCountry}}
    </td>
  </ng-container>
  <ng-container matColumnDef="contact">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="ContactName">Contact Name</th>
    <td mat-cell *matCellDef="let row">
      {{row.ContactName}}
    </td>
  </ng-container>
  <ng-container matColumnDef="paidByCommercial">
    <th mat-header-cell *matHeaderCellDef>Paid by Commercial</th>
    <td mat-cell *matCellDef="let row">
      {{ row.PaidByCommercialCount ? row.PaidByCommercialCount + " Yes" : null }}
      <br *ngIf="row.PaidByNonCommercialCount&&row.PaidByCommercialCount">
      {{ row.PaidByNonCommercialCount ? row.PaidByNonCommercialCount + " No" : null }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="Columns; sticky: true"></tr>
  <tr mat-row (dblclick)="doubleClick(row)"
      (click)="this.selection.toggle(row)"
      [class.selected]="selection.isSelected(row)"
      *matRowDef="let row; columns: Columns"></tr>
</table>
