import { DocumentMetadataDto } from "../../../components/document-picker/DocumentMetadataDto";
import { VendorPaymentPlan } from "../../vendor-agreement-modal/types/VendorAgreement";
import { PaymentLineItemInvoiceAssocUpdates } from "./PaymentLineItemInvoiceAssocUpdates";

export interface Invoice extends InvoiceCreate {
  Id: number;
  InvoiceNumber: string;
  InvoiceNumberSequence: number;
  CreatedByPersonId: number;
  CreatedByName: string;
  CreatedDate: Date;
  UpdatedByPersonId: number;
  UpdatedByName: string;
  UpdatedDate: Date;
  Documents: Array<DocumentMetadataDto>;
  VendorId: number;
  VendorName: string;
  VendorCode: string;
  VendorCountryId: number;
  AgreementPaymentPlan?: VendorPaymentPlan;
  AgreementTimeline?: number;
  AgreementEndDate?: Date;
  VendorPurchaseOrderRequired: boolean;
  TotalInvoiced: number;
  CurrencyName: string;
  CanBeDeleted: boolean;
}

export interface InvoiceCreate extends InvoiceUpdate {
  Year: number;
  PurchaseRequisitionNumber: string;
  PurchaseOrderNumber: string;
  QuotePurchaseOrderEndDates: Array<Date>;
}

export interface InvoiceUpdate {
  InvoiceStatus: InvoiceStatus;
  PaymentCheck: string;
  PaymentDate: Date;
  DocumentIds: Array<number>;
  VendorAssignedInvoiceNumber: string;
  VendorInvoiceDate?: Date;
  DueDate?: Date;
  PaymentLineItemInvoiceAssocUpdates?: Array<PaymentLineItemInvoiceAssocUpdates>;
}

export enum InvoiceStatus {
  New = 0,
  Submitted = 1,
  Complete = 2,
}
export function GetInvoiceStatusDescription(status: InvoiceStatus): string {
  switch (status) {
    case InvoiceStatus.New: return "New"
    case InvoiceStatus.Submitted: return "Submitted"
    case InvoiceStatus.Complete: return "Completed"
  }
}
