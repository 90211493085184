<div>
  <h1 mat-dialog-title>
    <i class="fa fa-warning"></i>
    <ng-container *ngIf="Errors.length > 0">
      {{Errors[0].title}}
    </ng-container>
    <ng-container *ngIf="Errors.length == 0">
      Something unexpected happened!
    </ng-container>
  </h1>
  <div mat-dialog-content>
    <div *ngFor="let error of Errors">
      <p style="font-weight: bold; margin-bottom: 5px;">
        {{error.message}}
      </p>

      <pre style="max-height: 300px; white-space: pre-wrap" *ngIf="error.techDetails != null && error.fluentValidationErrors?.length == 0">{{error.techDetails}}</pre>

      <ul *ngIf="error.fluentValidationErrors?.length > 0">
        <li *ngFor="let message of error.fluentValidationErrors">{{message}}</li>
      </ul>
    </div>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-raised-button color="primary" (click)="close()">
      <i class="fa fa-close"></i>
      Close
    </button>
  </div>
</div>
