import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SettingsService } from '../utility/SettingsService';

export interface ErrorHandlerResponse {
  continueExecutingErrorHandlers: boolean
};

export type errorHandler = { (error: HttpErrorResponse): ErrorHandlerResponse };
export class FtmsHttpParams extends HttpParams {
  public OnError?: errorHandler;
  public OnNotFound?: errorHandler;

  constructor(params?: { [param: string]: string | ReadonlyArray<string> }) {
    super({ fromObject: params });
  }

  /**
   * Sets the error handler. Error handlers are invoked in this
   * order: 1: NotFound (if applicable), 2: ErrorHandler, 3: Default error handler (modal popup)
   */
  public WithOnErrorHandler = (handler: errorHandler): FtmsHttpParams => {
    this.OnError = handler;
    return this;
  }

  /**
   * Sets the NotFound error handler. Error handlers are invoked in this
   * order: 1: NotFound (if applicable), 2: ErrorHandler, 3: Default error handler (modal popup)
   */
  public WithOnNotFoundHandler = (handler: errorHandler): FtmsHttpParams => {
    this.OnNotFound = handler;
    return this;
  }
};

@Injectable()
export class ApiService {
  private baseUrl: string;

  constructor(public http: HttpClient, public settingsService: SettingsService) {
    if (settingsService === null || settingsService === undefined)
      settingsService = new SettingsService();

    this.baseUrl = settingsService.GetSettings().apiUrl;
  }

  public POST = <TBody, TResponse>(body: TBody, url: string, params: FtmsHttpParams = undefined): Observable<TResponse> => {
    return this.http.post<TResponse>(`${this.baseUrl}${url}`, body, { params: params });
  }

  public GET = <TResponse>(url: string, params: FtmsHttpParams = undefined): Observable<TResponse> => {
    return this.http.get<TResponse>(`${this.baseUrl}${url}`, { params: params });
  }

  public PUT = <TBody, TResponse>(body: TBody, url: string, params: FtmsHttpParams = undefined): Observable<TResponse> => {
    return this.http.put<TResponse>(`${this.baseUrl}${url}`, body, { params: params });
  }

  public DELETE = <TResponse>(url: string, params: FtmsHttpParams = undefined): Observable<TResponse> => {
    return this.http.delete<TResponse>(`${this.baseUrl}${url}`, { params: params });
  }

  public GetApiUrl = (url: string): string => {
    return `${this.baseUrl}${url}`;
  }

  public convertToQueryUrl = (obj: any): string => {
    const params = new URLSearchParams(obj);
    for (const [key, value] of Object.entries(obj)) {
      if (Array.isArray(value)) {
        params.delete(key);
        value.forEach((v) => params.append(key, v));
      }
    }
    return params.toString();
  }
}
