import { Component, OnInit } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { CurrentUserService, DbPerson } from "../../shared/CurrentUser.service";
import { IQuickLinkGroups, IQuickLinks } from "../../shared/quicklinks/IQuickLinks";
import { QuickLinksService } from "../../shared/services/quickLinks.service";

@Component({
  selector: 'quicklink-redirect',
  templateUrl: 'quicklink-redirect.component.html'
})
export class QuicklinkRedirectComponent implements OnInit {
  constructor(public quicklinksService: QuickLinksService,
    private userService: CurrentUserService,
    private router: Router) {
  }

  ngOnInit(): void {
    this.GetQuickLink().subscribe(quickLink => {
      this.router.navigateByUrl(quickLink.redirectTo, { replaceUrl: true, state: quickLink.navigationExtraState } as NavigationExtras)
    });
  }

  public GetQuickLink = (): Observable<IQuickLinks> => {
    return this.userService.GetApiUser().pipe(map(user => {
      var quicklinkgroups = this.quicklinksService.getQuickLinkGroups(user as DbPerson);
      var quicklinks = [];
      quicklinkgroups.forEach(q => q.quicklinks.forEach(quicklink=>quicklinks.push(quicklink)));
      var quicklink= quicklinks.find(link => "/" + link.route == this.router.url);
      return quicklink ? quicklink : { route: "home", redirectTo: "home" };
    }));
  }
}
