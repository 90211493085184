import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "../../shared/ApiService";
import { Invoice, InvoiceCreate, InvoiceUpdate } from "./types/Invoice";
import { InvoiceCreateDropdowns } from "./types/InvoiceCreateDropdowns";
import { PossibleInvoicePaymentLineItems } from "./types/PossibleInvoicePaymentLineItems";

@Injectable()
export class InvoiceModalService {

  constructor(private apiService: ApiService) {
  }

  public GetCreateDropdowns = (vendorId: number): Observable<InvoiceCreateDropdowns> => {
    return this.apiService.GET<InvoiceCreateDropdowns>(`api/v1/vendors/${vendorId}/invoice-create-meta`);
  }

  public GetInvoice = (invoiceId: number): Observable<Invoice> => {
    return this.apiService.GET<Invoice>(`api/v1/invoices/${invoiceId}`);
  }

  public CreateInvoice = (vendorId: number, createData: InvoiceCreate): Observable<Invoice> => {
    return this.apiService.POST<InvoiceCreate, Invoice>(createData, `api/v1/vendor/${vendorId}/invoice`);
  }

  public UpdateInvoice = (invoiceId: number, updateData: InvoiceUpdate): Observable<Invoice> => {
    return this.apiService.PUT<InvoiceUpdate, Invoice>(updateData, `api/v1/invoices/${invoiceId}`);
  }

  public DeleteInvoice = (invoiceId: number): Observable<void> => {
    return this.apiService.DELETE<void>(`api/v1/invoices/${invoiceId}`);
  }

  public GetInvoicesForVendor = (vendorId: number): Observable<Array<Invoice>> => {
    return this.apiService.GET<Array<Invoice>>(`api/v1/vendors/${vendorId}/invoices`);
  }

  public GetPossiblePaymentLineItems = (invoiceId: number): Observable<Array<PossibleInvoicePaymentLineItems>> => {
    return this.apiService.GET<Array<PossibleInvoicePaymentLineItems>>(`api/v1/invoice/${invoiceId}/possible-payment-line-items`);
  }
}
