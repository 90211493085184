import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from "@angular/core";

@Component({
  selector: 'comma-separated-input',
  templateUrl: 'comma-separated-input.component.html',
  styleUrls: ['comma-separated-input.component.less']
})
export class CommaSeparatedInputComponent implements OnInit {
  @Input() amount: number;
  @Output() amountChange = new EventEmitter<number>();
  @Input() deletable: boolean = false;
  @Input() isDarkLabel: boolean = false;
  @Output() deleteInstance = new EventEmitter<boolean>();
  @Input() isDeleteDisabled: boolean;
  @Input() label: string;
  @Input() controlName: string;
  @Input() isDisabled: boolean;
  @Input() rightJustify: boolean;
  @ViewChild("amountInput", { static: false }) amountInput: ElementRef<HTMLInputElement>;
  @ViewChild("amountDisplayInput", { static: false }) amountDisplayInput: ElementRef<HTMLInputElement>;

  ngOnInit(): void {
  }

  onFocus = () => {
    this.amountInput.nativeElement.style.display = "";
    this.amountInput.nativeElement.focus();
    this.amountDisplayInput.nativeElement.style.display = "none";
  }

  onFocusOut = () => {
    if (!isNaN(Number(this.amountInput.nativeElement.value))) {
      this.amountDisplayInput.nativeElement.style.display = "";
      this.amountInput.nativeElement.style.display = "none";
      this.amountChange.emit(this.amount);
    }
  }

  delete = () => {
    this.deleteInstance.emit(true);
  }
}
