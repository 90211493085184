import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { YesNoModal } from '../../modals/yes-no-modal/yes-no-modal.component';
import { MessageModal } from '../../modals/message-modal/message-modal.component';

@Injectable()
export class DialogService {
    constructor(@Inject(MatDialog) public dialog: MatDialog) {
    }

    confirmUnsavedChanges(setNoButtonAsDefault?: boolean): Observable<boolean> {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            headerTitle: "Unsaved Changes!",
            message: "Are you sure you want to leave this page?",
            yesText: "Yes, Discard and Leave this Page",
            noText: "Stay on this Page",
            setNoButtonAsDefault: setNoButtonAsDefault
        };
        const yesNoDialog = this.dialog.open(YesNoModal, dialogConfig);

        return yesNoDialog.afterClosed();
    };

    showMessage(title: string, message: string, buttonText?: string): Observable<void> {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            headerTitle: title,
            message: message,
            okText: buttonText
        };

        const messageDialog = this.dialog.open(MessageModal, dialogConfig);

        return messageDialog.afterClosed();
    }
}