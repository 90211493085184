import { DocumentMetadataDto } from "../../../components/document-picker/DocumentMetadataDto";

export interface Quote extends QuoteCreate {
  Id: number;
  VendorId: number;
  VendorCode: string;
  VendorName: string;
  VendorCountryId: number;
  QuoteNumber: string;
  QuoteNumberSequence: number;
  FieldScientistName: string;
  ContactName: string;
  ContactEmail: string;
  LastApprovedByPersonId?: number;
  LastApprovedByPersonName?: string;
  LastApprovedDate?: Date;
  CreatedByPersonId: number;
  CreatedDate: Date;
  UpdatedByPersonId?: number;
  UpdatedDate?: Date;
  ActiveAgreementStartDate?: Date;
  ActiveAgreementEndDate?: Date;
  IsPurchaseOrderRequired: boolean;
  Documents: Array<DocumentMetadataDto>;
  CanBeDeleted: boolean;
  VendorActive: boolean;
}

export interface QuoteCreate extends QuoteUpdate {
}

export interface QuoteUpdate {
  ContactId: number;
  FieldScientistId: number;
  Year: number;
  PurchaseRequisitionNumber: string;
  PurchaseOrderNumber: string;
  PurchaseOrderStartDate: Date;
  PurchaseOrderEndDate: Date;
  VendorAssignedQuoteNumber: string;
  Comments: string;
  Status: QuoteStatus;
  PaymentLineItemAssocUpdates: Array<QuotePaymentLineItemAssocUpdatesDto>;
  DocumentIds: Array<number>;
}

export interface QuotePaymentLineItemAssocUpdatesDto {
  AssocId: number;
  QuotedAmount: number;
  Comment: string;
}

export enum QuoteStatus {
  New = 0,
  ReadyForReview = 1,
  Approved = 2,
  PurchaseRequest = 3,
  PurchaseOrder = 4,
  NoPurchaseOrder = 5,
}
