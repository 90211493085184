import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { Component, EventEmitter, Inject, Input, Output } from "@angular/core";
import { MatChipInputEvent } from "@angular/material/chips";
import { VendorSelectionModal } from "../../modals/vendor-selection-modal/vendor-selection-modal.component";
import { VendorCard } from "../../modals/vendor-selection-modal/types/VendorCard";
import { SearchHelper } from "../../shared/helpers/SearchHelper";
import { IFilterDef, ISelectedFilterDef, IFilterCategoryDef } from "../../shared/interfaces/IFilterDef";
import { SearchType } from "../../shared/services/search-storage.service";
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from "../../shared/format-datepicker";
import { ICodeItem } from "../../shared/interfaces/ICodeItem";

@Component({
  selector: 'filter-card',
  templateUrl: 'filter-card.component.html',
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class FilterCardComponent<TSearchCriteria> {

  @Input() public SearchHistoryType: SearchType;
  @Input() public selectedFilters: ISelectedFilterDef<TSearchCriteria>[] = [];
  @Input() public filterCategories: IFilterCategoryDef<TSearchCriteria>[];
  @Input() public enableFavorites: boolean = false;
  @Output() public selectedFiltersChange = new EventEmitter<ISelectedFilterDef<TSearchCriteria>[]>();

  @Output() public OnCollapseSearchToggled = new EventEmitter();
  @Output() public OnClearSearch = new EventEmitter();
  @Output() public OnSearchClicked = new EventEmitter<ISelectedFilterDef<TSearchCriteria>[]>();
  @Output() public OnClearFavorites = new EventEmitter();

  readonly separatorKeyCodes = [ENTER, COMMA] as const;
  isLoading: boolean = false;
  constructor(
    @Inject(SearchHelper) private searchHelper: SearchHelper,
    @Inject(VendorSelectionModal) private vendorSelectionModal: VendorSelectionModal
  ) {

  }

  ngOnInit(): void {
    if (this.filterCategories) {
      this.filterCategories.forEach(category => {
        category.filters.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      });
    }
  }

  private notifySelectedFiltersChanged = () => {
    this.selectedFiltersChange.emit(this.selectedFilters);
  }

  public areAllFiltersApplied = (): boolean => {
    return this.filterCategories.every(category => category.filters.every(a => !a.disabled && this.isFilterInUse(a)));
  }

  public doesCategoryHaveAvailableFilters = (filterCategory: IFilterCategoryDef<TSearchCriteria>): boolean => {
    return filterCategory.filters.find(a => !a.disabled && !this.isFilterInUse(a)) !== undefined;
  }

  ToggleSearchSection = () => {
    this.OnCollapseSearchToggled.emit();
  }

  isFilterInUse = (filterDef: IFilterDef<TSearchCriteria>) => {
    if (this.selectedFilters == null)
      return false;

    if (this.selectedFilters.map(e => e.filter.name).indexOf(filterDef.name) < 0) {
      return false;
    }
    else {
      return true;
    }
  }

  addFilter = (filterDef: IFilterDef<TSearchCriteria>) => {
    if (this.isFilterInUse(filterDef)) {
      return;
    }

    if (filterDef.disabled == true) {
      return;
    }
    if (this.selectedFilters == null)
      this.selectedFilters = [];

    this.selectedFilters.push({ filter: filterDef, searchValue: filterDef.defaultValue });
    this.notifySelectedFiltersChanged();
  }

  removeFilter = (selectedItem: ISelectedFilterDef<TSearchCriteria>) => {
    var index = this.selectedFilters.indexOf(selectedItem);
    if (index > -1) {
      this.selectedFilters.splice(index, 1);
      this.notifySelectedFiltersChanged();
    }
  }

  public OnSearchButtonClick = () => {
    this.OnSearchClicked.emit();
    
    if (this.selectedFilters == null)
      this.selectedFilters = [];


    this.searchHelper.setLatestFiltersV2(this.SearchHistoryType, this.selectedFilters);
  }

  public clearFavorites = () => {
    if (this.OnClearFavorites)
      this.OnClearFavorites.emit();
  }

  clearSearch = () => {
    this.selectedFilters = [];
    this.notifySelectedFiltersChanged();
    if (this.OnClearSearch)
      this.OnClearSearch.emit();
  }

  fetchPreviousSearchParameters = async () => {
    this.isLoading = true;
    let filters: Array<IFilterDef<TSearchCriteria>> = [];
    this.filterCategories.forEach(category => {
      category.filters.forEach(filter => filters.push(filter));
    });

    var savedSearch = await this.searchHelper.getLatestFiltersV2(this.SearchHistoryType, filters);

    if (savedSearch != null)
      this.selectedFilters = savedSearch;

    this.notifySelectedFiltersChanged();
    this.isLoading = false;
  }

  addChip(event: MatChipInputEvent, filterName: string): void {
    const value = (event.value || '').trim();
    const filter = this.selectedFilters.find(e => e.filter.name === filterName);
    if (!filter.searchValue)
      filter.searchValue = [];

    // Add our chip
    if (value) {
      filter.searchValue.push(value);
    }

    // Clear the input value
    event.input.value = "";
  }

  public removeChip = (chip: ICodeItem, filterName: string) => {
    const filter: Array<ICodeItem> = this.selectedFilters.find(e => e.filter.name === filterName).searchValue;
    const index = filter.indexOf(chip);
    filter.splice(index, 1);
  }

  public openVendorModal = (filterName: string) => {
    this.vendorSelectionModal.ShowModal({
      countryId: null,
      checkAgreementStatus: false
    }).subscribe((result: VendorCard) => {
      if (result !== undefined) {
        let vendorFilter: ISelectedFilterDef<TSearchCriteria> = this.selectedFilters.find(e => e.filter.name === filterName);
        if (vendorFilter.searchValue == undefined)
          vendorFilter.searchValue = [];

        vendorFilter.searchValue.push(<ICodeItem>{ Id: result.VendorId, Code: result.VendorName, Description: result.VendorCode });
      }
    });
  }
}
