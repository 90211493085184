import { SelectionModel } from "@angular/cdk/collections";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { VendorSearchRow } from "../vendor-search/types/VendorSearchRow";

@Component({
  selector: 'vendor-results-grid',
  templateUrl: 'vendor-results-grid.component.html'
})
export class VendorResultsGridComponent {
  public selection = new SelectionModel<VendorSearchRow>(false);

  @Input() public vendors: Array<VendorSearchRow> = [];

  @Output() public onEdit = new EventEmitter<VendorSearchRow>();

  @Output() public sortChanged = new EventEmitter<MatSort>();

  private defaultColumns: Array<string> = [
    "vendorName",
    "code",
    "type",
    "contact",
    "addressLine1",
    "addressLine2",
    "addressLine3",
    "country",
    "state",
    "city",
    "zip",
    "phone",
    "fax",
    "email",
    "active"
  ];

  public Columns: Array<string> = [...this.defaultColumns];

  public doubleClick = (row: VendorSearchRow) => {
    if (!this.selection.isSelected(row))
      this.selection.toggle(row);

    this.onEdit.next(row);
  }

  onSortChange = (evt: MatSort) => {
    this.sortChanged.next(evt);
  }
}
