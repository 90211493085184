import { Inject, Injectable, OnInit } from '@angular/core';
import { AccountInfo } from '@azure/msal-browser';
import { Observable, of, ReplaySubject } from "rxjs";
import { QuoteSearchParameters } from '../../components/quote-search/types/QuoteSearchParameters';
import { CurrentUserService, DbPerson } from '../CurrentUser.service';
import { IQuickLinks } from "../quickLinks/IQuickLinks";
import { IQuickLinkGroups } from "../quickLinks/IQuickLinks";

@Injectable() 

export class QuickLinksService implements OnInit {
    public quickLinksEnabled: ReplaySubject<boolean> = new ReplaySubject(1);

    constructor(@Inject(CurrentUserService) private currentUserService: CurrentUserService) {
    }

    ngOnInit(): void {
    }

  public getQuickLinkGroups = (p: DbPerson): IQuickLinkGroups[] => {
    var quickLinkGroups: IQuickLinkGroups[] = [
      {
        groupdisplayName: 'Field Scientists Links',
        quicklinks:[
          {
            isFTMSLink: true,
            displayName: 'FTMS (Field Trial Management System)',
            iconName: 'link',
            route: '',
            redirectTo: '',
          },
          {
            isFTMSLink: true,
            displayName: 'My Trials - Actual',
            iconName: 'link',
            route: '#/v2/my-trials-actual',
            redirectTo: '',
          },
          {
            displayName: 'My Vendors',
            iconName: 'link',
            route: 'my-vendors',
            redirectTo: 'vendors',
            navigationExtraState: { PersonResponsibleIds: p !== null ? [p.Id] : [], Years: [new Date().getFullYear()] }
          },
          {
            displayName: 'My Quotes - Field Scientist',
            iconName: 'link',
            route: 'my-quotes/field-scientist',
            redirectTo: 'quotes',
            navigationExtraState: { PersonResponsibleIds: p !== null ? [p.Id] : [], BudgetYears: [new Date().getFullYear()] }
          },
          {
            displayName: 'My Invoices  - Field Scientist',
            iconName: 'link',
            route: 'my-invoices/field-scientist',
            redirectTo: 'invoices',
            navigationExtraState: { PersonResponsibleIds: p !== null ? [p.Id] : [], BudgetYears: [new Date().getFullYear()] }
          }
        ]
      },
      {
        groupdisplayName: 'Regional Admin Links',
        quicklinks: [
          {
            displayName: 'My Quotes - Regional Payment Admin',
            iconName: 'link',
            route: 'my-quotes/regional-payment-admin',
            redirectTo: 'quotes',
            navigationExtraState: { BudgetYears: [new Date().getFullYear()], VendorCountries: p.PaymentAdminCountries, QuoteStatus: null }
          },
          {
            displayName: 'My Invoices  - Regional Payment Admin',
            iconName: 'link',
            route: 'my-invoices/regional-payment-admin',
            redirectTo: 'invoices',
            navigationExtraState: { InvoiceYear: new Date().getFullYear(), VendorCountries: p.PaymentAdminCountries }
          },
          {
            displayName: 'My Agreements',
            iconName: 'link',
            route: 'my-agreements',
            redirectTo: 'agreements',
            navigationExtraState: { VendorName: null, VendorCode: null, VendorCountry: p.PaymentAdminCountries, AgreementYear: new Date().getFullYear(), AgreementNumber: null, AgreementType: null, AgreementEndDateRangeEnd: null }
          }

        ]
      }
    ];

    return quickLinkGroups;
  }

    setQuickLinksEnabled = (addMode: boolean) => {
        this.quickLinksEnabled.next(addMode);
    }
}
