import { Component, Inject, Injectable, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Data, Params } from "@angular/router";
import { Observable } from "rxjs";
import { IModalComponent } from "../../../components/direct-modal-link/Types/IModalComponent";
import { CurrentUserService } from "../../../shared/CurrentUser.service";
import { AppDateAdapter, APP_DATE_FORMATS } from "../../../shared/format-datepicker";
import { FormControlHelper } from "../../../shared/helpers/FormControlHelper";
import { ChangeDetectionService } from "../../../shared/services/change-detection.service";
import { DialogService } from "../../../shared/services/dialog.service";
import { LoadingService } from "../../../shared/services/loading-overlay/loading-overlay.component";
import { InvoiceModalService } from "../invoice-modal.service";
import { GetInvoiceStatusDescription, Invoice, InvoiceStatus, InvoiceUpdate } from "../types/Invoice";
import { Trial as InvoiceAssocTrial } from "../payment-line-item-assoc/invoice-payment-line-item-assoc.component";
import * as InvoiceAssocMapper from "./assoc-mapper";
import { PossibleInvoicePaymentLineItems } from "../types/PossibleInvoicePaymentLineItems";
import { AppRoles } from "../../../shared/enums/AppRoles";
import { YesNoModal } from "../../yes-no-modal/yes-no-modal.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { VendorDetailModal } from "../../vendor-detail-modal/vendor-detail-modal.component";

export interface ShowInvoiceEditModalData {
  InvoiceId: number;
  isFromVendor?: boolean;
}

@Component({
  selector: 'invoice-edit-modal',
  templateUrl: 'invoice-edit-modal.component.html',
  styleUrls: [
    "invoice-edit-modal.component.less",
  ],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class InvoiceEditModalComponent implements OnInit {
  public Invoice: Invoice;
  public AssocData: Array<InvoiceAssocTrial> = [];
  public UserIsAdmin: boolean = false;
  public UserRegionalAdminCountries: number[] = [];
  public isFromVendor?: boolean;

  // Create an object of bootstrap col-sm-N class names
  public cols: { [key: string]: { $implicit: string } } = {};

  public TimelineCalc: number = null;
  public PoEnd = null;

  public Dropdowns = {
    InvoiceStatuses: [InvoiceStatus.New, InvoiceStatus.Submitted, InvoiceStatus.Complete].map(a => {
      return {
        id: a,
        label: GetInvoiceStatusDescription(a),
        isEnabled: () => true
      }
    })
  }

  constructor(@Inject(MatDialogRef) private dialogRef: MatDialogRef<InvoiceEditModal, number>,
    @Inject(MAT_DIALOG_DATA) private showModalData: ShowInvoiceEditModalData,
    public dialog: MatDialog,
    private loadingService: LoadingService,
    public dialogService: DialogService,
    private formControlHelper: FormControlHelper,
    private userService: CurrentUserService,
    private changeDetectionService: ChangeDetectionService,
    private snackbar: MatSnackBar,
    private invoiceService: InvoiceModalService,
    private vendorDetailModal: VendorDetailModal  )
  {

    [...Array(12).keys()].map(a => {
      this.cols[`sm${a}`] = { $implicit: `col-sm-${a}` };
    });
  }

  public isDocumentChangeDisabled = (): boolean => !this.isNewStatus();
  public IsAtLeastOneDocumentRequired = () => false;

  ngOnInit(): void {
    const invoiceObservable = this.invoiceService.GetInvoice(this.showModalData.InvoiceId);
    const loadRef = this.loadingService.StartWaiting("Fetching data");
    this.isFromVendor = this.showModalData.isFromVendor;
    invoiceObservable.subscribe(invoice => {
      this.invoiceService.GetPossiblePaymentLineItems(invoice.Id).subscribe(possiblePaymentLineItems => {
        this.SetInvoiceData(invoice, possiblePaymentLineItems);

        this.userService.HasRole(AppRoles.PaymentAdmin).subscribe(hasRole => {
          this.UserIsAdmin = this.UserIsAdmin || hasRole;
          this.userService.GetPaymentAdminCountries().subscribe(countries => {
            this.UserRegionalAdminCountries = countries;
            var isRegionalAdmin = this.UserRegionalAdminCountries.find(c => c == this.Invoice.VendorCountryId) != null;

            this.UserIsAdmin = this.UserIsAdmin || isRegionalAdmin;
          });
        });

        loadRef.Stop();
      });
    });
  }

  public isPurchaseOrderInvoice: boolean = false;
  public isPurchaseRequestInvoice: boolean = false;

  private SetInvoiceData = (invoice: Invoice, possiblePaymentLineItems: Array<PossibleInvoicePaymentLineItems>) => {
    this.Invoice = invoice;
    this.AssocData = InvoiceAssocMapper.MapApiToAssoc(possiblePaymentLineItems, this.Invoice.Id);

    this.isPurchaseOrderInvoice = invoice.PurchaseOrderNumber != null && invoice.PurchaseOrderNumber.length > 0;
    this.isPurchaseRequestInvoice = invoice.PurchaseRequisitionNumber != null && invoice.PurchaseRequisitionNumber.length > 0;

    if (invoice.QuotePurchaseOrderEndDates?.length == 1)
      this.PoEnd = invoice.QuotePurchaseOrderEndDates[0];
    if (invoice.QuotePurchaseOrderEndDates?.length > 1)
      this.PoEnd = "Multiple";

    if (invoice.VendorInvoiceDate != null && invoice.DueDate != null) {
      let dueDate = new Date(invoice.DueDate);
      let invoiceDate = new Date(invoice.VendorInvoiceDate);

      if(invoiceDate <= dueDate)
        this.TimelineCalc = Math.trunc((dueDate.getTime() - invoiceDate.getTime()) / (1000 * 3600 * 24));
    }

    this.changeDetectionService.SetOriginalValue(this.GetChangeDetectionData());
  }

  public isComplete = () => {
    if (this.Invoice.InvoiceStatus == InvoiceStatus.Complete)
      return true;
    else
      return false;
  }

  public isNewStatus = () => {
    if (this.Invoice.InvoiceStatus == InvoiceStatus.New)
      return true;
    else
      return false;
  }

  public SaveAndClose = (form: NgForm) => this.save(form, true);
  public Save = (form: NgForm) => this.save(form, false);

  private save = (form: NgForm, closeOnSave: boolean) => {
    if (!this.formControlHelper.CheckFormIsValid(form))
      return;

    let updateData: InvoiceUpdate = this.Invoice;
    updateData.PaymentLineItemInvoiceAssocUpdates = InvoiceAssocMapper.MapAssocToUpdates(this.AssocData, this.Invoice.Id);
    updateData.DocumentIds = this.Invoice.Documents.map(a => a.Id);

    let loadRef = this.loadingService.StartWaiting("Updating invoice");

    let apiResponse = this.invoiceService.UpdateInvoice(this.Invoice.Id, updateData);

    apiResponse.subscribe(updatedInvoice => {

      if (closeOnSave) {
        loadRef.Stop();
        this.dialogRef.close(updatedInvoice.Id);
      }
      else {
        this.invoiceService.GetPossiblePaymentLineItems(updatedInvoice.Id).subscribe(possiblePaymentLineItems => {
          this.SetInvoiceData(updatedInvoice, possiblePaymentLineItems);

          loadRef.Stop();
        });
      }
    })
  }

  public CancelModal = async () => {
    let shouldLeave = await this.CheckForChangesAndPrompt();
    if (!shouldLeave)
      return;

    this.dialogRef.close(undefined);
  }

  public DisableDelete = () => {
    if (this.Invoice?.CanBeDeleted == null || !this.Invoice.CanBeDeleted || this.changeDetectionService.HasChanges(this.GetChangeDetectionData()))
      return true;
    return false;
  }

  public DeleteInvoice = async () => {
    let shouldLeave = await this.CheckForChangesAndPrompt();
    if (!shouldLeave)
      return;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      headerTitle: "Delete Invoice", message: "Are you sure you want to delete this Invoice?",
      yesText: "Yes",
      noText: "No"
    };
    const yesNoDialog = this.dialog.open(YesNoModal, dialogConfig);


    yesNoDialog.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        const loadingRef = this.loadingService.StartWaiting("Deleting Invoice");
        this.invoiceService.DeleteInvoice(this.Invoice.Id).subscribe((result) => {
          loadingRef.Stop();

          this.snackbar.open("Invoice deleted successfully.", null, {
            duration: 5000,
          });
          this.dialogRef.close(null);

        },
          (error) => {
            loadingRef.Stop();
          });
      }
    });
  }

  private GetChangeDetectionData = () => {
    return {
      invoice: this.Invoice,
      assocUpdates: InvoiceAssocMapper.MapAssocToUpdates(this.AssocData, this.Invoice.Id)
    }
  }

  private async CheckForChangesAndPrompt(): Promise<boolean> {
    if (this.changeDetectionService.HasChanges(this.GetChangeDetectionData())) {
      let shouldLeave = await this.changeDetectionService.HasChangeDialog();
      if (!shouldLeave)
        return false;
    }
    return true;
  }

  editVendor = (vendorId: number) => {
    if (!this.isFromVendor) {
      this.vendorDetailModal.ShowModal({
        vendorId: vendorId,
        isFromInvoice: true
      });
    }

  }
}

@Injectable()
export class InvoiceEditModal implements IModalComponent {
  constructor(@Inject(MatDialog) private dialog: MatDialog) {
  }

  ShowStandaloneModal(data: Data, params: Params): Observable<any> {
    var modalParams: ShowInvoiceEditModalData =
    {
      InvoiceId: Number(params.get('invoiceId')),
    };

    return this.ShowModal(modalParams);
  }

  public ShowModal =
    (data: ShowInvoiceEditModalData): Observable<number | undefined> => {
      const ref = this.dialog.open(InvoiceEditModalComponent, {
        data: data,
        width: "1500px",
        disableClose: true,
        autoFocus: false,
      });

      return ref.afterClosed();
    }
}
