export class Error {
  title: string;
  techDetails: string;
  message: string;
  fluentValidationErrors: string[] = [];

  constructor(title: string, message: string, techDetails: string, fluentValidationErrors: string[]) {
    this.title = title;
    this.techDetails = techDetails;
    this.message = message;
    if (fluentValidationErrors != null)
      fluentValidationErrors.forEach(e => this.fluentValidationErrors.push(e));
  }
}
