import { GetInvoiceStatusDescription, InvoiceStatus } from "../../modals/invoice-modal/types/Invoice";
import { IFilterCategoryDef, IFilterDef, IFilterDefWithOptions, IFilterDefWithTypeahead, SelectOption } from "../../shared/interfaces/IFilterDef";
import { TypeaheadItem } from "../typeahead/typeahead.component";
import { InvoiceSearchMeta } from "./types/InvoiceSearchMeta";
import { InvoiceSearchParameters } from "./types/InvoiceSearchParameters";

var namedFilters = {
  VendorName: <IFilterDef<InvoiceSearchParameters>>{ name: "Vendor Name", inputType: "text", hintText: "Executes a 'contains' search", searchParameterProperty: "VendorName" },
  VendorCode: <IFilterDef<InvoiceSearchParameters>>{ name: "Vendor Code", inputType: "text", hintText: "Executes a 'contains' search", searchParameterProperty: "VendorCode" },
  Countries: <IFilterDefWithTypeahead<number, InvoiceSearchParameters>>{ name: "Vendor Country", inputType: "autocomplete", options: [], searchParameterProperty: "VendorCountries" },

  InvoiceYear: <IFilterDefWithOptions<number, InvoiceSearchParameters>>{ name: "Invoice Year", inputType: "selectSingle", searchParameterProperty: "InvoiceYear" },

  PersonResponsibles: <IFilterDefWithTypeahead<number, InvoiceSearchParameters>>{ name: "Person Responsible", inputType: "autocomplete", options: [], searchParameterProperty: "PersonResponsibleIds" },
  TrialYears: <IFilterDefWithOptions<number, InvoiceSearchParameters>>{ name: "Years", inputType: "selectMulti", options: [], searchParameterProperty: "PaymentLineItemYears" },
  BudgetYears: <IFilterDefWithOptions<number, InvoiceSearchParameters>>{ name: "Budget Years", inputType: "selectMulti", options: [], searchParameterProperty: "BudgetYears" },
  TrialNontrialNumber: <IFilterDef<InvoiceSearchParameters>>{ name: "Number", inputType: "text", hintText: "Executes a 'contains' search", searchParameterProperty: "TrialNontrialNumber" },


  PoNumber: <IFilterDef<InvoiceSearchParameters>>{ name: "Purchase Order Number", inputType: "text", hintText: "Executes a 'contains' search", searchParameterProperty: "PurchaseOrderNumber" },
  PrNumber: <IFilterDef<InvoiceSearchParameters>>{ name: "Purchase Requisition Number", inputType: "text", hintText: "Executes a 'contains' search", searchParameterProperty: "PurchaseRequisitionNumber" },
  FspsInvoiceNumber: <IFilterDef<InvoiceSearchParameters>>{ name: "FSPS Invoice Number", inputType: "text", hintText: "Executes a 'contains' search", searchParameterProperty: "FspsInvoiceNumber" },
  InvoiceStatus: <IFilterDefWithOptions<InvoiceStatus, InvoiceSearchParameters>>{ name: "Invoice Status", inputType: "selectSingle", options: [], searchParameterProperty: "InvoiceStatus" },
  VendorInvoiceNumber: <IFilterDef<InvoiceSearchParameters>>{ name: "Vendor Invoice Number", inputType: "text", hintText: "Executes a 'contains' search", searchParameterProperty: "VendorInvoiceNumber" },
}

var filterCategories: IFilterCategoryDef<InvoiceSearchParameters>[] = [
  {
    categoryName: "Vendor",
    filters: [
      namedFilters.VendorName,
      namedFilters.VendorCode,
      namedFilters.Countries
    ]
  },
  {
    categoryName: "Invoice",
    filters: [
      namedFilters.InvoiceYear,
      namedFilters.PoNumber,
      namedFilters.PrNumber,
      namedFilters.FspsInvoiceNumber,
      namedFilters.InvoiceStatus,
      namedFilters.VendorInvoiceNumber
    ]
  },
  {
    categoryName: "Trial/Non-Trial",
    filters: [
      namedFilters.PersonResponsibles,
      namedFilters.TrialYears,
      namedFilters.BudgetYears,
      namedFilters.TrialNontrialNumber
    ]
  }
];

var filters: IFilterDef<InvoiceSearchParameters>[] = [
  namedFilters.VendorName,
  namedFilters.VendorCode,
  namedFilters.Countries,
  namedFilters.InvoiceYear,
  namedFilters.PoNumber,
  namedFilters.PrNumber,
  namedFilters.FspsInvoiceNumber,
  namedFilters.InvoiceStatus,
  namedFilters.PersonResponsibles,
  namedFilters.TrialYears,
  namedFilters.BudgetYears,
  namedFilters.TrialNontrialNumber,
  namedFilters.VendorInvoiceNumber
]

export var filterDefinitions = {
  namedFilters: namedFilters,
  filterCategories: filterCategories,
  filters: filters,
  mapFiltersFromApi: (response: InvoiceSearchMeta) => {
    namedFilters.Countries.options = response.VendorCountries.map(a => <TypeaheadItem<number>>{
      DisplayText: a.Description,
      Item: a.Id
    });
    namedFilters.InvoiceYear.options = response.Years.map(a => <SelectOption<number>>{ display: a.toString(), value: a });

    namedFilters.BudgetYears.options = response.BudgetYears.map(a => <SelectOption<number>>{ display: a.toString(), value: a });
    namedFilters.TrialYears.options = response.TrialYears.map(a => <SelectOption<number>>{ display: a.toString(), value: a });
    namedFilters.PersonResponsibles.options = response.PersonResponsible.map(a => <TypeaheadItem<number>>{
      DisplayText: a.LastName + ", " + a.FirstName,
      Item: a.PersonId
    });
    namedFilters.InvoiceStatus.options = [
      InvoiceStatus.New,
      InvoiceStatus.Submitted,
      InvoiceStatus.Complete
    ].map(status => {
      return {
        display: GetInvoiceStatusDescription(status),
        value: status
      }
    });
  }
}
