import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService, FtmsHttpParams } from "../../shared/ApiService";
import { QuoteDropdowns } from "./types/QuoteDropdowns";
import { Quote, QuoteCreate, QuoteUpdate } from "./types/Quote";

@Injectable()
export class QuoteModalService {

  constructor(private apiService: ApiService) {
  }

  public GetQuoteDropdowns = (vendorId: number): Observable<QuoteDropdowns> => {
    return this.apiService.GET<QuoteDropdowns>(`api/v1/vendors/${vendorId}/quotes-detail-meta`);
  }

  public GetQuoteById = (vendorId: number, quoteId: number): Observable<Quote> => {
    return this.apiService.GET<Quote>(`api/v1/vendors/${vendorId}/quotes/${quoteId}`, this.getNotFoundHandler());
  }

  public CreateQuote = (vendorId: number, createData: QuoteCreate): Observable<Quote> => {
    return this.apiService.POST<QuoteCreate, Quote>(createData, `api/v1/vendors/${vendorId}/quotes`);
  }

  public UpdateQuote = (vendorId: number, quoteId: number, createData: QuoteUpdate): Observable<Quote> => {
    return this.apiService.PUT<QuoteUpdate, Quote>(createData, `api/v1/vendors/${vendorId}/quotes/${quoteId}`);
  }

  private getNotFoundHandler = () => {
    return new FtmsHttpParams().WithOnNotFoundHandler(() => {
      return {
        continueExecutingErrorHandlers: false
      };
    });
  }
}
