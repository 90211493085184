import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "../../shared/ApiService";
import { QuoteStatus } from "../quote-create-modal/types/Quote";
import { QuoteStatusDescriptionDto, QuoteStatusDropdowns } from "./types/QuoteStatusDescriptionDto";

@Injectable()
export class QuoteEditModalService {
  constructor(private apiService: ApiService) {

  }

  public GetQuotePaymentLineItems = (vendorId: number, quoteId: number, contactId: number, personId: number, year: number): Observable<Array<VendorQuoteLineItemDto>> => {
    let url = `api/v1/vendors/${vendorId}/quotes/${quoteId}/payment-line-items?contactId=${contactId}&personId=${personId}&year=${year}`;
    return this.apiService.GET<Array<VendorQuoteLineItemDto>>(url);
  }

  public DeleteQuote = (vendorId: number, quoteId: number): Observable<void> => {
    return this.apiService.DELETE<void>(`api/v1/vendors/${vendorId}/quotes/${quoteId}`);
  }

  public GetQuoteStatusDescription = (status: QuoteStatus): Observable<QuoteStatusDescriptionDto> => {
    let url = `api/v1/quote-status-description/${status}`;
    return this.apiService.GET(url);
  }

  public GetQuoteStatusDropdownList = (): Observable<Array<QuoteStatusDropdowns>> => {
    return this.apiService.GET<Array<QuoteStatusDropdowns>>("api/v1/quote-status-description-list");
  }
}

export interface VendorQuoteLineItemDto {
  PaymentLineItem: PaymentLineItemDto,
  Assocs: Array<VendorPaymentLineItemAssocDto>
}

export interface PaymentLineItemDto {
  Id: number,
  ItemType: number,
  Year: number,
  Status: string,
  Number: string,
  ShortTitle: string,
  CurrencyName: string,
  PersonResponsibleId?: number,
  IsCommercial?: boolean,
  IsExternal?: boolean,
  PersonResponsibleName?: string,
  SourceSystemLink?: string,
  RestrictionStatus: PaymentLineItemRestriction
}

export interface VendorPaymentLineItemAssocDto {
  Id: number,
  PaymentLineItemId: number,
  VendorId: number,
  ContactId: number,
  ObjectiveName: string,
  BudgetYear: number,
  QuoteId?: number,
  QuotedAmount?: number,
  Comment: string,
  QuoteNumber:string
}

export enum PaymentLineItemRestriction {
  NoRestrictions = 0,
  NotAddedToInvoices = 1,
}
