<h1 mat-dialog-title>
  <ng-container>
    <span>Create New FSPS Quote</span>
  </ng-container>
</h1>

<div mat-dialog-content #modalContainer scrollInsetShadow>
  <form name="quote-create-form" #quoteCreateForm="ngForm" style="padding-bottom: 15px;">
    <vendor-quote-filter [VendorId]="Quote.VendorId"
                         [ValuesRequired]="true"
                         (YearSelected)="Quote.Year = $event"
                         (FieldScientistSelected)="Quote.FieldScientistId = $event"
                         (ContactSelected)="Quote.ContactId = $event"></vendor-quote-filter>
      <!--<div class="row">
    <div class="col-sm-4">
      <mat-form-field>
        <mat-label>Trial/Non-Trial Year</mat-label>
        <mat-select required [(ngModel)]="Quote.Year" #year name="year" (selectionChange)="onYearSelected($event.value)">
          <mat-option *ngFor="let year of Dropdowns.Years" [value]="year">{{year}}</mat-option>
          <mat-error *ngIf="year.errors?.required">
            Year is required
          </mat-error>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-sm-4">
      <mat-form-field>
        <mat-label>Trial/Non-Trial Responsible Person</mat-label>
        <mat-select required [disabled]="Quote.Year == null" [(ngModel)]="Quote.FieldScientistId" #fieldScientist name="field-scientist" (selectionChange)="onFieldScientistSelected($event.value)">
          <mat-option *ngFor="let a of Dropdowns.FieldScientists" [value]="a.Id">{{a.Name}}</mat-option>
          <mat-error *ngIf="fieldScientist.errors?.required">
            Responsible Person is required
          </mat-error>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-sm-4">
      <mat-form-field>
        <mat-label>Vendor/Contact</mat-label>
        <mat-select required [disabled]="Quote.FieldScientistId == null"  [(ngModel)]="Quote.ContactId" #contact name="contact">
          <mat-option *ngFor="let a of Dropdowns.Contacts" [value]="a.Id">{{a.Name}}</mat-option>
          <mat-error *ngIf="contact.errors?.required">
            Contact is required
          </mat-error>
        </mat-select>
      </mat-form-field>
    </div>
  </div>-->
</form>
</div>

<div mat-dialog-actions style="display: flex">
  <span style="flex: 1 1 auto;"></span>
  <button mat-raised-button color="accent" (click)="CancelModal()">Cancel</button>
  <button mat-raised-button color="primary" (click)="SaveQuote(quoteCreateForm)">Create</button>
</div>
