import { Component, Inject, Injectable } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Data, Params } from "@angular/router";
import { Observable } from "rxjs";
import { CurrentUserService } from "../../../shared/CurrentUser.service";
import { AppRoles } from "../../../shared/enums/AppRoles";
import { FormControlHelper } from "../../../shared/helpers/FormControlHelper";
import { ChangeDetectionService } from "../../../shared/services/change-detection.service";
import { LoadingService } from "../../../shared/services/loading-overlay/loading-overlay.component";
import { VendorDetailModalService } from "../../vendor-detail-modal/vendor-detail-modal.service";
import { InvoiceModalService } from "../invoice-modal.service";
import { Invoice, InvoiceCreate, InvoiceStatus } from "../types/Invoice";
import { InvoiceCreateDropdowns } from "../types/InvoiceCreateDropdowns";

export interface ShowInvoiceCreateModalData {
  VendorId: number;
}

@Component({
  selector: 'invoice-create-modal',
  templateUrl: 'invoice-create-modal.component.html',
  styleUrls: [
    'invoice-create-modal.component.less'
  ]
})
export class InvoiceCreateModalComponent {
  public IsNewVendor: boolean = false;
  public UserIsAdmin: boolean = false;
  public UserIsRegionalAdmin: boolean = false;
  public UserRegionalAdminCountries: number[] = [];

  public Dropdowns = {
    Years: <Array<number>>[],
    PoNumbers: <Array<string>>[],
    PrNumbers: <Array<string>>[],
    PoNumbersByYear: <{ [key: number]: Array<string> }>{},
    PrNumbersByYear: <{ [key: number]: Array<string> }>{}
  }

  public VendorId: number;
  public Year: number;
  public PurchaseOrderNumber: string;
  public PurchaseRequisitionNumber: string;
  public CreateCriteria: "PO" | "NOPO" = "PO";
  public QuotePurchaseOrderEndDates: Array<Date> = null;
  constructor(private formControlHelper: FormControlHelper,
    private loadingService: LoadingService,
    private changeDetectionService: ChangeDetectionService,
    private invoiceModalService: InvoiceModalService,
    private userService: CurrentUserService,
    private vendorService: VendorDetailModalService,
    private dialogRef: MatDialogRef<InvoiceCreateModalComponent, number>,
    @Inject(MAT_DIALOG_DATA) private showModalData: ShowInvoiceCreateModalData,
  ) {

  }

  private GetChangeDetectionData = () => {
    return {
      Year: this.Year,
      PONumber: this.PurchaseOrderNumber,
      PRNumber: this.PurchaseRequisitionNumber,
      CreateCriteria: this.CreateCriteria
    }
  }

  ngOnInit(): void {
    const vendorLoadObservable = this.vendorService.GetVendor(this.showModalData.VendorId);
    const dropdownsObservable = this.invoiceModalService.GetCreateDropdowns(this.showModalData.VendorId);
    const loadRef = this.loadingService.StartWaiting("Fetching data");
    this.VendorId = this.showModalData.VendorId;

    dropdownsObservable.subscribe(dropdowns => {
      if (dropdowns)
        this.SetDropdowns(dropdowns);

      this.changeDetectionService.SetOriginalValue(this.GetChangeDetectionData());

      this.userService.HasRole(AppRoles.PaymentAdmin).subscribe(hasRole => {
        this.UserIsAdmin = this.UserIsAdmin || hasRole;
        vendorLoadObservable.subscribe((vendor) => {
          this.userService.GetPaymentAdminCountries().subscribe(countries => {
            this.UserRegionalAdminCountries = countries;
            var isRegionalAdmin = false;
            if (this.IsNewVendor)
              isRegionalAdmin = this.UserRegionalAdminCountries.length > 0;
            else
              isRegionalAdmin = this.UserRegionalAdminCountries.find(c => c == vendor.CountryId) != null;

            this.UserIsAdmin = this.UserIsAdmin || isRegionalAdmin;
          });
        });
      });

      loadRef.Stop();
    });
  }

  public SetDropdowns = (dropdowns: InvoiceCreateDropdowns) => {
    this.Dropdowns.Years = dropdowns.PurchaseOrdersAndRequisitions.map(a => a.Year).sort();

    dropdowns.PurchaseOrdersAndRequisitions.forEach(tmp => {
      this.Dropdowns.PoNumbersByYear[tmp.Year] = tmp.PurchaseOrderNumbers;
      this.Dropdowns.PrNumbersByYear[tmp.Year] = tmp.PurchaseRequisitionNumbers;
    });
  }

  public OnYearChange = () => {
    this.Dropdowns.PoNumbers = this.Dropdowns.PoNumbersByYear[this.Year];
    this.Dropdowns.PrNumbers = this.Dropdowns.PrNumbersByYear[this.Year];
  }

  public SaveInvoice = (form: NgForm) => {
    if (!this.formControlHelper.CheckFormIsValid(form))
      return;

    const loadingRef = this.loadingService.StartWaiting("Creating new Invoice");

    const createData: InvoiceCreate = {
      DocumentIds: [],
      InvoiceStatus: InvoiceStatus.New,
      PaymentCheck: null,
      PaymentDate: null,
      PurchaseOrderNumber: this.CreateCriteria == "PO" ? this.PurchaseOrderNumber : "",
      PurchaseRequisitionNumber: this.CreateCriteria == "NOPO" ? this.PurchaseRequisitionNumber : "",
      QuotePurchaseOrderEndDates: this.QuotePurchaseOrderEndDates,
      VendorAssignedInvoiceNumber: null,
      Year: this.Year
    };

    const apiResponse = this.invoiceModalService.CreateInvoice(this.VendorId, createData);

    apiResponse.subscribe((result: Invoice) => {
      loadingRef.Stop();
      // Close modal
      this.dialogRef.close(result.Id);
    }, (error) => {
      loadingRef.Stop();
    });
  }

  public CancelModal = async () => {
    let shouldLeave = await this.CheckForChangesAndPrompt();
    if (!shouldLeave)
      return;

    this.dialogRef.close(undefined);
  }

  private async CheckForChangesAndPrompt(): Promise<boolean> {
    if (this.changeDetectionService.HasChanges(this.GetChangeDetectionData())) {
      let shouldLeave = await this.changeDetectionService.HasChangeDialog();
      if (!shouldLeave)
        return false;
    }
    return true;
  }
}

@Injectable()
export class InvoiceCreateModal {
  constructor(private dialog: MatDialog) {
  }

  public ShowModal =
    /** Displays the modal. Returns the id when the user closes the modal, or undefined if no record was created
     * @returns {Observable<number | undefined>} The ID or undefined if no record was created.
     */
    (data: ShowInvoiceCreateModalData): Observable<number | undefined> => {
      const ref = this.dialog.open(InvoiceCreateModalComponent, {
        data: data,
        width: "400px",
        disableClose: true,
        autoFocus: false,
      });

      return ref.afterClosed();
    }
}
