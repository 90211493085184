import { VendorCountry } from "./types/VendorModalDropdowns";
import { Observable } from "rxjs";
import { VendorCard } from "./types/VendorCard";
import { Injectable } from "@angular/core";
import { ApiService } from "../../shared/ApiService";
import { PagedResults } from "../../shared/interfaces/PagedResults";
import { VendorSearchParameters } from "../../components/vendor-search/types/VendorSearchParameters";

@Injectable()
export class VendorSelectionService {

    constructor(private apiService: ApiService) {
    }

    public GetVendorCountries = (): Observable<VendorCountry[]> => {
        return this.apiService.GET<VendorCountry[]>(`api/v1/vendor/countries`);
    }

    public GetVendorSearch = (params: VendorSearchParameters): Observable<PagedResults<VendorCard>> => {
        return this.apiService.POST<VendorSearchParameters, PagedResults<VendorCard>>(params, `api/v1/vendor/search`);
    }
}
