import { Component, Inject, Injectable, ViewChild, ElementRef } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { PageEvent } from "@angular/material/paginator";
import { Observable } from "rxjs";
import { LoadingService } from "../../shared/services/loading-overlay/loading-overlay.component";
import { VendorCard } from "./types/VendorCard";
import { VendorSelectionService } from "./vendor-selection-modal.service";
import { TypeaheadItem } from "../../components/typeahead/typeahead.component";
import { VendorCountry } from "./types/VendorModalDropdowns";
import * as paginator from '../../shared/services/PaginatorService';
import { PagedResults } from "../../shared/interfaces/PagedResults";
import { VendorSearchParameters } from "../../components/vendor-search/types/VendorSearchParameters";

export interface ShowVendorSelectionModalData {
  countryId?: number;
  activeContactOnly?: boolean;
  activeVendorOnly?: boolean;
  checkAgreementStatus: boolean;
}

type VendorCardDisplayData = VendorCard & {
  IsAgreementStatusOkay: boolean;
  DoesAgreementExpireSoon: boolean;
};

@Component({
  selector: 'vendor-selection-modal',
  templateUrl: 'vendor-selection-modal.component.html'
})
export class VendorSelectionModalComponent {
  public searchResults: VendorCardDisplayData[] = [];
  public searchValueVendor: string;
  public searchValueContact: string;
  public SelectedCountryId: number;
  public pageIndex: number = 0;
  public totalCount: number = 0;
  public itemsPerPage = paginator.itemsPerPageDefault();
  public pageSizeOptions = paginator.pageSizeOptions();
  public isLoading: boolean = false;
  public checkAgreementStatus: boolean;
  public removable = true;
  public singleSelectOnly: boolean = false;
  public DialogTitle: string = "Vendor - Contact Search";
  public TodaysDate: Date = new Date(new Date().setHours(0, 0, 0, 0));
  public ExpireThresholdDate: Date = new Date(this.TodaysDate.getFullYear(), this.TodaysDate.getMonth(), this.TodaysDate.getDate() + 90);
  public AgreementExpiresSoonMessage = "Agreement with this Vendor is going to expire in less than 90 days. Communicate with payment admins to extend this agreement for next growing season";

  @ViewChild('vendorSearchInput', { static: false }) vendorSearchInput: ElementRef<HTMLInputElement>;

  public Dropdowns = {
    Countries: <Array<TypeaheadItem<number>>>[],
    CountriesById: <{ [countryId: number]: VendorCountry }>{},
  }

  constructor(@Inject(MatDialogRef) private dialogRef: MatDialogRef<VendorSelectionModal, VendorCard>,
    @Inject(LoadingService) private loadingService: LoadingService,
    @Inject(MatSnackBar) private snackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) private showModalData: ShowVendorSelectionModalData,
    @Inject(VendorSelectionService) private vendorService: VendorSelectionService) {
  }

  ngOnInit(): void {
    const loadRef = this.loadingService.StartWaiting("Loading Current Vendors...");
    const dropdownObservable = this.vendorService.GetVendorCountries();

    dropdownObservable.subscribe(countries => {
      this.Dropdowns.Countries = countries.map(x => <TypeaheadItem<number>>{
        Item: x.CountryId,
        DisplayText: x.CountryName
      });

      this.checkAgreementStatus = this.showModalData.checkAgreementStatus;

      if (this.showModalData.countryId != null)
        this.SelectedCountryId = this.showModalData.countryId;

      loadRef.Stop();
      // The autoFocusDirective doesn't persist when a loading dialog pops up
      // so manually set focus to the search input if vendors were passed in
      setTimeout(() => {
        this.vendorSearchInput.nativeElement.focus();
      }, 250);
    });
  }

  public onPageChanged = (e: PageEvent) => {
    this.itemsPerPage = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.UpdateItems();
  }

  public Search = () => {
    this.pageIndex = 0;
    this.totalCount = 0;
    this.UpdateItems();
  }

  private UpdateItems = () => {
    this.searchResults = [];
    this.isLoading = true;

    const searchParameters: VendorSearchParameters = {
      VendorName: this.searchValueVendor,
      ContactName: this.searchValueContact,
      CountryIds: [this.SelectedCountryId],
      ActiveContactOnly: this.showModalData.activeContactOnly,
      ActiveVendorOnly: this.showModalData.activeVendorOnly,
      IsDescending: false,
      ItemsPerPage: this.itemsPerPage,
      Page: this.pageIndex,
      OrderBy: "",
      VendorCode: null,
      VendorZip: "",
      VendorActive: true,
      TrialNontrialNumber:""
    }

    this.vendorService.GetVendorSearch(searchParameters)
      .subscribe((c: PagedResults<VendorCard>) => {
        this.searchResults = c.Rows.map((vendor) => {
          var tmp: VendorCardDisplayData = <any>vendor;

          tmp.DoesAgreementExpireSoon = false;

          if (tmp.AgreementEndDate != null) {
            tmp.AgreementEndDate = new Date(tmp.AgreementEndDate);
            tmp.DoesAgreementExpireSoon = tmp.AgreementEndDate < this.ExpireThresholdDate;
          }

          tmp.IsAgreementStatusOkay = this.IsVendorAgreementStatusOkay(tmp);

          return tmp;
        });
        this.totalCount = c.TotalCount;
        this.isLoading = false;
      });
  }

  private IsVendorAgreementStatusOkay = (vendor: VendorCard) => {

    if (vendor.AgreementRequired == false)
      return true;

    if (vendor.AgreementEndDate === null || vendor.AgreementEndDate === undefined)
      return false;

    return vendor.AgreementEndDate >= this.TodaysDate;
  }

  public ClearContactSearch = () => {
    this.searchValueContact = "";
  }

  public ClearVendorSearch = () => {
    this.searchValueVendor = "";
  }

  public AddVendor = (row: VendorCard) => {
    this.dialogRef.close(row);
  }

  public Cancel = () => {
    this.dialogRef.close(undefined);
  }
}

@Injectable()
export class VendorSelectionModal {
  constructor(@Inject(MatDialog) private dialog: MatDialog) {

  }

  public ShowModal =
    /** Displays passed in Vendor and allows for searching other Vendors
     * @returns {Observable<VendorCard | undefined>} The selected Vendors or undefined if no Vendors were selected.
     */
    (data: ShowVendorSelectionModalData): Observable<VendorCard | undefined> => {
      const ref = this.dialog.open(VendorSelectionModalComponent, {
        data: data,
        width: "900px",
        disableClose: true,
        autoFocus: false,
      });

      return ref.afterClosed();
    }
}
