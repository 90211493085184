import { AfterViewInit, Component, ViewChild } from "@angular/core";
import { VendorSearchRow } from './types/VendorSearchRow';
import { VendorSearchService } from "./vendor-search.service";
import { VendorSearchParameters } from "./types/VendorSearchParameters";
import { map } from "rxjs";
import { Subscription } from "rxjs";
import { VendorDetailModal } from "../../modals/vendor-detail-modal/vendor-detail-modal.component";
import { filterDefinitions } from "./vendor-search-filters";
import { LoadingService } from "../../shared/services/loading-overlay/loading-overlay.component";
import { SearchType } from "../../shared/services/search-storage.service";
import { Router } from "@angular/router";
import { CurrentUserService } from "../../shared/CurrentUser.service";
import { AppRoles } from "../../shared/enums/AppRoles";
import { GenericSearchPageComponent } from "../generic-search-page/generic-search-page.component";

@Component({
  selector: 'vendor-search',
  templateUrl: 'vendor-search.component.html'
})
export class VendorSearch implements AfterViewInit {

  @ViewChild(GenericSearchPageComponent, { static: true }) public SearchPageComponent: GenericSearchPageComponent<VendorSearchRow, VendorSearchParameters>;

  public SearchHistoryType: SearchType = SearchType.Vendor;
  public UserIsAdmin: boolean = false;

  public navigationExtras: Partial<VendorSearchParameters>;
  public filterCategories = filterDefinitions.filterCategories;

  constructor(
    private searchService: VendorSearchService,
    private router: Router,
    private loadingService: LoadingService,
    private vendorDetailModal: VendorDetailModal,
    private userService: CurrentUserService
  ) {
    this.navigationExtras = this.router.getCurrentNavigation().extras.state;
  }

  ngAfterViewInit(): void {

    this.userService.HasRole(AppRoles.PaymentAdmin).subscribe(hasRole => {
      this.UserIsAdmin = this.UserIsAdmin || hasRole
    });
    this.userService.GetPaymentAdminCountries().subscribe(countries => {
      this.UserIsAdmin = this.UserIsAdmin || (countries as number[]).length > 0;
    });

    this.SearchPageComponent.Options.SearchForRows = (searchParameters) => {
      return this.searchService.SearchVendors(searchParameters);
    }
    this.SearchPageComponent.Options.CreateRow = () => this.create();

    this.SearchPageComponent.Options.EditRow = (row) => {

      return this.vendorDetailModal.ShowModal({
        vendorId: row.VendorId
      }).pipe(
        map(a => a !== undefined)
      );
    };

    this.SearchPageComponent.Options.SearchHistoryType = SearchType.Vendor;

    const loadRef = this.loadingService.StartWaiting("Loading Options...");
    this.searchService.GetDropdowns().subscribe((response) => {
      filterDefinitions.mapFiltersFromApi(response);
      this.SearchPageComponent.InitializeFilters();

      loadRef.Stop();
    });
  }

  private subscriptionsToClose: Subscription[] = [];
  ngOnDestroy(): void {
    [...this.subscriptionsToClose].forEach(s => {
      if (s != null && !s.closed) {
        s.unsubscribe();
      }
    });
  }

  create = () => {
    return this.vendorDetailModal.ShowModal({
      vendorId: null
    }).pipe(
      map(a => a !== undefined)
    );
  }

  downloadLinkHref = () => {
    var parameters = this.SearchPageComponent.GetSearchParameters(this.SearchPageComponent.GetCurrentPageSettings());
    window.open(this.searchService.GetExcelDownloadUrl(parameters));
  }
}
