import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CurrentUserService } from '../../shared/CurrentUser.service';
import { IQuickLinks } from '../../shared/quicklinks/IQuickLinks';
import { IQuickLinkGroups } from '../../shared/quicklinks/IQuickLinks';
import { QuickLinksService } from '../../shared/services/quickLinks.service';
import { AppInfoService } from '../../singletons/app-info.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {


  public MyLinkGroups: IQuickLinkGroups[];
  public FtmsLink: string;

  constructor(private router: Router, private quickLinksService: QuickLinksService, private currentUserService: CurrentUserService, public service: AppInfoService) {
  }

  ngOnInit(): void {
   
    this.currentUserService.GetApiUser().subscribe(user => this.MyLinkGroups = this.quickLinksService.getQuickLinkGroups(user));
    this.service.getAppStartupInfo().subscribe(info => {
      if (info)
        this.FtmsLink = info.FtmsLink;
    });
  }

  navigateToFtms(route: string) {
    window.open(this.FtmsLink + route, '_blank').focus();
  }

  navigateTo = (quickLink: IQuickLinks) => {
    if (quickLink.isFTMSLink) {
      this.navigateToFtms(quickLink.route);
    }
    else {
      if (quickLink.navigationExtraState != undefined)
        this.router.navigateByUrl(quickLink.route, {
          state: quickLink.navigationExtraState
        });
      else
        this.router.navigateByUrl(quickLink.route);
    }

    
  }
}
