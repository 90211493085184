import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ApiService } from "../ApiService";

@Injectable()
export class SearchStorageService {

  constructor(@Inject(HttpClient) httpClient: HttpClient, private apiService: ApiService) {
  }

  storeSearch = (searchType: SearchType, searchJson: string): Promise<number> => {
    let data = {
      SearchType: searchType,
      SearchJson: searchJson,
    };
    return this.apiService.POST<any,number>(data, "api/v1/users/me/searches").toPromise();
    
  }

  getLastSearch = (searchType: SearchType): Promise<SearchRecord> => {
    return this.apiService.GET<SearchRecord>(`api/v1/users/me/searches/bytype/${searchType}/latest`).toPromise();
  }

  deleteStoredSearch = (id: number): Promise<void> => {
    this.apiService.DELETE(`api/v1/users/me/searches/byid/${id}`);
    return;
  }
}

export class SearchRecord {
  public id;
  public Name;
  public SearchJson;
  public SearchType;
}

export enum SearchType {
  Program = 1,
  Protocol = 2,
  TrialPlan = 3,
  TrialActual = 4,
  Placeholder = 5,
  NonTrial = 6,
  ResponsibilityReport = 7,
  EmailHistory = 8,
  Vendor = 9,
  Quotes = 10,
  Agreements = 11,
  Invoices = 12
}
