<table mat-table [dataSource]="agreements" matSort (matSortChange)="onSortChange($event)">
  <ng-container matColumnDef="VendorName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Vendor Name</th>
    <td mat-cell *matCellDef="let row">
      {{row.VendorName}}
    </td>
  </ng-container>
  <ng-container matColumnDef="VendorCode">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Vendor Code</th>
    <td mat-cell *matCellDef="let row">
      {{row.VendorCode}}
    </td>
  </ng-container>
  <ng-container matColumnDef="VendorCountry">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Vendor Country</th>
    <td mat-cell *matCellDef="let row">
      {{row.VendorCountry}}
    </td>
  </ng-container>
  <ng-container matColumnDef="AgreementYear">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Agreement Year</th>
    <td mat-cell *matCellDef="let row">
      {{row.AgreementYear}}
    </td>
  </ng-container>
  <ng-container matColumnDef="AgreementNumber">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Agreement Number</th>
    <td mat-cell *matCellDef="let row">
      {{row.AgreementNumber}}
    </td>
  </ng-container>
  <ng-container matColumnDef="AgreementTypeName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Agreement Type</th>
    <td mat-cell *matCellDef="let row">
      {{row.AgreementTypeName}}
    </td>
  </ng-container>
  <ng-container matColumnDef="AgreementStart">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Agreement Start Date</th>
    <td mat-cell *matCellDef="let row">
      {{row.AgreementStart | date:'d-LLL-y'}}
    </td>
  </ng-container>
  <ng-container matColumnDef="AgreementEnd">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Agreement End Date</th>
    <td mat-cell *matCellDef="let row">
      {{row.AgreementEnd | date:'d-LLL-y'}}
    </td>
  </ng-container>
  <ng-container matColumnDef="PaymentPlan">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment Plan</th>
    <td mat-cell *matCellDef="let row">
      {{ row.PaymentPlan }}
    </td>
  </ng-container>
  <ng-container matColumnDef="TimelineDays">
    <th mat-header-cell style="text-align: right" *matHeaderCellDef mat-sort-header>Timeline (Days)</th>
    <td mat-cell *matCellDef="let row" class="text-right">
      {{row.TimelineDays}}
    </td>
  </ng-container>
  <ng-container matColumnDef="AgreementActive">
    <th mat-header-cell style="text-align: right" *matHeaderCellDef mat-sort-header>Agreement Active</th>
    <td mat-cell *matCellDef="let row" class="text-right">
      {{row.AgreementActive ? "Yes" : "No"}}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="Columns; sticky: true"></tr>
  <tr mat-row (dblclick)="doubleClick(row)"
      (click)="this.selection.toggle(row)"
      [class.selected]="selection.isSelected(row)"
      *matRowDef="let row; columns: Columns"></tr>
</table>
