

<mat-chip-list #chipList>
    <mat-chip *ngFor="let item of SelectedItems"
              (removed)="remove(item)">
        {{item.DisplayText}}
        <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>

    <input matInput
           autocomplete="off"
           name="trial_responsible-multi-{{id}}"
           [matAutocomplete]="ftmsAutocompleteMulti"
           [matChipInputFor]="chipList"
           [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
           [formControl]="inputControl"
           (matChipInputTokenEnd)="add($event)"
           #autocompleteTrigger="matAutocompleteTrigger"
           autocompleteValidator />

</mat-chip-list>

<mat-autocomplete #ftmsAutocompleteMulti="matAutocomplete"
                  [displayWith]="DisplayItem"
                  (optionSelected)="autocompleteSelect($event)"
                  (opened)="autocompleteOpened()">
    <mat-option *ngFor="let item of FilteredList | async" [value]="item">
        {{item.DisplayText}}
    </mat-option>
</mat-autocomplete>
