import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "../../shared/ApiService";
import { PagedResults } from "../../shared/interfaces/PagedResults";
import { InvoiceSearchMeta } from "./types/InvoiceSearchMeta";
import { InvoiceSearchParameters } from "./types/InvoiceSearchParameters";
import { InvoiceSearchRow } from "./types/InvoiceSearchRow";

@Injectable()
export class InvoiceSearchService {
  constructor(private apiService: ApiService) {

  }

  public GetInvoiceSearchFilters = (): Observable<InvoiceSearchMeta> => {
    return this.apiService.GET<InvoiceSearchMeta>("api/v1/invoice-search-meta");
  }

  public Search = (parameters: InvoiceSearchParameters): Observable<PagedResults<InvoiceSearchRow>> => {
    return this.apiService.POST<InvoiceSearchParameters, PagedResults<InvoiceSearchRow>>(parameters, `api/v1/invoices-search`);
  }
}
