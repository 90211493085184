<div class="corteva-header">
  <div class="corteva-header-content">
    <div class="corteva-header-content-wrapper" style="margin-right: 365px;">
      <div class="corteva-heading">
        <div class="corteva-heading-logo">
          <a class="home-link pull-left" [routerLink]="['home']">
            <img class="logo" src="assets/styles/images/ftmsLogo-dark.png">
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="corteva-header-content-secondary">
    <div class="user-info">
      <div class="user-name">
        <em class="pull-right" (ctrl-click)="viewDiagProfile()">{{UserDisplayName}}</em>
        <div>
          <ul class="list-inline pull-right">
            <li *ngIf="environmentName != 'PROD'">
              <div>{{ environmentName }}</div>
            </li>
          </ul>
        </div>
      </div>
      <div class="user-avatar">
        <img src="assets/styles/images/guest-avatar.svg" #profilePicture height="74" width="74" />
      </div>
    </div>
    <img src="assets/styles/images/secondary-logo.svg" height="168" width="112" />
  </div>
</div>
<div>
  <div class="nav-container">
    <div class="nav-container" style="flex:1 1 auto">
      <div class="nav-menu-item" *ngFor="let menuItem of mainMenu">
        <div *ngIf="!menuItem.items" class="nav-menu-item-label" style="cursor:pointer" [routerLink]="menuItem.url">{{menuItem.name}}</div>

        <div *ngIf="menuItem.items" class="nav-menu-item-label">{{menuItem.name}}</div>
        <ul *ngIf="menuItem.items" class="dropdown-menu">
          <li *ngFor="let item of menuItem.items">
            <a [routerLink]="item.url">{{item.name}}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
