import { Component, Inject, Injectable, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, FormGroup, NgForm, NgModelGroup } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { forkJoin, Observable, of } from "rxjs";
import { TypeaheadItem } from "../../components/typeahead/typeahead.component";
import { IComponentDirty } from "../../shared/interfaces/IComponentDirty";
import { ChangeDetectionService } from "../../shared/services/change-detection.service";
import { DialogService } from "../../shared/services/dialog.service";
import { LoadingService } from "../../shared/services/loading-overlay/loading-overlay.component";
import { CountryForVendor, SubdivisionForVendor, VendorDetailDropdowns, VendorType } from "./types/VendorDetailDropdowns";
import { Contact, VendorCreate, VendorUpdate, Vendor } from "./types/Vendor";
import { VendorDetailModalService } from "./vendor-detail-modal.service";
import { VendorAgreementModal } from "../vendor-agreement-modal/vendor-agreement-modal.component";
import { VendorAgreement } from "../vendor-agreement-modal/types/VendorAgreement";
import { VendorAgreementModalService } from "../vendor-agreement-modal/vendor-agreement-modal.service";
import { SelectionModel } from "@angular/cdk/collections";
import { VendorQuote } from "./types/VendorQuote";
import { CurrentUserService } from "../../shared/CurrentUser.service";
import { AppRoles } from "../../shared/enums/AppRoles";
import { QuoteCreateModal } from "../quote-create-modal/quote-create-modal.component";
import { QuoteEditModal } from "../quote-edit-modal/quote-edit-modal.component";
import { GetInvoiceStatusDescription, Invoice } from "../invoice-modal/types/Invoice";
import { IModalComponent } from "../../components/direct-modal-link/Types/IModalComponent";
import { Data, Params } from "@angular/router";
import { InvoiceEditModal } from "../invoice-modal/edit/invoice-edit-modal.component";
import { InvoiceCreateModal } from "../invoice-modal/create/invoice-create-modal.component";
import { InvoiceModalService } from "../invoice-modal/invoice-modal.service";
import { InvoiceFilterComponent } from "../../components/invoice-filter/invoice-filters.component";
import { QuoteEditModalService } from "../quote-edit-modal/quote-edit-modal.service";
import { QuoteStatusDropdowns } from "../quote-edit-modal/types/QuoteStatusDescriptionDto";

export interface ShowVendorDetailModalData {
  /** The VendorDetail ID to show. If null, then the user can create a new vendor */
  vendorId?: number;
  isFromQuote?: boolean;
  isFromInvoice?: boolean;
}

@Component({
  selector: 'vendor-detail-modal',
  templateUrl: 'vendor-detail-modal.component.html'
})
export class VendorDetailModalComponent implements OnInit, IComponentDirty {
  private AllCountries: Array<CountryForVendor>;
  private AllSubdivisions: Array<SubdivisionForVendor>;
  private changeDetectionService: ChangeDetectionService = null;
  private DataLoaded: boolean = false;
  public IsNewVendor: boolean = false;
  public UserIsAdmin: boolean = false;
  public UserIsRegionalAdmin: boolean = false;
  public UserRegionalAdminCountries: number[] = [];
  public contactGroup: string = "contacts";
  public Agreements: Array<VendorAgreement>;
  public AllQuotes: Array<VendorQuote>;
  public Quotes: Array<VendorQuote>;
  public AllInvoices: Array<Invoice>;
  public Invoices: Array<Invoice>;
  public agreementSelection = new SelectionModel<VendorAgreement>(false);
  public selectedTab: number;
  public isFromQuote?: boolean;
  public isFromInvoice?: boolean;
  public quoteStatusDropdowns: QuoteStatusDropdowns[] = [];
  @ViewChild(InvoiceFilterComponent, { static: true }) public InvoiceFilterComponent: InvoiceFilterComponent;

  public QuoteFilters = {
    Year: <number>undefined,
    FieldScientistId: <number>undefined,
    ContactId: <number>undefined
  }

  public InvoiceFilters = {
    Year: <number>undefined,
    PurchaseOrderNumber: <string>undefined,
    PurchaseRequisitionNumber: <string>undefined
  }

  public Dropdowns = {
    Countries: <Array<TypeaheadItem<number>>>[],
    Subdivisions: <Array<TypeaheadItem<number>>>[],
    VendorTypes: <Array<VendorType>>[],
  }

  public Vendor = {
    Id: <number>undefined,
    Name: <string>undefined,
    Code: <string>undefined,
    VendorTypeId: <number>undefined,
    AddressLine1: <string>undefined,
    AddressLine2: <string>undefined,
    AddressLine3: <string>undefined,
    City: <string>undefined,
    CountryId: <number>undefined,
    SubdivisionId: <number>undefined,
    Zip: <string>undefined,
    Email: <string>undefined,
    Fax: <string>undefined,
    Phone: <string>undefined,
    Po: <boolean>true,
    PoComment: <string>undefined,
    AgreementRequired: <boolean>true,
    AgreementNotRequiredReason: <string>undefined,
    Active: <boolean>null,
    Contacts: <Contact[]>[],
    VendorComments: <string>undefined,
    CreateDate: <Date>null,
    CreatedByPersonId: <number>undefined,
    CreatedByName: <string>undefined,
    UpdateDate: <Date>null,
    UpdatedByPersonId: <number>undefined,
    UpdatedByName: <string>undefined,
  };

  public BooleanDropdown = [
    {
      name: "Yes",
      value: true
    },
    {
      name: "No",
      value: false
    }
  ];

  constructor(private dialogRef: MatDialogRef<VendorDetailModal, boolean>,
    @Inject(MAT_DIALOG_DATA) private showModalData: ShowVendorDetailModalData,
    public dialog: MatDialog,
    private snackbar: MatSnackBar,
    private loadingService: LoadingService,
    private vendorService: VendorDetailModalService,
    public dialogService: DialogService,
    private vendorAgreementService: VendorAgreementModalService,
    private vendorAgreementModal: VendorAgreementModal,
    private quoteCreateModal: QuoteCreateModal,
    private quoteEditModal: QuoteEditModal,
    private userService: CurrentUserService,
    private invoiceEditModal: InvoiceEditModal,
    private invoiceCreateModal: InvoiceCreateModal,
    private invoiceService: InvoiceModalService,
    private quoteEditModalService: QuoteEditModalService
  ) {
    this.changeDetectionService = new ChangeDetectionService(dialogService);
    this.selectedTab = 3;
  }

  ngOnInit(): void {
    this.Vendor.Id = this.showModalData.vendorId;
    this.isFromQuote = this.showModalData.isFromQuote;
    this.isFromInvoice = this.showModalData.isFromInvoice;
    const dropdownsObservable = this.vendorService.GetVendorDropdowns();
    const loadRef = this.loadingService.StartWaiting("Fetching Vendor");
    let vendorLoadObservable: Observable<Vendor>;
    let vendorAgreementObservable: Observable<Array<VendorAgreement>>;
    let vendorQuoteObservable: Observable<Array<VendorQuote>>;
    let invoiceObservable: Observable<Array<Invoice>>;

    this.quoteEditModalService.GetQuoteStatusDropdownList().subscribe((dropdowns) => {
      this.quoteStatusDropdowns = dropdowns;
    });

    if (this.showModalData.vendorId == null) {
      vendorLoadObservable = of(null);
      vendorAgreementObservable = of(null);
      vendorQuoteObservable = of(null);
      invoiceObservable = of(null);
      this.IsNewVendor = true;
      this.AddContact();
    }
    else {
      vendorLoadObservable = this.vendorService.GetVendor(this.showModalData.vendorId);
      vendorAgreementObservable = this.vendorAgreementService.GetAgreementsByVendorId(this.showModalData.vendorId);
      vendorQuoteObservable = this.vendorService.GetVendorQuotesByVendorId(this.showModalData.vendorId);
      invoiceObservable = this.invoiceService.GetInvoicesForVendor(this.showModalData.vendorId);
    }

    forkJoin(dropdownsObservable, vendorLoadObservable, vendorAgreementObservable, vendorQuoteObservable, invoiceObservable)
      .subscribe(([dropdowns, vendor, agreements, quotes, invoices]) => {
        if (dropdowns) {
          this.SetDropdowns(dropdowns);
        }

        if (vendor) {
          this.SetVendorData(vendor);

          this.FilterSubdivisionsForVendor();
        }

        if (agreements)
          this.Agreements = agreements;

        if (quotes) {
          this.Quotes = quotes;
          this.AllQuotes = quotes;
        }

        if (invoices) {
          this.Invoices = invoices;
          this.AllInvoices = invoices;
          this.UpdateInvoiceFilters();
        }

        this.DataLoaded = true;
        this.changeDetectionService.SetOriginalValue(this.Vendor);

        this.userService.HasRole(AppRoles.PaymentAdmin).subscribe(hasRole => {
          this.UserIsAdmin = this.UserIsAdmin || hasRole;

          this.userService.GetPaymentAdminCountries().subscribe(countries => {
            this.UserRegionalAdminCountries = countries;
            var isRegionalAdmin = false;
            if (this.IsNewVendor)
              isRegionalAdmin = this.UserRegionalAdminCountries.length > 0;
            else
              isRegionalAdmin = this.UserRegionalAdminCountries.find(c => c == this.Vendor.CountryId) != null;

            this.UserIsAdmin = this.UserIsAdmin || isRegionalAdmin;

            if (!hasRole && isRegionalAdmin)
              this.Dropdowns.Countries = this.AllCountries.filter(c => this.UserRegionalAdminCountries.indexOf(c.Id) > -1).map(x => <TypeaheadItem<number>>{
                DisplayText: x.Name,
                Item: x.Id
              });
          });
        });

        loadRef.Stop();
      });
  }

  private onlyUnique = <TValue>(value: TValue, index: number, self: Array<TValue>) => {
    return self.indexOf(value) === index;
  }

  private UpdateInvoiceFilters = () => {
    this.InvoiceFilterComponent.SetDropdowns({
      PurchaseOrdersForVendor: this.AllInvoices.map(a => a.PurchaseOrderNumber)
        .filter(this.onlyUnique)
        .filter(a => a !== null && a !== undefined && a !== "")
        .map(a => {
        return {
          Number: a
        };
      }),
      PurchaseRequisitionsForVendor: this.AllInvoices.map(a => a.PurchaseRequisitionNumber)
        .filter(this.onlyUnique)
        .filter(a => a !== null && a !== undefined && a !== "")
        .map(a => {
        return {
          Number: a
        };
      }),
      Years: this.AllInvoices.map(a => a.Year).filter(this.onlyUnique)
    });
  }

  GetInvoiceStatusDescription = GetInvoiceStatusDescription;

  IsComponentDirty = (): boolean => {
    return this.changeDetectionService.HasChanges(this.Vendor);
  };

  public SetDropdowns = (dropdowns: VendorDetailDropdowns) => {
    this.Dropdowns.Countries = dropdowns.CountriesForVendor.map(x => <TypeaheadItem<number>>{
      DisplayText: x.Name,
      Item: x.Id
    });
    this.AllCountries = dropdowns.CountriesForVendor;
    this.AllSubdivisions = dropdowns.SubdivisionsForVendor;
    this.Dropdowns.VendorTypes = dropdowns.VendorTypes;
  }

  public FilterSubdivisionsForVendor = () => {
    this.Dropdowns.Subdivisions = this.AllSubdivisions.filter(s => s.CountryId === this.Vendor.CountryId).map(x => <TypeaheadItem<number>>{
      DisplayText: x.SubdivisionName,
      Item: x.SubdivisionId
    });
  }

  public SetVendorData = (vendor: Vendor) => {
    this.Vendor.Id = vendor.Id;
    this.Vendor.Name = vendor.Name;
    this.Vendor.Code = vendor.Code;
    this.Vendor.VendorTypeId = vendor.VendorTypeId;
    this.Vendor.AddressLine1 = vendor.AddressLine1;
    this.Vendor.AddressLine2 = vendor.AddressLine2;
    this.Vendor.AddressLine3 = vendor.AddressLine3;
    this.Vendor.City = vendor.City;
    this.Vendor.CountryId = vendor.CountryId;
    this.Vendor.SubdivisionId = vendor.SubdivisionId;
    this.Vendor.Zip = vendor.Zip;
    this.Vendor.Fax = vendor.Fax;
    this.Vendor.Email = vendor.Email;
    this.Vendor.Phone = vendor.Phone;
    this.Vendor.Po = vendor.Po;
    this.Vendor.PoComment = vendor.PoComment;
    this.Vendor.AgreementRequired = vendor.AgreementRequired;
    this.Vendor.AgreementNotRequiredReason = vendor.AgreementNotRequiredReason;
    this.Vendor.Active = vendor.Active;
    this.Vendor.VendorComments = vendor.VendorComments;
    this.Vendor.CreateDate = vendor.CreateDate;
    this.Vendor.CreatedByPersonId = vendor.CreatedByPersonId;
    this.Vendor.CreatedByName = vendor.CreatedByName;
    this.Vendor.UpdateDate = vendor.UpdateDate;
    this.Vendor.UpdatedByName = vendor.UpdatedByName;
    this.Vendor.UpdatedByPersonId = vendor.UpdatedByPersonId;
    if (vendor.Contacts) {
      this.Vendor.Contacts = vendor.Contacts;
      this.Vendor.Contacts.forEach(r => {
        r.RecId = this.getNextContactId();
      });
    }
  }

  public onQuoteYearFilterSelected(year: number) {
    this.QuoteFilters.Year = year;
    this.QuoteFilters.FieldScientistId = null;
    this.QuoteFilters.ContactId = null;
    this.filterQuotes();
  }

  public onFieldScientistFilterSelected(fieldScientistId: number) {
    this.QuoteFilters.FieldScientistId = fieldScientistId;
    this.QuoteFilters.ContactId = null;
    this.filterQuotes();
  }

  public onContactFilterSelected(contactId: number) {
    this.QuoteFilters.ContactId = contactId;
    this.filterQuotes();
  }

  private filterQuotes = () => {
    this.Quotes = this.AllQuotes;
    if (this.QuoteFilters.Year)
      this.Quotes = this.Quotes.filter(q => q.Year == this.QuoteFilters.Year);

    if (this.QuoteFilters.FieldScientistId)
      this.Quotes = this.Quotes.filter(q => q.FieldScientistId == this.QuoteFilters.FieldScientistId);

    if (this.QuoteFilters.ContactId)
      this.Quotes = this.Quotes.filter(q => q.ContactId == this.QuoteFilters.ContactId);
  }

  public onInvoiceYearFilterSelected(year: number) {
    this.InvoiceFilters.Year = year;
    this.InvoiceFilters.PurchaseOrderNumber = null;
    this.InvoiceFilters.PurchaseRequisitionNumber = null;
    this.filterInvoices();
  }

  public onPurchaseOrderFilterSelected(poNumber: string) {
    this.InvoiceFilters.PurchaseOrderNumber = poNumber;
    this.InvoiceFilters.PurchaseRequisitionNumber = null;
    this.filterInvoices();
  }

  public onPurchaseRequisitionFilterSelected(prNumber: string) {
    this.InvoiceFilters.PurchaseRequisitionNumber = prNumber;
    this.filterInvoices();
  }

  private filterInvoices = () => {
    this.Invoices = this.AllInvoices;
    if (this.InvoiceFilters.Year)
      this.Invoices = this.Invoices.filter(q => q.Year == this.InvoiceFilters.Year);

    if (this.InvoiceFilters.PurchaseOrderNumber)
      this.Invoices = this.Invoices.filter(q => q.PurchaseOrderNumber == this.InvoiceFilters.PurchaseOrderNumber);

    if (this.InvoiceFilters.PurchaseRequisitionNumber)
      this.Invoices = this.Invoices.filter(q => q.PurchaseRequisitionNumber == this.InvoiceFilters.PurchaseRequisitionNumber);
  }

  public RemoveContact = (contact: Contact) => {
    if (this.Vendor.Contacts.length === 1)
      this.snackbar.open("There must be at least 1 Contact per Vendor.", null, {
        duration: 5000,
      });
    else
      this.Vendor.Contacts = this.Vendor.Contacts.filter(r => r.Id != contact.Id);
  }

  public SaveVendor = (form: NgForm, closeAfterSave: boolean) => {
    if (!this.CheckFormIsValid(form))
      return;

    const loadingRef = this.loadingService.StartWaiting(this.IsNewVendor ? "Creating new Vendor" : "Updating Vendor");

    const updateData: VendorUpdate = {
      Name: this.Vendor.Name,
      Code: this.Vendor.Code,
      VendorTypeId: this.Vendor.VendorTypeId,
      AddressLine1: this.Vendor.AddressLine1,
      AddressLine2: this.Vendor.AddressLine2,
      AddressLine3: this.Vendor.AddressLine3,
      City: this.Vendor.City,
      CountryId: this.Vendor.CountryId,
      SubdivisionId: this.Vendor.SubdivisionId,
      Zip: this.Vendor.Zip,
      Email: this.Vendor.Email,
      Fax: this.Vendor.Fax,
      Phone: this.Vendor.Phone,
      Po: this.Vendor.Po,
      PoComment: this.Vendor.PoComment,
      AgreementRequired: this.Vendor.AgreementRequired,
      AgreementNotRequiredReason: this.Vendor.AgreementNotRequiredReason,
      Active: this.Vendor.Active,
      Contacts: this.Vendor.Contacts,
      VendorComments: this.Vendor.VendorComments
    };
    let apiResponse: Observable<Vendor>;

    if (this.IsNewVendor) {
      const createData: VendorCreate = {
        Name: this.Vendor.Name,
        Code: this.Vendor.Code,
        VendorTypeId: this.Vendor.VendorTypeId,
        AddressLine1: this.Vendor.AddressLine1,
        AddressLine2: this.Vendor.AddressLine2,
        AddressLine3: this.Vendor.AddressLine3,
        City: this.Vendor.City,
        CountryId: this.Vendor.CountryId,
        SubdivisionId: this.Vendor.SubdivisionId,
        Zip: this.Vendor.Zip,
        Email: this.Vendor.Email,
        Fax: this.Vendor.Fax,
        Phone: this.Vendor.Phone,
        Po: this.Vendor.Po,
        PoComment: this.Vendor.PoComment,
        AgreementRequired: this.Vendor.AgreementRequired,
        AgreementNotRequiredReason: this.Vendor.AgreementNotRequiredReason,
        Active: this.Vendor.Active,
        Contacts: this.Vendor.Contacts,
        VendorComments: this.Vendor.VendorComments
      }

      apiResponse = this.vendorService.CreateVendor(createData);
    }
    else {
      apiResponse = this.vendorService.UpdateVendor(this.Vendor.Id, updateData);
    }

    apiResponse.subscribe(result => {
      this.SetVendorData(result);

      this.changeDetectionService.SetOriginalValue(this.Vendor);
      this.IsNewVendor = false;
      loadingRef.Stop();
      if (closeAfterSave) {
        this.dialogRef.close(true);
      }
    }, (error) => {
      loadingRef.Stop();
    });
  }

  public ToggleActive = (form: NgForm) => {
    this.Vendor.Active = !this.Vendor.Active;
    this.SaveVendor(form, false);
  }

  private GetControls = (formGroup: FormGroup): Array<AbstractControl> => {
    if (!formGroup || !formGroup.controls)
      return [];

    var controls: Array<AbstractControl> = [];
    for (const ctrlKey in formGroup.controls) {
      var control = formGroup.controls[ctrlKey];
      if (control instanceof FormGroup) {
        controls = controls.concat(this.GetControls(control));
      }
      else {
        controls.push(control);
      }
    }

    return controls;
  }

  public CheckFormIsValid = (form: NgForm): boolean => {
    let hasErrors = false;

    var controls: Array<AbstractControl> = this.GetControls(form.form);
    for (const ctrl of controls) {
      if (ctrl.errors) {
        ctrl.markAllAsTouched();
        hasErrors = true;
      }
    }


    if (hasErrors) {
      if (!form.form.controls[this.contactGroup].valid)
        this.snackbar.open("At least one contact must be filled in. Please fix the highlighted contact fields and try again.", null, {
          duration: 5000,
        });
      else
        this.snackbar.open("There are validation errors. Please fix the highlighted fields and try again.", null, {
          duration: 5000,
        });
    }

    return !hasErrors;
  }

  public onCountrySelected = (countryId: number) => {
    this.FilterSubdivisionsForVendor();

    this.Vendor.Contacts.forEach(r => {
      r.CountryId = countryId;
    });
  }

  public AddContact = () => {
    const rec: Contact = {
      Id: null,
      RecId: this.getNextContactId(),
      Name: null,
      VendorId: this.Vendor.Id,
      AddressLine1: null,
      AddressLine2: null,
      AddressLine3: null,
      City: null,
      CountryId: this.Vendor.CountryId,
      SubdivisionId: this.Vendor.SubdivisionId,
      Zip: null,
      Phone: null,
      Fax: null,
      Email: null,
      Active: false,
      TrialAssociated: false
    };
    this.Vendor.Contacts = [...this.Vendor.Contacts, rec];
  }

  public AddAgreement = () => {
    let canActivate = this.Agreements == null ? true : false;
    if (this.Agreements != null)
      canActivate = this.Agreements.every(a => a.Active == false);

    this.vendorAgreementModal.ShowModal({
      VendorId: this.Vendor.Id,
      CanActivate: canActivate
    }).subscribe((agreementId: number) => {
      if (agreementId)
        this.updateAgreementItems();
    });
  }

  public EditAgreement = (row: VendorAgreement) => {
    if (!this.UserIsAdmin) {
      this.snackbar.open("Only Payment Admins may edit agreements.", null, {
        duration: 5000,
      });
      return;
    }
    if (!this.Vendor.AgreementRequired)
      return;

    if (!this.agreementSelection.isSelected(row))
      this.agreementSelection.toggle(row);

    this.vendorAgreementModal.ShowModal({
      Id: row.Id,
      VendorId: row.VendorId,
      CanActivate: this.canActivateAgreement(row.Id)
    }).subscribe((agreementId: number) => {
      if (agreementId)
        this.updateAgreementItems();
    });
  }

  private canActivateAgreement = (selectedRowId: number): boolean => {
    if (this.Agreements) {
      if (this.Agreements.every(a => a.Active == false) === true)
        return true;
      else {
        const actives = this.Agreements.filter(a => a.Active === true);

        if (actives != null && actives.length === 1) {
          const active = actives[0];
          if (active.Id == selectedRowId)
            return true;
        }
      }
    }

    return false;
  }

  private updateAgreementItems = () => {
    const loadRef = this.loadingService.StartWaiting("Fetching Agreements");
    this.Agreements = [];
    this.vendorAgreementService.GetAgreementsByVendorId(this.Vendor.Id).subscribe((results) => {
      this.Agreements = results;
      loadRef.Stop();
    });
  }

  public AddQuote = () => {
    this.quoteCreateModal.ShowModal({
      VendorId: this.Vendor.Id
    }).subscribe((quoteId: number) => {
      if (quoteId) {
        this.updateQuoteItems();
        this.EditQuote(quoteId);
      }
    });
  }

  public EditQuote = (quoteId: number) => {
    if (!this.isFromQuote) {
      this.quoteEditModal.ShowModal({
        VendorId: this.Vendor.Id,
        QuoteId: quoteId,
        isFromVendor: true
      }).subscribe(result => {
        this.updateQuoteItems();

        if (result.InvoiceIdToDisplay !== undefined) {
          this.updateInvoiceItems();
          this.EditInvoice(result.InvoiceIdToDisplay);
        }
      });
    }
    
  }

  public EditInvoice = (invoiceId: number) => {
    if (!this.isFromInvoice) {
      this.invoiceEditModal.ShowModal({
        InvoiceId: invoiceId,
        isFromVendor: true
      }).subscribe(result => {
        this.updateInvoiceItems();
      });
    }
  }

  public GetQuoteStatus = (status: number) => {
    return this.quoteStatusDropdowns.filter(x => x.Status == status)[0].Description;
  }

  private updateQuoteItems = () => {
    const loadRef = this.loadingService.StartWaiting("Fetching Quotes");
    this.Quotes = [];
    this.vendorService.GetVendorQuotesByVendorId(this.Vendor.Id).subscribe((results) => {
      this.AllQuotes = results;
      this.Quotes = results;
      this.filterQuotes();
      loadRef.Stop();
    });
  }

  private updateInvoiceItems = () => {
    const loadRef = this.loadingService.StartWaiting("Fetching Invoices");
    this.Invoices = [];
    this.invoiceService.GetInvoicesForVendor(this.Vendor.Id).subscribe(results => {
      this.AllInvoices = results;
      this.UpdateInvoiceFilters();
      this.Invoices = results;
      this.filterInvoices();
      loadRef.Stop();
    });
  }

  public AddInvoice = () => {
    this.invoiceCreateModal.ShowModal({
      VendorId: this.Vendor.Id
    }).subscribe(invoiceId => {
      if (invoiceId != null) {
        this.EditInvoice(invoiceId);
      }
    });
  }

  private getNextContactId = (): number => {
    let highest = 1;
    this.Vendor.Contacts.forEach(by => {
      if (by.RecId > highest)
        highest = by.RecId;
    });
    return highest + 1;
  }

  public TabHasErrors = (form: NgModelGroup): boolean => {
    //Handle null control groups on form load
    if (!form || !form.control)
      return false;
    const controls: Array<AbstractControl> = [];
    for (const ctrlKey in form.control.controls) {
      controls.push(form.control.controls[ctrlKey]);
    }

    return controls.some(control => control.errors != null && this.DataLoaded);
  }

  public CancelModal = async () => {
    let shouldLeave = await this.CheckForChangesAndPrompt();
    if (!shouldLeave)
      return;

    this.dialogRef.close(undefined);
  }

  private async CheckForChangesAndPrompt(): Promise<boolean> {
    if (this.changeDetectionService.HasChanges(this.Vendor)) {
      let shouldLeave = await this.changeDetectionService.HasChangeDialog();
      if (!shouldLeave)
        return false;
    }
    return true;
  }
}

@Injectable()
export class VendorDetailModal implements IModalComponent {
  public static readonly StandaloneModalName: string = "VendorDetailModal";
  constructor(@Inject(MatDialog) private dialog: MatDialog) {
  }

  ShowStandaloneModal(data: Data, params: Params) {
    var modalParams: ShowVendorDetailModalData =
    {
      vendorId: Number(params.get('id'))
    };

    return this.ShowModal(modalParams);
  }

  public ShowModal =
    /** Displays the Vendor modal. Returns the vendor id when the user closes the modal, or undefined if no vendor was created
     * @returns {Observable<number | undefined>} The vendor ID or undefined if no vendor was created.
     */
    (data: ShowVendorDetailModalData): Observable<number | undefined> => {
      const ref = this.dialog.open(VendorDetailModalComponent, {
        data: data,
        width: "1200px",
        disableClose: true,
        autoFocus: false,
      });

      return ref.afterClosed();
    }
}
