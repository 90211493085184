import { Component, Inject, Injectable, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'yes-no-modal',
  templateUrl: 'yes-no-modal.component.html'
})

export class YesNoModal implements OnInit {
  headerTitle: string = "";
  message: string = "";
  yesText: string = "Yes";
  noText: string = "No";
  setNoButtonAsDefault: boolean = false;
  private data: any;

  constructor(@Inject(MatDialogRef) public dialogRef: MatDialogRef<YesNoModal>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.data = data;
  }

  ngOnInit() {
    this.headerTitle = this.data.headerTitle;
    this.message = this.data.message;
    this.yesText = this.data.yesText;
    this.noText = this.data.noText;
    this.setNoButtonAsDefault = this.data.setNoButtonAsDefault ?? false;
  }

  onYesClick(): void {
    this.dialogRef.close(true);
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }
}
