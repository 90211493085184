<div id="yes-no-modal-container" style="min-width: 500px;">
    <h1 mat-dialog-title>{{headerTitle}}</h1>
    <div mat-dialog-content class="ui-dialog-message">
        <p>{{message}}</p>
    </div>
    <div mat-dialog-actions align="end">
        <div class="btn-group" role="group">
            <button class="btn btn-default" (click)="onOkClick()">
                {{okText}}
            </button>
        </div>
    </div>
</div>