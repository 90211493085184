<div id="yes-no-modal-container" style="min-width: 500px;">
    <h1 mat-dialog-title>{{headerTitle}}</h1>
    <div mat-dialog-content>
        <p>{{message}}</p>
    </div>
    <div mat-dialog-actions align="end">
      <div class="btn-group" role="group">
        <button class="btn btn-default" (click)="onYesClick()">
          {{yesText}}
        </button>
        <button *ngIf="!setNoButtonAsDefault" class="btn btn-default" (click)="onNoClick()">
          {{noText}}
        </button>
        <button *ngIf="setNoButtonAsDefault" class="btn btn-default" (click)="onNoClick()" cdkFocusInitial>
          {{noText}}
        </button>
      </div>
    </div>
</div>
