<div class="container">
  <div>
    <p><strong>First Name: </strong> {{profile?.givenName}}</p>
    <p><strong>Last Name: </strong> {{profile?.surname}}</p>
    <p><strong>Email: </strong> {{profile?.userPrincipalName}}</p>
    <p><strong>Id: </strong> {{profile?.id}}</p>
    <p><strong>Token Issued at: </strong> {{ tokenIssuedAt | date:'full' }}</p>
    <p><strong>Token Valid not before: </strong> {{ tokenValidStart | date:'full' }}</p>
    <p><strong>Token Expires: </strong> {{ tokenValidEnd | date:'full' }}</p>
    <p><strong>Protected API check: </strong>{{protectedApiResponse}}</p>
  </div>
  <hr />
  <p>
    <strong>Token Role Claims:</strong>
  </p>
  <ol>
    <li *ngFor="let role of tokenRoleClaims">{{role}}</li>
  </ol>
  <p>
    <strong>Mapped Roles:</strong>
  </p>
  <ol>
    <li *ngFor="let role of roles">{{role}}</li>
  </ol>
  <p>
    <strong>Regional Payment Admin Countries:</strong>
  </p>
  <ol>
    <li *ngFor="let country of adminCountries">{{country.CountryName}} ({{country.CountryCode}})</li>
  </ol>
</div>
