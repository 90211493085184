
<div class="row">
  <div class="col-sm-3">
    <mat-card class="small-card">
      <mat-card-title>Trials/Non-trials</mat-card-title>
      <div class="trial-select-list">
        <mat-selection-list [multiple]="false">
          <mat-list-item *ngFor="let Trial of data" (click)="SelectedTrial = Trial" [class.active]="SelectedTrial === Trial" class="trial-select-item">

            <div style="width: 100%">
              <div>
                {{Trial.TrialNumber}}
                <ng-container *ngIf="Trial.Status">
                  ({{Trial.Status}})
                </ng-container>
              </div>
            </div>

          </mat-list-item>
        </mat-selection-list>
      </div>
    </mat-card>
  </div>
  <div class="col-sm-4">

    <mat-card class="small-card">
      <mat-card-title>
        <a *ngIf="SelectedTrial?.SourceSystemLink" href="{{SelectedTrial?.SourceSystemLink}}" target="_blank">{{SelectedTrial?.TrialNumber}}</a>
        <span *ngIf="!SelectedTrial?.SourceSystemLink">{{SelectedTrial?.TrialNumber}}</span>
      </mat-card-title>
      <mat-card-subtitle class="trial-title">{{SelectedTrial?.Title}}</mat-card-subtitle>
      <div class="assoc-detail-wrapper" scrollInsetShadow *ngIf="SelectedTrial">

        <div *ngIf="ShowCurrencyMessage()" class="alert alert-info" role="alert">
          This trial/non-trial has a different currency compared to others previously selected.
        </div>

        <div *ngIf="ShowRestrictionMessage()" class="alert alert-info" role="alert">
          This cannot be added to current invoice due to its status.
        </div>
        <div *ngFor="let year of SelectedTrial.BudgetYears">
          <div style="display: flex;">
            {{year.Year}} -
            {{GetBudgetYearQuotedAmount(year) | number}} {{SelectedTrial.Currency}} Total Committed
            ({{GetLeft(year) | number}} {{SelectedTrial.Currency}} remaining)
          </div>

          <div *ngFor="let objective of year.Objectives" style="margin-left: 20px; padding-left: 5px; border-left: 1px solid #888;">
            <div style="margin-top: 3px; margin-bottom: 0;">
              {{objective.ObjectiveName}} ({{objective.QuotedAmount | number}} Total Committed)
              <button mat-icon-button type="button" [style.visibility]="canAdd(objective) ? null : 'hidden'" (click)="AddInvoice(objective, SelectedTrial.Currency)" [disabled]="isDisabled || isAssocDisabled(SelectedTrial)"><mat-icon>playlist_add</mat-icon></button>
            </div>
            <div style="margin-left: 25px; font-size: 130%;">
              <div *ngFor="let invoice of objective.Invoices">
                  <comma-separated-input [(amount)]="invoice.Amount"
                                         [label]="invoice.Name"
                                         [controlName]="year.Year + objective.ObjectiveName + invoice.Name"
                                         [isDisabled]="!invoice.Editable || isDisabled || isAssocDisabled(SelectedTrial)"
                                         (amountChange)="RefreshSummary()"
                                         [deletable]="invoice.Editable"
                                         (deleteInstance)="RemoveInvoice(objective, invoice)"
                                         [isDeleteDisabled]="isDisabled || isAssocDisabled(SelectedTrial)"></comma-separated-input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
  <div class="col-sm-5">
    <mat-card class="small-card">
      <mat-card-title>Invoice Summary</mat-card-title>
      <mat-card-subtitle>This invoice</mat-card-subtitle>
      <mat-card-content>

        <table mat-table [dataSource]="Summary" multiTemplateDataRows class="mat-table-small">

          <ng-container matColumnDef="trial">
            <th mat-header-cell *matHeaderCellDef>Trial</th>
            <td mat-cell *matCellDef="let row">
              <ng-container *ngIf="row.Summary == 'Trial'">
                {{row.TrialNumber}}
              </ng-container>
              <ng-container *ngIf="row.Summary == 'Year'">
                <span style="padding-left: 25px;">{{row.Year}}</span>
              </ng-container>
              <ng-container *ngIf="row.Summary == 'None'">
                <span style="padding-left: 50px;">{{row.ObjectiveName}}</span>
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="year">
            <th mat-header-cell *matHeaderCellDef>Year</th>
            <td mat-cell *matCellDef="let row">
              <ng-container *ngIf="row.Summary == 'Year'">
                {{row.Year}}
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="objective">
            <th mat-header-cell *matHeaderCellDef>Objective</th>
            <td mat-cell *matCellDef="let row">
              <ng-container *ngIf="row.Summary == 'None'">
                {{row.ObjectiveName}}
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="committed">
            <th mat-header-cell style="text-align: right" *matHeaderCellDef>Committed</th>
            <td mat-cell style="text-align: right" *matCellDef="let row">{{row.Committed | number}}</td>
          </ng-container>
          <ng-container matColumnDef="invoiced">
            <th mat-header-cell style="text-align: right" *matHeaderCellDef>Invoiced</th>
            <td mat-cell style="text-align: right" *matCellDef="let row">{{row.Invoiced | number}}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="Columns"></tr>
          <tr mat-row *matRowDef="let row; columns: Columns" [style.font-weight]="row.IssummaryItem ? '500' : 'initial'"></tr>
        </table>

      </mat-card-content>
    </mat-card>
  </div>
</div>
