import { SelectionModel } from "@angular/cdk/collections";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { QuoteStatus } from "../../../modals/quote-create-modal/types/Quote";
import { QuoteSearchRow } from "../types/QuoteSearchRow";
import { QuoteEditModalService } from "../../../modals/quote-edit-modal/quote-edit-modal.service";
import { QuoteStatusDropdowns } from "../../../modals/quote-edit-modal/types/QuoteStatusDescriptionDto";

@Component({
  selector: 'quote-search-results',
  templateUrl: 'quote-search-results-grid.component.html'
})
export class QuoteSearchResultsGridComponent implements OnInit {
  public selection = new SelectionModel<QuoteSearchRow>(false);

  @Input() quotes: QuoteSearchRow;

  @Output() public onEdit = new EventEmitter<QuoteSearchRow>();

  @Output() public sortChanged = new EventEmitter<MatSort>();

  public quoteStatusDropdowns: QuoteStatusDropdowns[] = [];

  constructor(
    private quoteEditModalService: QuoteEditModalService
  )
  {
  }

  ngOnInit() {
    this.quoteEditModalService.GetQuoteStatusDropdownList().subscribe((dropdowns) => {
      this.quoteStatusDropdowns = dropdowns;
    });
  };

  public Columns = [
    "number",
    "year",
    "person",
    "budgetYear",
    "counts",
    "committed",
    "currency",
    "status",
    "pr",
    "purchaseOrder",
    "docs",
    "name",
    "code",
    "country",
    "contact",
    "paidByCommercial"
  ];

  public doubleClick = (row: QuoteSearchRow) => {
    if (!this.selection.isSelected(row))
      this.selection.toggle(row);

    this.onEdit.next(row);
  }

  onSortChange = (evt: MatSort) => {
    this.sortChanged.next(evt);
  }

  public QuoteStatusDescription = (status: QuoteStatus) => {

    return this.quoteStatusDropdowns.filter(x => x.Status == status)[0].Description;
  }
}
