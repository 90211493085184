
<input matInput autocomplete="new-password" name="trial_responsible-{{id}}" class="typeahead-input"
       [matAutocomplete]="ftmsAutocomplete"
       [formControl]="inputControl"
       [(ngModel)]="textValue"
       type="text"
       (blur)="onTouchedFn()" />

<mat-icon *ngIf="value" mat-icon-button class="clear-input-icon" (click)="clearTypeahead()">close</mat-icon>

<mat-autocomplete #ftmsAutocomplete="matAutocomplete"
                  [displayWith]="DisplayItem">

    <ng-container *ngIf="value !== undefined">
        <mat-option *ngFor="let item of getFullList()" [value]="item">
            {{item.DisplayText}}
        </mat-option>
    </ng-container>


    <ng-container *ngIf="value === undefined">
        <mat-option *ngFor="let item of FilteredList | async" [value]="item">
            {{item.DisplayText}}
        </mat-option>
    </ng-container>
</mat-autocomplete>
