import { Error as ErrorObject } from './types/Error';
import { Inject, Injectable } from '@angular/core';
import { ErrorComponent } from './error-modal.component';
import { ErrorModalShowData } from './types/ErrorModalShowData';
import { BehaviorSubject, catchError, Observable, throwError } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { errorHandler, FtmsHttpParams } from '../../shared/ApiService';


@Injectable()
export class ErrorHandlerService {
  public Errors: ErrorObject[] = [];
  private dialogRef: MatDialogRef<ErrorComponent, any>;
  private ErrorUpdates: BehaviorSubject<ErrorObject[]> = new BehaviorSubject<ErrorObject[]>([]);

  constructor(
    @Inject(MatDialog) private dialog: MatDialog) {

  }

  private RefreshModal = () => {
    if (this.Errors.length > 0) {
      if (this.dialogRef === undefined) {
        // Display the modal
        this.dialogRef = this.dialog.open(ErrorComponent, {
          width: "800px",
          panelClass: "error-modal",
          disableClose: true,
          data: <ErrorModalShowData>{
            Errors: this.ErrorUpdates
          }
        });
        var subscription = this.dialogRef.afterClosed().subscribe(() => {
          subscription.unsubscribe();
          this.dialogRef = undefined;
          this.ClearErrors();
        });
      }
      else {
        // the error modal is displayed, and we are just adding more errors to it.
        // this will work automatically since we passed in the BehaviorSubject to
        // the error modal component
      }
    }
    else {
      if (this.dialogRef !== undefined) {
        // hide the modal
        this.dialogRef.close();
        this.dialogRef = undefined;
      }
    }
  };

  public AddError(title: string, message: string, techDetails: string) {
    this.Errors.push(new ErrorObject(title, message, techDetails, []));
    this.ErrorUpdates.next(this.Errors);
    this.RefreshModal();
  }

  public AddFluentValidationError(title: string, message: string, fluentValidationErrors: string[]) {
    this.Errors.push(new ErrorObject(title, message, "", fluentValidationErrors));
    this.ErrorUpdates.next(this.Errors);
    this.RefreshModal();
  }

  public ClearErrors() {
    this.Errors = [];
    this.ErrorUpdates.next(this.Errors);
    this.RefreshModal();
  }

  public HandleError = (err: HttpErrorResponse) => {
    // Setup error modal
    var message = err.message;
    var title = 'Something went wrong!';
    if (err.statusText != null) {
      message += ' (' + err.statusText + ')';
    }

    var techDetails: any = err.error;

    // fluentValidation errors
    if (err.status === 400) {
      let fluentValidationErrors: string[] = err.error;

      this.AddFluentValidationError(title, message, fluentValidationErrors);
    }
    else {
      // we weren't able to connect to the server - handle this error gracefully
      if (err.status === 0 && err.statusText === 'error') {
        message = 'Connection error';
        techDetails = 'The connection to the server was lost.\nPlease ensure that your are connected to the Corteva network.';
      }

      // we weren't authorized to take a particular action - give a better error message
      if (err.status === 403 && err.statusText === 'Forbidden') {
        message = 'Not Authorized';
        techDetails = '403 Forbidden: You do not have sufficient permission to perform this action.';
      }

      this.AddError(title, err.message, techDetails);
    }
  }
}


// Handles displaying errors to user using the ErrorViewModel
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private errorHandlerService: ErrorHandlerService) {
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.log("Handling exception", {
            error: error,
            req: req,
            next: next
          });
          let handlers: Array<errorHandler> = [];
          if (req.params instanceof FtmsHttpParams) {
            console.log("Custom error stuff!!!");
            if (req.params.OnNotFound != null && error.status == 404)
              handlers.push(req.params.OnNotFound);
            if (req.params.OnError != null)
              handlers.push(req.params.OnError);
          }
          else {
            console.log("No custom error stuff");
          }
          handlers.push(() => {
            this.errorHandlerService.HandleError(error);
            return {
              continueExecutingErrorHandlers: false
            }
          });
          // execute error handlers
          let keepGoing = true;
          for (let i = 0; i < handlers.length && keepGoing; i++) {
            let h = handlers[i];
            let response = h(error);
            keepGoing = response.continueExecutingErrorHandlers;
          }
          return throwError(error);
        })
      );
  }
}
