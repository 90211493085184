import { Error as ErrorObject } from './types/Error';
import { Inject, Component } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ErrorModalShowData } from './types/ErrorModalShowData';

@Component({
  selector: 'error-modal',
  templateUrl: 'error-modal.component.html',
})
export class ErrorComponent {

  public Errors: ErrorObject[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) private showModalData: ErrorModalShowData,
    @Inject(MatDialogRef) private dialogRef: MatDialogRef<ErrorComponent, void>) {
    showModalData.Errors.subscribe(errors => {
      this.Errors = errors;
    });
  }

  public close = () => {
    this.dialogRef.close();
  }
}
