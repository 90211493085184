import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { VendorDetailDropdowns } from "./types/VendorDetailDropdowns";
import { Vendor, VendorUpdate, VendorCreate } from "./types/Vendor";
import { VendorQuote } from "./types/VendorQuote";
import { ApiService, FtmsHttpParams } from "../../shared/ApiService";

@Injectable()
export class VendorDetailModalService {

  constructor(private apiService: ApiService) {
    }

    public GetVendorDropdowns = (): Observable<VendorDetailDropdowns> => {
      return this.apiService.GET<VendorDetailDropdowns>('api/v1/vendor-detail-meta');
    }

    public GetVendor = (vendorId: number): Observable<Vendor> => {
        return this.apiService.GET<Vendor>(`api/v1/vendor/${vendorId}`, this.getNotFoundHandler());
    }

    public CreateVendor = (createData: VendorCreate): Observable<Vendor> => {
        return this.apiService.POST<VendorCreate, Vendor>(createData, 'api/v1/vendor');
    }

    public UpdateVendor = (vendorId: number, updateData: VendorUpdate): Observable<Vendor> => {
        return this.apiService.PUT<VendorUpdate, Vendor>(updateData, `api/v1/vendor/${vendorId}`);
    }

    public GetVendorQuotesByVendorId = (vendorId: number): Observable<Array<VendorQuote>> => {
        return this.apiService.GET<Array<VendorQuote>>(`api/v1/vendors/${vendorId}/quotes`, this.getNotFoundHandler());
    }
            
    private getNotFoundHandler = () => {
        return new FtmsHttpParams().WithOnNotFoundHandler(() => {
	        return {
		        continueExecutingErrorHandlers: false
	        };
        });
    }
}
