import { AppComponent } from './app.component';
import { NavMenuComponent } from './components/header/nav-menu/nav-menu.component';
import { HomeComponent } from './components/home/home.component';
import { ProfileComponent } from './components/profile/profile.component';
import { CtrlClickDirective } from './directives/ctrl-click.directive';
import { AppHeaderComponent } from './components/header/header.component';
import { AppFooterComponent } from './components/footer/footer.component';
import { NoRoleAccessComponent } from './auth/no-role-access/no-role-access.component';
import { LoadingOverlayComponent, LoadingService } from "./shared/services/loading-overlay/loading-overlay.component";
import { TypeaheadComponent, TypeaheadMultiComponent } from './components/typeahead/typeahead.component';
import { FilterCardComponent } from './components/filter-card/filter-card.component';
import { VendorDetailModal, VendorDetailModalComponent } from './modals/vendor-detail-modal/vendor-detail-modal.component';
import { VendorAgreementModal, VendorAgreementModalComponent } from './modals/vendor-agreement-modal/vendor-agreement-modal.component';
import { VendorSearch } from './components/vendor-search/vendor-search.component';
import { VendorResultsGridComponent } from './components/vendor-results-grid/vendor-results-grid.component';
import { VendorSelectionModal, VendorSelectionModalComponent } from './modals/vendor-selection-modal/vendor-selection-modal.component';
import { SearchStorageService } from './shared/services/search-storage.service';
import { VendorDetailModalService } from './modals/vendor-detail-modal/vendor-detail-modal.service';
import { VendorSearchService } from './components/vendor-search/vendor-search.service';
import { VendorAgreementModalService } from './modals/vendor-agreement-modal/vendor-agreement-modal.service';
import { ChangeDetectionService } from './shared/services/change-detection.service';
import { DialogService } from './shared/services/dialog.service';
import { VendorSelectionService } from './modals/vendor-selection-modal/vendor-selection-modal.service';
import { YesNoPipe } from './shared/pipes/yes-no.pipe';
import { QuoteEditModal, QuoteEditModalComponent } from './modals/quote-edit-modal/quote-edit-modal.component';
import { QuoteCreateModal, QuoteCreateModalComponent } from './modals/quote-create-modal/quote-create-modal.component';
import { QuoteModalService } from './modals/quote-create-modal/quote-modal.service';
import { QuoteEditModalLineItemAssocGridComponent } from './modals/quote-edit-modal/line-item-assoc-grid/quote-edit-modal-line-item-assoc-grid.component';
import { YesNoModal } from './modals/yes-no-modal/yes-no-modal.component';
import { QuoteEditModalService } from './modals/quote-edit-modal/quote-edit-modal.service';
import { FileDragAndDropDirective, FileDragAndDropGuardDirective } from './directives/file-drag-and-drop.directive';
import { DocumentPickerComponent } from './components/document-picker/document-picker.component';
import { ScrollInsetShadowDirective } from './directives/scroll-inset-shadow.directive';
import { DocumentPickerService } from './components/document-picker/document-picker.service';
import { VendorQuoteFilterComponent } from './components/vendor-quote-filters/vendor-quote-filters.component';
import { QuoteSearchService } from './components/quote-search/quote-search.service';
import { QuoteSearchComponent } from './components/quote-search/quote-search.component';
import { QuoteSearchResultsGridComponent } from './components/quote-search/quote-search-results-grid/quote-search-results-grid.component';
import { GenericSearchPageComponent } from './components/generic-search-page/generic-search-page.component';
import { ErrorComponent } from './modals/error-modal/error-modal.component';
import { DecimalsOnlyDirective } from './shared/directives/DecimalsOnlyDirective';
import { QuickLinksService } from './shared/services/quickLinks.service';
import { QuicklinkRedirectComponent } from './components/quicklink-redirect-component/quicklink-redirect.component';
import { InvoiceModalService } from './modals/invoice-modal/invoice-modal.service';
import { InvoiceFilterComponent } from './components/invoice-filter/invoice-filters.component';
import { AgreementSearchService } from './components/agreement-search/agreement-search.service';
import { AgreementSearchComponent } from './components/agreement-search/agreement-search.component';
import { AgreementSearchResultsGridComponent } from './components/agreement-search/agreement-search-results-grid/agreement-search-results-grid.component';
import { InvoiceEditModal, InvoiceEditModalComponent } from './modals/invoice-modal/edit/invoice-edit-modal.component';
import { DirectModalLinkComponent, DirectModalLinkService } from './components/direct-modal-link/direct-modal-link.component';
import { InvoicePaymentLineItemAssocComponent } from './modals/invoice-modal/payment-line-item-assoc/invoice-payment-line-item-assoc.component';
import { InvoiceCreateModal, InvoiceCreateModalComponent } from './modals/invoice-modal/create/invoice-create-modal.component';
import { InvoiceSearchService } from './components/invoice-search/invoice-search.service';
import { InvoiceSearchComponent } from './components/invoice-search/invoice-search.component';
import { InvoiceSearchResultsGridComponent } from './components/invoice-search/invoice-search-results-grid/invoice-search-results-grid.component';
import { CommaSeparatedInputComponent } from './components/comma-separated-input/comma-separated-input.component';

export const modalComponents = [
  VendorAgreementModalComponent,
  VendorSelectionModalComponent,
  VendorDetailModalComponent,
  QuoteCreateModalComponent,
  QuoteEditModalComponent,
  YesNoModal,
  ErrorComponent,
  InvoiceEditModalComponent,
  InvoiceCreateModalComponent,
];

// Wrappers around opening modals
export const modals = [
  VendorDetailModal,
  VendorSelectionModal,
  VendorAgreementModal,
  QuoteCreateModal,
  QuoteEditModal,
  InvoiceEditModal,
  InvoiceCreateModal,
];

export const componentServices = [
  LoadingService,
  QuickLinksService,
  ChangeDetectionService,
  DialogService,
  SearchStorageService,
  VendorDetailModalService,
  VendorSearchService,
  VendorSelectionService,
  AgreementSearchService,
  VendorAgreementModalService,
  QuoteModalService,
  QuoteEditModalService,
  DocumentPickerService,
  QuoteSearchService,
  InvoiceModalService,
  DirectModalLinkService,
  InvoiceSearchService
];

export const components = [
  AppComponent,
  NavMenuComponent,
  HomeComponent,
  QuicklinkRedirectComponent,
  AppHeaderComponent,
  AppFooterComponent,
  ProfileComponent,
  NoRoleAccessComponent,
  LoadingOverlayComponent,
  TypeaheadComponent,
  TypeaheadMultiComponent,
  FilterCardComponent,
  VendorQuoteFilterComponent,
  InvoiceFilterComponent,
  VendorSearch,
  VendorResultsGridComponent,
  QuoteEditModalLineItemAssocGridComponent,
  DocumentPickerComponent,
  QuoteSearchComponent,
  QuoteSearchResultsGridComponent,
  AgreementSearchComponent,
  AgreementSearchResultsGridComponent,
  GenericSearchPageComponent,
  DirectModalLinkComponent,
  InvoicePaymentLineItemAssocComponent,
  InvoiceSearchComponent,
  InvoiceSearchResultsGridComponent,
  CommaSeparatedInputComponent,
];

export const pipes = [
  YesNoPipe
];

export const directives = [
  CtrlClickDirective,
  FileDragAndDropDirective,
  FileDragAndDropGuardDirective,
  DecimalsOnlyDirective,
  ScrollInsetShadowDirective
];
