import { AfterViewInit, Component, ViewChild } from "@angular/core";
import { AsyncSubject, map, of } from "rxjs";
import { SearchType } from "../../shared/services/search-storage.service";
import { GenericSearchPageComponent } from "../generic-search-page/generic-search-page.component";
import { QuoteSearchService } from "./quote-search.service";
import { QuoteSearchParameters } from "./types/QuoteSearchParameters";
import { QuoteSearchRow } from "./types/QuoteSearchRow";
import { filterDefinitions } from "./quote-search-filters";
import { IFilterCategoryDef } from "../../shared/interfaces/IFilterDef";
import { LoadingService } from "../../shared/services/loading-overlay/loading-overlay.component";
import { QuoteEditModal, ShowQuoteEditModalData } from "../../modals/quote-edit-modal/quote-edit-modal.component";
import { InvoiceEditModal } from "../../modals/invoice-modal/edit/invoice-edit-modal.component";

@Component({
  selector: 'quote-search',
  templateUrl: 'quote-search.component.html'
})
export class QuoteSearchComponent implements AfterViewInit {
  @ViewChild(GenericSearchPageComponent, { static: true }) public SearchPageComponent: GenericSearchPageComponent<QuoteSearchRow, QuoteSearchParameters>;

  public SearchHistoryType: SearchType = SearchType.Quotes;

  public filterCategories: IFilterCategoryDef<QuoteSearchParameters>[] = filterDefinitions.filterCategories;

  public quoteData: QuoteSearchRow[] = [];

    constructor(private searchService: QuoteSearchService, private loadingService: LoadingService,
      private quoteEditModal: QuoteEditModal, private invoiceEditModal: InvoiceEditModal) {

  }

  ngAfterViewInit() {
    this.SearchPageComponent.Options.SearchForRows = (searchParameters) => {
      return this.searchService.SearchQuotes(searchParameters);
    }

    this.SearchPageComponent.Options.EditRow = (row) => {
      let retVal = new AsyncSubject<boolean>();
      var modalParams: ShowQuoteEditModalData =
      {
        VendorId: Number(row.VendorId),
        QuoteId: Number(row.QuoteId)
      };
      this.quoteEditModal.ShowModal(modalParams)
        .subscribe(result => {

          retVal.next(result.QuoteId !== undefined);

          if (result.InvoiceIdToDisplay !== undefined) {
            this.invoiceEditModal.ShowModal({
              InvoiceId: result.InvoiceIdToDisplay
            }).subscribe(() => {
              retVal.complete();
            });
          }
          else {
            retVal.complete();
          }
        });
      return retVal.asObservable();
    };

    this.SearchPageComponent.Options.SearchHistoryType = SearchType.Quotes;

    const loadRef = this.loadingService.StartWaiting("Loading Options...");
    this.searchService.GetDropdowns().subscribe(response => {
      console.log("response", response);
      filterDefinitions.mapFiltersFromApi(response);
      this.SearchPageComponent.InitializeFilters();

      loadRef.Stop();
    });
  }
}
