<h1 mat-dialog-title>
  <ng-container>
    <span>Create New FSPS Invoice</span>
  </ng-container>
</h1>

<div mat-dialog-content #modalContainer scrollInsetShadow>

  <form name="invoice-create-form" #invoiceCreateForm="ngForm">
    <mat-button-toggle-group name="createCriteria" [(value)]="CreateCriteria" style="margin-bottom: 5px;" class="button-toggle-color-accent">
      <mat-button-toggle value="PO">PO</mat-button-toggle>
      <mat-button-toggle value="NOPO">NO PO</mat-button-toggle>
    </mat-button-toggle-group>
    <mat-form-field>
      <mat-label>Trial/Non-Trial Year</mat-label>
      <mat-select required [(ngModel)]="Year" #year name="year" (selectionChange)="OnYearChange()">
        <mat-option *ngFor="let year of Dropdowns.Years" [value]="year">{{year}}</mat-option>
        <mat-error *ngIf="year.errors?.required">
          Year is required
        </mat-error>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="CreateCriteria == 'PO'">
      <mat-label>Purchase Order Number</mat-label>
      <mat-select required [(ngModel)]="PurchaseOrderNumber" #poNumber name="poNumber">
        <mat-option *ngFor="let po of Dropdowns.PoNumbers" [value]="po">{{po}}</mat-option>
        <mat-error *ngIf="poNumber.errors?.required">
          Purchase Order Number is required
        </mat-error>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="CreateCriteria == 'NOPO'">
      <mat-label>Purchase Requisition Number</mat-label>
      <mat-select required [(ngModel)]="PurchaseRequisitionNumber" #prNumber name="prNumber">
        <mat-option *ngFor="let pr of Dropdowns.PrNumbers" [value]="pr">{{pr}}</mat-option>
        <mat-error *ngIf="prNumber.errors?.required">
          Purchase Requisition Number is required
        </mat-error>
      </mat-select>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions style="display: flex">
  <span style="flex: 1 1 auto;"></span>
  <button mat-raised-button color="accent" (click)="CancelModal()">Cancel</button>
  <button mat-raised-button color="primary" (click)="SaveInvoice(invoiceCreateForm)">Create</button>
</div>
