import { Component, Inject, Injectable, OnInit } from "@angular/core";
import { AbstractControl, FormGroup, NgForm, NgModelGroup } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Data, Params } from "@angular/router";
import { forkJoin, Observable, of } from "rxjs";
import { IComponentDirty } from "../../shared/interfaces/IComponentDirty";
import { ChangeDetectionService } from "../../shared/services/change-detection.service";
import { DialogService } from "../../shared/services/dialog.service";
import { LoadingService } from "../../shared/services/loading-overlay/loading-overlay.component";
import { AgreementType, VendorAgreement, VendorAgreementCreate, VendorAgreementUpdate, VendorPaymentPlan } from "./types/VendorAgreement";
import { VendorAgreementModalService } from "./vendor-agreement-modal.service";
import { AgreementTypeItem, VendorAgreementDropdowns, VendorPaymentPlanItem } from "./types/VendorAgreementDropdowns";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { AppDateAdapter, APP_DATE_FORMATS } from "../../shared/format-datepicker";

export interface ShowVendorAgreementModalData {
  /** The VendorAgreement ID to show. If null, then the user can create a new vendor agreement */
  Id?: number;
  /** Required */
  VendorId: number;
  CanActivate: boolean;

}

@Component({
  selector: 'vendor-agreement-modal',
  templateUrl: 'vendor-agreement-modal.component.html',
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class VendorAgreementModalComponent implements OnInit, IComponentDirty {
  private changeDetectionService: ChangeDetectionService = null;
  private DataLoaded: boolean = false;
  private HasBeenSaved: boolean = false;
  public IsNewAgreement: boolean = false;
  public CanActivate: boolean = false;

  public Dropdowns = {
    Years: <Array<number>>[],
    AgreementTypes: <Array<AgreementTypeItem>>[],
    PaymentPlans: <Array<VendorPaymentPlanItem>>[],
  }

  public Agreement = {
    Id: <number>undefined,
    VendorId: <number>undefined,
    AgreementNumber: <string>undefined,
    AgreementType: <AgreementType>undefined,
    EffectiveStartDate: <Date>undefined,
    EffectiveEndDate: <Date>undefined,
    PaymentPlan: <VendorPaymentPlan>undefined,
    Timeline: <number>undefined,
    Comments: <string>undefined,
    CreatedByPersonId: <number>undefined,
    CreatedDate: <Date>undefined,
    CreatedByName: <string>undefined,
    UpdatedByPersonId: <number>undefined,
    UpdatedDate: <Date>undefined,
    UpdatedByName: <string>undefined,
    Active: <boolean>null
  };

  constructor(@Inject(MatDialogRef) private dialogRef: MatDialogRef<VendorAgreementModal, number>,
    @Inject(MAT_DIALOG_DATA) private showModalData: ShowVendorAgreementModalData,
    @Inject(MatDialog) public dialog: MatDialog,
    @Inject(MatSnackBar) private snackbar: MatSnackBar,
    @Inject(LoadingService) private loadingService: LoadingService,
    @Inject(VendorAgreementModalService) private agreementService: VendorAgreementModalService,
    @Inject(DialogService) public dialogService: DialogService) {
    this.changeDetectionService = new ChangeDetectionService(dialogService);
  }

  ngOnInit(): void {
    this.Dropdowns.Years = this.getYears();
    const dropdownsObservable = this.agreementService.GetVendorAgreementDropdowns();
    const loadRef = this.loadingService.StartWaiting("Fetching Vendor Agreement");
    let agreementLoadObservable: Observable<VendorAgreement>;
    if (this.showModalData.Id == null) {
      agreementLoadObservable = of(null);
      this.IsNewAgreement = true;
      this.Agreement.VendorId = this.showModalData.VendorId;
    }
    else {
      agreementLoadObservable = this.agreementService.GetAgreementById(this.showModalData.VendorId, this.showModalData.Id);
    }
    this.CanActivate = this.showModalData.CanActivate;

    forkJoin(dropdownsObservable, agreementLoadObservable).subscribe(([dropdowns, agreement]) => {
      if (dropdowns) {
        this.SetDropdowns(dropdowns);
      }

      if (agreement)
        this.SetAgreementData(agreement);

      this.DataLoaded = true;
      this.changeDetectionService.SetOriginalValue(this.Agreement);

      loadRef.Stop();
    });
  }

  IsComponentDirty = (): boolean => {
    return this.changeDetectionService.HasChanges(this.Agreement);
  };

  public SetDropdowns = (dropdowns: VendorAgreementDropdowns) => {
    this.Dropdowns.AgreementTypes = dropdowns.AgreementTypes;
    this.Dropdowns.PaymentPlans = dropdowns.PaymentPlans;
  }

  private getYears = (): number[] => {
    const years = [];
    const startYear = new Date().getFullYear();
    const endYear = startYear + 1;

    for (let i = startYear; i <= endYear; i++) {
      years.push(i);
    }
    return years;
  }

  public SetAgreementData = (agreement: VendorAgreement) => {
    this.Agreement.Id = agreement.Id;
    this.Agreement.VendorId = agreement.VendorId;
    this.Agreement.AgreementNumber = agreement.AgreementNumber;
    this.Agreement.AgreementType = agreement.AgreementType;
    this.Agreement.EffectiveStartDate = agreement.EffectiveStartDate;
    this.Agreement.EffectiveEndDate = agreement.EffectiveEndDate;
    this.Agreement.PaymentPlan = agreement.PaymentPlan;
    this.Agreement.Timeline = agreement.Timeline;
    this.Agreement.Comments = agreement.Comments;
    this.Agreement.CreatedByPersonId = agreement.CreatedByPersonId;
    this.Agreement.CreatedDate = agreement.CreatedDate;
    this.Agreement.CreatedByName = agreement.CreatedByName;
    this.Agreement.UpdatedByPersonId = agreement.UpdatedByPersonId;
    this.Agreement.UpdatedDate = agreement.UpdatedDate;
    this.Agreement.UpdatedByName = agreement.UpdatedByName;
    this.Agreement.Active = agreement.Active;
  }

  public SaveAgreement = (form: NgForm, closeAfterSave: boolean) => {
    if (!this.CheckFormIsValid(form))
      return;

    const loadingRef = this.loadingService.StartWaiting(this.IsNewAgreement ? "Creating new Agreement" : "Updating Agreement");

    const updateData: VendorAgreementUpdate = {
      AgreementType: this.Agreement.AgreementType,
      EffectiveStartDate: this.Agreement.EffectiveStartDate,
      EffectiveEndDate: this.Agreement.EffectiveEndDate,
      AgreementNumber: this.Agreement.AgreementNumber,
      PaymentPlan: this.Agreement.PaymentPlan,
      Timeline: this.Agreement.Timeline,
      Comments: this.Agreement.Comments,
      Active: this.Agreement.Active
    };
    let apiResponse: Observable<VendorAgreement>;

    if (this.IsNewAgreement) {
      const createData: VendorAgreementCreate = {
        VendorId: this.Agreement.VendorId,
        AgreementType: this.Agreement.AgreementType,
        EffectiveStartDate: this.Agreement.EffectiveStartDate,
        EffectiveEndDate: this.Agreement.EffectiveEndDate,
        AgreementNumber: this.Agreement.AgreementNumber,
        PaymentPlan: this.Agreement.PaymentPlan,
        Timeline: this.Agreement.Timeline,
        Comments: this.Agreement.Comments,
        Active: this.Agreement.Active
      }

      apiResponse = this.agreementService.CreateAgreement(this.Agreement.VendorId, createData);
    }
    else {
      apiResponse = this.agreementService.UpdateAgreement(this.Agreement.VendorId, this.Agreement.Id, updateData);
    }

    apiResponse.subscribe((result: VendorAgreement) => {
      this.SetAgreementData(result);

      this.changeDetectionService.SetOriginalValue(this.Agreement);
      this.IsNewAgreement = false;
      this.HasBeenSaved = true;
      loadingRef.Stop();
      if (closeAfterSave) {
        this.dialogRef.close(this.Agreement.Id);
      }
    }, (error) => {
      loadingRef.Stop();
    });
  }

  public ToggleActive = (form: NgForm) => {
    this.Agreement.Active = !this.Agreement.Active;
    this.SaveAgreement(form, false);
  }

  public activeToggleTooltip = () => {
    if (this.IsNewAgreement)
      return "Save new Agreement first";
    else if (!this.CanActivate) {
      return "This Vendor already has an active Agreement";
    }
    return "";
  }

  private GetControls = (formGroup: FormGroup): Array<AbstractControl> => {
    if (!formGroup || !formGroup.controls)
      return [];

    var controls: Array<AbstractControl> = [];
    for (const ctrlKey in formGroup.controls) {
      var control = formGroup.controls[ctrlKey];
      if (control instanceof FormGroup) {
        controls = controls.concat(this.GetControls(control));
      }
      else {
        controls.push(control);
      }
    }

    return controls;
  }

  public CheckFormIsValid = (form: NgForm): boolean => {
    let hasErrors = false;

    var controls: Array<AbstractControl> = this.GetControls(form.form);
    for (const ctrl of controls) {
      if (ctrl.errors) {
        ctrl.markAllAsTouched();
        hasErrors = true;
      }
    }

    if (hasErrors) {
      this.snackbar.open("There are validation errors. Please fix the highlighted fields and try again.", null, {
        duration: 5000,
      });
    }

    return !hasErrors;
  }

  public TabHasErrors = (form: NgModelGroup): boolean => {
    //Handle null control groups on form load
    if (!form || !form.control)
      return false;
    const controls: Array<AbstractControl> = [];
    for (const ctrlKey in form.control.controls) {
      controls.push(form.control.controls[ctrlKey]);
    }

    return controls.some(control => control.errors != null && this.DataLoaded);
  }

  public CancelModal = async () => {
    let shouldLeave = await this.CheckForChangesAndPrompt();
    if (!shouldLeave)
      return;

    if (this.HasBeenSaved)
      this.dialogRef.close(this.Agreement.Id);
    else
      this.dialogRef.close(undefined);
  }

  private async CheckForChangesAndPrompt(): Promise<boolean> {
    if (this.changeDetectionService.HasChanges(this.Agreement)) {
      let shouldLeave = await this.changeDetectionService.HasChangeDialog();
      if (!shouldLeave)
        return false;
    }
    return true;
  }
}

@Injectable()
export class VendorAgreementModal {
  constructor(@Inject(MatDialog) private dialog: MatDialog) {
  }

  ShowStandaloneModal(data: Data, params: Params): Observable<any> {
    var modalParams: ShowVendorAgreementModalData =
    {
      Id: Number(params.get('vendorId')),
      VendorId: Number(params.get('id')),
      CanActivate: params.get('canActivate')
    };

    return this.ShowModal(modalParams);
  }

  public ShowModal =
    /** Displays the Vendor Agreement modal. Returns the agreement id when the user closes the modal, or undefined if no vendor was created
     * @returns {Observable<number | undefined>} The agreement ID or undefined if no vendor was created.
     */
    (data: ShowVendorAgreementModalData): Observable<number | undefined> => {
      const ref = this.dialog.open(VendorAgreementModalComponent, {
        data: data,
        width: "800px",
        disableClose: true,
        autoFocus: false,
      });

      return ref.afterClosed();
    }
}
