import { Component, Input } from "@angular/core";
import { QuoteEditModalHelper } from "../QuoteEditModalHelper";
import { QuoteEditAssocGridBudgetYear, QuoteEditAssocGridItem } from "../types/QuoteEditAssocGridItem";

@Component({
  selector: 'quote-edit-modal-line-item-assoc-grid',
  templateUrl: 'quote-edit-modal-line-item-assoc-grid.component.html'
})
export class QuoteEditModalLineItemAssocGridComponent {

  private quoteEditModalHelper = new QuoteEditModalHelper();

  public Columns: Array<string> = [];

  @Input() public isGridDisabled: boolean = false;
  @Input() public quoteId: number;
  @Input() public rows: Array<QuoteEditAssocGridItem> = [];
  @Input() public hasDocs: boolean = true;

  public columnBudgetYears: Array<number> = [];
  @Input() public set budgetYears(val: Array<number>)
  {
    this.columnBudgetYears = val;
    this.Columns = this.GetColumns(val);
  }

  private GetColumns = (budgetYears: Array<number>): Array<string> => {
    return [
      "number",
      "external",
      "shortTitle",
      "commercial",
      "objective",
      ...budgetYears.map(a => "by_" + a),
      "totalCommitted",
      "currency",
      "status",
      "comment"
    ];
  }

  public GetRowTotalCommitted = (row: QuoteEditAssocGridItem) => {
    return [
        ...row.BudgetYears
        .filter(a => a.QuotedAmount > 0) // only get filled in quoted amounts
        .map(a => Number(a.QuotedAmount)), // convert the quoted amounts to number (since they would be strings from the input boxes)
        0 // provide at least a 0 to add if no values exist
      ]
      .reduce((a, b) => a + b); // add them all together
  }

  public CanEditComment = (row: QuoteEditAssocGridItem) => {
    // return true if any budget year cell is filled in for this quote
    return row.BudgetYears.find(by => this.quoteEditModalHelper.IsQuotedAmountForThisQuote(this.quoteId, by));
  }

  public ShouldRowCellBeLocked = (row: QuoteEditAssocGridItem, budgetYear: number): boolean => {
    var cell = row.BudgetYears.find(a => a.BudgetYear === budgetYear);

    if (!cell)
      return true;

    // short circuit to save a bunch of processing time if the whole grid is disabled
    if (this.isGridDisabled)
      return true;

    if (cell.IsLocked)
      return true;

    // look up all quoted amounts
    var quotedCurrencies: string[] = [];
    var quotedItemTypes: number[] = [];
    this.rows.forEach(row => {
      row.BudgetYears.filter(by => this.quoteEditModalHelper.IsQuotedAmountForThisQuote(this.quoteId, by)).forEach(by => {
        quotedCurrencies.push(row.CurrencyName);
        quotedItemTypes.push(row.ItemType);
      });
    });

    // Make sure you can only enter values for 1 currency or item type (i.e., trials or non-trials)
    var quotedCurrency: string = row.CurrencyName;
    var quotedItemType: number = row.ItemType;

    if (quotedCurrencies.length > 0) {
      quotedCurrency = quotedCurrencies[0];
    }
    if (quotedItemTypes.length > 0) {
      quotedItemType = quotedItemTypes[0];
    }

    return quotedCurrency !== row.CurrencyName || quotedItemType !== row.ItemType;
  }

  constructor() {
    this.Columns = this.GetColumns([]);
  }
}
