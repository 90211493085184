import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { PaymentLineItemRestriction } from "../../quote-edit-modal/quote-edit-modal.service";


interface TrialSummaryItem {
  TrialNumber: string,
  Summary: "Trial" | "Year" | "None",
  ObjectiveName: string,
  Year: number,
  Committed: number,
  Invoiced: number
};

export interface Trial {
  TrialNumber: string,
  Title: string,
  IsCommercial: boolean,
  Currency: string,
  Status: string,
  BudgetYears: Array<BudgetYear>,
  RestrictionStatus: PaymentLineItemRestriction,
  SourceSystemLink?: string,
  ItemType: number
}

export interface BudgetYear {
  Year: number,
  Objectives: Array<Objective>
}

export interface Objective {
  ObjectiveName: string,
  QuotedAmount: number,
  Invoices: Array<Invoice>,
  PaymentLineItemVendorAssocId: number,
  Currency: string
}

export interface Invoice {
  Amount: number,
  Name: string,
  Editable: boolean,
  IsCurrentInvoice: boolean,
  InvoiceId: number,
  PaymentLineItemInvoiceAssocId?: number
}

@Component({
  selector: 'invoice-payment-line-item-assoc',
  templateUrl: 'invoice-payment-line-item-assoc.component.html',
  styleUrls: [
    'invoice-payment-line-item-assoc.component.less'
  ]
})
export class InvoicePaymentLineItemAssocComponent implements OnChanges {

  @Output() public dataChange = new EventEmitter<Array<Trial>>();
  @Input() public invoiceNumber: string = "Current Invoice";
  @Input() public invoiceId: number;
  @Input() public data: Array<Trial> = [];
  @Input() public isDisabled: boolean;

  public SelectedTrial: Trial = null;
  public ObjectiveCurrency: string;

  constructor() {
  }

  public Summary = [];

  public GetSummary = () => {
    var retVal = [];

    this.data.forEach(trial => {

      retVal = [...retVal, ...this.GetIndividualSummary(trial)];

    });
    return retVal;
  }

  public isAssocDisabled = (trial: Trial): boolean => {
    return trial.RestrictionStatus == PaymentLineItemRestriction.NotAddedToInvoices;
  }

  public ShowRestrictionMessage = () => {
    return this.SelectedTrial.RestrictionStatus == PaymentLineItemRestriction.NotAddedToInvoices;
  }

  public GetIndividualSummary = (trial: Trial): Array<TrialSummaryItem> => {
    var retVal: Array<TrialSummaryItem> = [];

    let trialSummaryRow: TrialSummaryItem = {
      Summary: "Trial",
      ObjectiveName: null,
      TrialNumber: trial.TrialNumber,
      Year: null,
      Committed: 0,
      Invoiced: 0
    }

    trial.BudgetYears.forEach(by => {

      let yearSummaryRow: TrialSummaryItem = {
        Summary: "Year",
        Committed: 0,
        Invoiced: 0,
        ObjectiveName: null,
        TrialNumber: trial.TrialNumber,
        Year: by.Year
      }

      by.Objectives.forEach(objective => {

        objective.Invoices.filter(inv => inv.Editable).forEach(invoice => {

          var hasTrialSummary = retVal.find(a => a.Summary == "Trial" && a.TrialNumber === trial.TrialNumber);

          if (!hasTrialSummary)
            retVal.push(trialSummaryRow);

          var hasYearSummary = retVal.find(a => a.Summary == "Year" && a.TrialNumber == trial.TrialNumber && a.Year == by.Year);

          if (!hasYearSummary)
            retVal.push(yearSummaryRow);

          trialSummaryRow.Committed += Number(objective.QuotedAmount);
          trialSummaryRow.Invoiced += Number(invoice.Amount);

          yearSummaryRow.Committed += Number(objective.QuotedAmount);
          yearSummaryRow.Invoiced += Number(invoice.Amount);

          retVal.push({
            Committed: objective.QuotedAmount || 0,
            Invoiced: invoice.Amount || 0,
            ObjectiveName: objective.ObjectiveName,
            Summary: "None",
            TrialNumber: trial.TrialNumber,
            Year: by.Year
          });
          this.ObjectiveCurrency = trial.Currency;
        });
      });
    });
    return retVal;
  }

  public Columns = ['trial', 'committed', 'invoiced']

  ngOnChanges(changes: SimpleChanges) {
    this.RefreshSummary(false);
    if (this.SelectedTrial === null && this.data !== null && this.data.length > 0) {
      // this happens after the modal is first opened
      this.SelectedTrial = this.data[0];
    }
    else if (this.SelectedTrial !== null && this.data !== null && this.data.indexOf(this.SelectedTrial) === -1) {
      // This happens after the invoice modal sets the assoc data again (such as after refreshing from the API)
      var matchingTrial = this.data.find(a => a.TrialNumber === this.SelectedTrial.TrialNumber);

      if (matchingTrial !== undefined)
        this.SelectedTrial = matchingTrial;
      else
        this.SelectedTrial = this.data[0];
    }
  }

  public RefreshSummary = (emitChangeNotification: boolean = true) => {
    this.Summary = this.GetSummary();
    this.dataChange.emit(this.data);
  }

  public AddInvoice = (objective: Objective, currency: string) => {
    if (this.Summary.length == 0)
      this.ObjectiveCurrency = currency;

    objective.Invoices.unshift({
      Amount: 0,
      Name: this.invoiceNumber,
      Editable: true,
      InvoiceId: this.invoiceId,
      PaymentLineItemInvoiceAssocId: null,
      IsCurrentInvoice: true
    });

    this.RefreshSummary();
  }

  public RemoveInvoice = (objective: Objective, invoice: Invoice) => {
    objective.Invoices.splice(objective.Invoices.indexOf(invoice), 1);
    this.RefreshSummary();
    if (this.Summary.length == 0)
      this.ObjectiveCurrency = null;
  }

  public canAdd = (objective: Objective) => {
    return objective.Invoices.find(a => a.Editable) === undefined && (this.HasSelectedCurrency() || this.ObjectiveCurrency == null);;
  }

  public GetBudgetYearQuotedAmount = (budgetYear: BudgetYear) => {
    let quotedAmount = 0;

    budgetYear.Objectives.forEach(objective => {
      quotedAmount += objective.QuotedAmount;
    });

    return quotedAmount;
  }

  public GetLeft = (budgetYear: BudgetYear) => {
    let invoicedAmount = 0;

    budgetYear.Objectives.forEach(objective => objective.Invoices.forEach(inv => {
      invoicedAmount += inv.Amount;
    }));

    return this.GetBudgetYearQuotedAmount(budgetYear) - invoicedAmount;
  }

  public HasSelectedCurrency = () => {
    return this.SelectedTrial?.Currency == this.ObjectiveCurrency
  }

  public ShowCurrencyMessage = () => {
    if (this.ObjectiveCurrency != null)
      return !this.isDisabled && !this.HasSelectedCurrency();
    else
      return false;
  }
}
