<table mat-table [dataSource]="vendors" matSort (matSortChange)="onSortChange($event)">
  <ng-container matColumnDef="vendorName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
    <td mat-cell *matCellDef="let row">
      {{row.VendorName}}
    </td>
  </ng-container>
  <ng-container matColumnDef="code">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Code</th>
    <td mat-cell *matCellDef="let row">
      {{row.Code}}
    </td>
  </ng-container>
  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="VendorTypeDescription">Type</th>
    <td mat-cell *matCellDef="let row">
      {{row.VendorTypeDescription}}
    </td>
  </ng-container>
  <ng-container matColumnDef="contact">
    <th mat-header-cell *matHeaderCellDef>Contacts</th>
    <td mat-cell *matCellDef="let row">
      {{row.ContactNames.join(', ')}}
    </td>
  </ng-container>
  <ng-container matColumnDef="addressLine1">
    <th mat-header-cell *matHeaderCellDef style="min-width: 65px">Address 1</th>
    <td mat-cell *matCellDef="let row">
      {{row.AddressLine1}}
    </td>
  </ng-container>
  <ng-container matColumnDef="addressLine2">
    <th mat-header-cell *matHeaderCellDef style="min-width: 65px">Address 2</th>
    <td mat-cell *matCellDef="let row">
      {{row.AddressLine2}}
    </td>
  </ng-container>
  <ng-container matColumnDef="addressLine3">
    <th mat-header-cell *matHeaderCellDef style="min-width: 65px">Address 3</th>
    <td mat-cell *matCellDef="let row">
      {{row.AddressLine3}}
    </td>
  </ng-container>
  <ng-container matColumnDef="country">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Country</th>
    <td mat-cell *matCellDef="let row">
      {{row.Country}}
    </td>
  </ng-container>
  <ng-container matColumnDef="state">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="Subdivision">State</th>
    <td mat-cell *matCellDef="let row">
      {{row.Subdivision}}
    </td>
  </ng-container>
  <ng-container matColumnDef="city">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>City</th>
    <td mat-cell *matCellDef="let row">
      {{row.City}}
    </td>
  </ng-container>
  <ng-container matColumnDef="zip">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Zip</th>
    <td mat-cell *matCellDef="let row">
      {{row.Zip}}
    </td>
  </ng-container>
  <ng-container matColumnDef="phone">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone</th>
    <td mat-cell *matCellDef="let row">
      {{row.Phone}}
    </td>
  </ng-container>
  <ng-container matColumnDef="fax">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Fax</th>
    <td mat-cell *matCellDef="let row">
      {{row.Fax}}
    </td>
  </ng-container>
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef>Email</th>
    <td mat-cell *matCellDef="let row">
      {{row.Email}}
    </td>
  </ng-container>
  <ng-container matColumnDef="active">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Active</th>
    <td mat-cell *matCellDef="let row">
      {{row.Active | YesNo}}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="Columns; sticky: true"></tr>
  <tr mat-row (dblclick)="doubleClick(row)"
      (click)="this.selection.toggle(row)"
      [class.selected]="selection.isSelected(row)"
      *matRowDef="let row; columns: Columns"></tr>
</table>
