import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'YesNo' })
export class YesNoPipe implements PipeTransform {
    transform(bool: boolean): string {
        if (bool) {
            return "Yes";
        } else if (bool === null || bool === undefined) {
            return "";
        } else {
            return "No";
        }
    }
}