import { QuoteStatus } from "../../modals/quote-create-modal/types/Quote";
import { IFilterCategoryDef, IFilterDef, IFilterDefWithOptions, IFilterDefWithTypeahead, SelectOption } from "../../shared/interfaces/IFilterDef";
import { TypeaheadItem } from "../typeahead/typeahead.component";
import { QuoteSearchDropdowns, QuoteSearchStatus } from "./types/QuoteSearchDropdowns";
import { QuoteSearchParameters } from "./types/QuoteSearchParameters";

var namedFilters = {
  VendorName: <IFilterDef<QuoteSearchParameters>>{ name: "Vendor Name", inputType: "text", hintText: "Executes a 'contains' search", searchParameterProperty: "VendorName" },
  VendorCode: <IFilterDef<QuoteSearchParameters>>{ name: "Vendor Code", inputType: "text", hintText: "Executes a 'contains' search", searchParameterProperty: "VendorCode" },
  Countries: <IFilterDefWithTypeahead<number, QuoteSearchParameters>>{ name: "Vendor Country", inputType: "autocomplete", options: [], searchParameterProperty: "VendorCountries" },

  ContactName: <IFilterDef<QuoteSearchParameters>>{ name: "Contact Name", inputType: "text", hintText: "Executes a 'contains' search", searchParameterProperty: "ContactName" },

  PersonResponsibles: <IFilterDefWithTypeahead<number, QuoteSearchParameters>>{ name: "Person Responsible", inputType: "autocomplete", options: [], searchParameterProperty: "PersonResponsibleIds" },
  TrialYears: <IFilterDefWithOptions<number, QuoteSearchParameters>>{ name: "Years", inputType: "selectMulti", options: [], searchParameterProperty: "PaymentLineItemYears" },
  BudgetYears: <IFilterDefWithOptions<number, QuoteSearchParameters>>{ name: "Budget Years", inputType: "selectMulti", options: [], searchParameterProperty: "BudgetYears" },
  TrialNontrialNumber: <IFilterDef<QuoteSearchParameters>>{ name: "Number", inputType: "text", hintText: "Executes a 'contains' search", searchParameterProperty: "TrialNontrialNumber" },
  PaidByCommercial: <IFilterDefWithOptions<boolean, QuoteSearchParameters>>{ name: "Paid by Commercial", inputType: "selectSingle", options: [], searchParameterProperty: "PaidByCommercial" },

  QuoteFspsNumber: <IFilterDef<QuoteSearchParameters>>{ name: "Quote FSPS Number", inputType: "text", searchParameterProperty: "QuoteNumber" },
  QuoteYear: <IFilterDefWithOptions<number, QuoteSearchParameters>>{ name: "Quote Year", inputType: "selectSingle", options: [], searchParameterProperty: "QuoteYear" },
  QuoteStatus: <IFilterDefWithOptions<QuoteStatus, QuoteSearchParameters>>{ name: "Quote Status", inputType: "selectSingle", options: [], searchParameterProperty: "QuoteStatus" },
  PurchaseOrder: <IFilterDef<QuoteSearchParameters>>{ name: "PO", inputType: "text", hintText: "Executes a 'contains' search", searchParameterProperty: "PO" },
  PurchaseRequest: <IFilterDef<QuoteSearchParameters>>{ name: "PR", inputType: "text", hintText: "Executes a 'contains' search", searchParameterProperty: "PR" }
}

var filterCategories: IFilterCategoryDef<QuoteSearchParameters>[] = [
  {
    categoryName: "Vendor",
    filters: [
      namedFilters.VendorName,
      namedFilters.VendorCode,
      namedFilters.Countries
    ]
  },
  {
    categoryName: "Contact",
    filters: [
      namedFilters.ContactName
    ]
  },
  {
    categoryName: "Trial/Non-Trial",
    filters: [
      namedFilters.PersonResponsibles,
      namedFilters.TrialYears,
      namedFilters.BudgetYears,
      namedFilters.TrialNontrialNumber,
      namedFilters.PaidByCommercial
    ]
  },
  {
    categoryName: "Quote",
    filters: [
      namedFilters.QuoteFspsNumber,
      namedFilters.QuoteYear,
      namedFilters.QuoteStatus,
      namedFilters.PurchaseOrder,
      namedFilters.PurchaseRequest
    ]
  }
];

var filters: IFilterDef<QuoteSearchParameters>[] = [
  namedFilters.VendorName,
  namedFilters.VendorCode,
  namedFilters.Countries,
  namedFilters.ContactName,
  namedFilters.PersonResponsibles,
  namedFilters.BudgetYears,
  namedFilters.TrialYears,
  namedFilters.QuoteFspsNumber,
  namedFilters.QuoteYear,
  namedFilters.QuoteStatus,
  namedFilters.PurchaseOrder,
  namedFilters.PurchaseRequest,
  namedFilters.TrialNontrialNumber
]

export var filterDefinitions = {
  namedFilters: namedFilters,
  filterCategories: filterCategories,
  filters: filters,
  mapFiltersFromApi: (response: QuoteSearchDropdowns) => {
    namedFilters.Countries.options = response.Countries.map(a => <TypeaheadItem<number>>{
      DisplayText: a.CountryName,
      Item: a.CountryId
    });
    namedFilters.PersonResponsibles.options = response.PersonResponsible.map(a => <TypeaheadItem<number>>{
        DisplayText: a.LastName + ", " + a.FirstName,
        Item: a.PersonId
    });
    namedFilters.BudgetYears.options = response.BudgetYears.map(a => <SelectOption<number>>{ display: a.toString(), value: a });
    namedFilters.TrialYears.options = response.Years.map(a => <SelectOption<number>>{ display: a.toString(), value: a });
    namedFilters.QuoteYear.options = response.Years.map(a => <SelectOption<number>>{ display: a.toString(), value: a });
    namedFilters.QuoteStatus.options = response.QuoteStatuses.map((a: QuoteSearchStatus) => <SelectOption<number>>{ display: a.Description, value: a.Status });
    namedFilters.PaidByCommercial.options = [{ display: "Yes", value: true }, { display: "No", value: false }];
  }
}
