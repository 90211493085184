import { Injectable, OnInit } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { map, Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { AppInfoService } from './singletons/app-info.service';

export interface EnvironmentAppInsightsConfig {
  ConnectionString: string;
  Url: string;
}

@Injectable()
export class AppMonitoringService {
  appInsights: ApplicationInsights;

  constructor(private service: AppInfoService) {
    console.log("App monitoring service constructor");

    this.GetEnvironmentSpecificAppInsightsConfig().subscribe(c => {
      let connectionString: string;

      connectionString = c.ConnectionString;

      console.log("Using Application connection string: " + connectionString);

      this.appInsights = new ApplicationInsights({
        config: {
          connectionString: connectionString,
          enableAutoRouteTracking: true // option to log all route changes
        }
      });

      this.appInsights.loadAppInsights();
    });
  }

  public GetEnvironmentSpecificAppInsightsConfig = (): Observable<EnvironmentAppInsightsConfig> => {

    return this.service.getAppStartupInfo()
      .pipe(
        map(info => {

          let environmentName: "LOCAL" | "DEV" | "QA" | "DEMO" | "PROD" = info.EnvironmentName as any;

          let envMapping = {
            "LOCAL": environment.ApplicationInsights.Dev,
            "DEV": environment.ApplicationInsights.Dev,
            "QA": environment.ApplicationInsights.Qa,
            "DEMO": environment.ApplicationInsights.Train,
            "PROD": environment.ApplicationInsights.Prod
          };

          return envMapping[environmentName];
        })
      );
  }

  logPageView(name?: string, url?: string) { // option to call manually
    this.appInsights.trackPageView({
      name: name,
      uri: url
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name: name }, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message: message }, properties);
  }
}
