<h1 mat-dialog-title>
  <ng-container *ngIf="Quote">
   
      <span>Edit Quote {{Quote.QuoteNumber}} - </span>
       <a  *ngIf="!isFromVendor" class="vendor-link" (click)="editVendor(Quote.VendorId)">{{Quote.VendorName}} ({{Quote.VendorCode}})</a>
       <span *ngIf="isFromVendor">{{Quote.VendorName}} ({{Quote.VendorCode}})</span>
  </ng-container>
</h1>

<div mat-dialog-content #modalContainer scrollInsetShadow>
  <form name="quote-create-form" #quoteEditForm="ngForm" style="padding-bottom: 15px;">

    <ng-container *ngIf="Quote">

      <div class="row">
        <div class="col-sm-9">

          <div class="row">
            <div class="col-sm-6">

              <div class="row">
                <div class="col-sm-8">
                  <ng-container *ngTemplateOutlet="vendorQuoteNumber"></ng-container>
                </div>
                <div class="col-sm-4">
                  <ng-container *ngTemplateOutlet="quoteStatus"></ng-container>
                </div>

              </div>
              <div class="row">
                <div class="col-sm-12">
                  <ng-container *ngTemplateOutlet="comments"></ng-container>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-7">
                  <ng-container *ngTemplateOutlet="prNumber"></ng-container>
                </div>
                <div class="col-sm-5">
                  <ng-container *ngTemplateOutlet="poStart"></ng-container>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-7">
                  <ng-container *ngTemplateOutlet="poNumber"></ng-container>
                </div>
                <div class="col-sm-5">
                  <ng-container *ngTemplateOutlet="poEnd"></ng-container>
                </div>
              </div>

            </div>
            <div class="col-sm-6">

              <div class="row">


                <div class="col-sm-4">
                  <ng-container *ngTemplateOutlet="year"></ng-container>
                </div>
                <div class="col-sm-8">
                  <ng-container *ngTemplateOutlet="contactName"></ng-container>
                </div>

              </div>
              <div class="row">
                <div class="col-sm-12">
                  <ng-container *ngTemplateOutlet="personResponsible"></ng-container>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-6">
                  <ng-container *ngTemplateOutlet="agreementStartDate"></ng-container>
                </div>
                <div class="col-sm-6">
                  <ng-container *ngTemplateOutlet="agreementEndDate"></ng-container>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <ng-container *ngTemplateOutlet="poRequired"></ng-container>
                </div>
                <div class="col-sm-6">
                  <ng-container *ngTemplateOutlet="total"></ng-container>
                </div>
              </div>
            </div>
          </div>



        </div>
        <div class="col-sm-3">
          <document-picker [documents]="Quote.Documents" [canAdd]="!isDocumentChangeDisabled()" [canRemove]="!isDocumentChangeDisabled()" [isAtLeastOneRequired]="IsAtLeastOneDocumentRequired()"></document-picker>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="mat-elevation-z8" style="margin-top: 8px;">
            <div class="data-table-quote">
              <quote-edit-modal-line-item-assoc-grid [isGridDisabled]="isGridDisabled()" [rows]="lineItemRows" [budgetYears]="budgetYears" [quoteId]="Quote.Id" [hasDocs]="checkDocumentStatus()"></quote-edit-modal-line-item-assoc-grid>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-template #vendorQuoteNumber>
      <mat-form-field>
        <mat-label>Vendor Quote Number</mat-label>
        <input matInput [(ngModel)]="Quote.VendorAssignedQuoteNumber" #vendorQuoteNumber name="vendorQuoteNumber" maxlength="255" />
      </mat-form-field>
    </ng-template>
    <ng-template #quoteStatus>
      <mat-form-field>
        <mat-label>Status</mat-label>
        <mat-select required [(ngModel)]="Quote.Status" #quoteStatus name="quoteStatus">
          <mat-option *ngFor="let status of Dropdowns.QuoteStatuses" [value]="status.id" [disabled]="!status.isEnabled()">{{status.label}}</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-template>
    <ng-template #comments>
      <mat-form-field>
        <mat-label>Comments</mat-label>
        <input matInput [(ngModel)]="Quote.Comments" #comments name="comments" maxlength="255" />
      </mat-form-field>
    </ng-template>
    <ng-template #year>
      <mat-form-field>
        <mat-label>Year</mat-label>
        <input matInput [(ngModel)]="Quote.Year" name="year" disabled />
      </mat-form-field>
    </ng-template>
    <ng-template #personResponsible>
      <mat-form-field>
        <mat-label matTooltip="Trial/Non-Trial Responsible Person">Trial/Non-Trial Investigator</mat-label>
        <input matInput [(ngModel)]="Quote.FieldScientistName" name="personResponsible" disabled />
      </mat-form-field>
    </ng-template>
    <ng-template #contactName>
      <mat-form-field>
        <mat-label>Vendor/Contact</mat-label>
        <input matInput [(ngModel)]="Quote.ContactName" name="contact" disabled />
        <mat-hint>{{Quote.ContactEmail?Quote.ContactEmail:"No Email for this Contact"}}</mat-hint>
      </mat-form-field>
    </ng-template>
    <ng-template #agreementStartDate>
      <mat-form-field>
        <mat-label>Agreement Start Date</mat-label>
        <input matInput [(ngModel)]="ActiveAgreement.StartDate" name="agreementStart" disabled />
      </mat-form-field>
    </ng-template>
    <ng-template #agreementEndDate>
      <mat-form-field>
        <mat-label>Agreement End Date</mat-label>
        <input matInput [(ngModel)]="ActiveAgreement.EndDate" name="agreementEnd" disabled />
      </mat-form-field>
    </ng-template>
    <ng-template #poRequired>
      <mat-form-field>
        <mat-label>PO Required</mat-label>
        <input matInput [(ngModel)]="PurchaseOrderRequired" name="poRequired" disabled />
      </mat-form-field>
    </ng-template>
    <ng-template #prNumber>
      <mat-form-field>
        <mat-label>PR</mat-label>
        <input matInput [(ngModel)]="Quote.PurchaseRequisitionNumber" #pr name="pr" maxlength="255" [disabled]="isPurchaseRequisitionDisabled()" />
      </mat-form-field>
    </ng-template>
    <ng-template #poNumber>
      <mat-form-field>
        <mat-label>PO</mat-label>
        <input matInput [(ngModel)]="Quote.PurchaseOrderNumber" #po name="po" maxlength="255" [disabled]="isPurchaseOrderDisabled()" />
      </mat-form-field>
    </ng-template>
    <ng-template #poStart>
      <mat-form-field>
        <mat-label>PO Start</mat-label>
        <input matInput [(ngModel)]="Quote.PurchaseOrderStartDate" #poStart [required]="Quote.IsPurchaseOrderRequired" name="poStart"
               [matDatepicker]="startDate" [disabled]="isPurchaseOrderDisabled()" />
        <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
        <mat-datepicker #startDate></mat-datepicker>
        <mat-error *ngIf="poStart.errors?.required">
          Start Date is required
        </mat-error>
      </mat-form-field>
    </ng-template>
    <ng-template #poEnd>
      <mat-form-field>
        <mat-label>PO End</mat-label>
        <input matInput [(ngModel)]="Quote.PurchaseOrderEndDate" #poEnd [required]="Quote.IsPurchaseOrderRequired" name="poEnd"
               [matDatepicker]="endDate" [min]="Quote.PurchaseOrderStartDate" [disabled]="isPurchaseOrderDisabled()" />
        <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
        <mat-datepicker #endDate></mat-datepicker>
        <mat-error *ngIf="poEnd.errors?.required">
          End Date is required
        </mat-error>
        <mat-error *ngIf="poEnd.errors?.matDatepickerMin">
          End Date must be after Start Date
        </mat-error>
      </mat-form-field>
    </ng-template>
    <ng-template #total>
      <mat-form-field>
        <mat-label>Total</mat-label>
        <input matInput  name="totalcommited" [disabled]="true" value="{{GetTotalCommitedAmount() | number}}" />
      </mat-form-field>
    </ng-template>
  </form>
</div>

<div mat-dialog-actions style="display: flex">
  <dl class="dl-horizontal modal-audit-info" *ngIf="Quote">
    <dt *ngIf="Quote.CreatedByName != undefined && Quote.CreatedByPersonId != 0">Created:</dt>
    <dd *ngIf="Quote.CreatedByName != undefined && Quote.CreatedByPersonId != 0">{{ Quote.CreatedDate | date:'d-LLL-y' }} by {{ Quote.CreatedByName }}</dd>
    <dt *ngIf="Quote.UpdatedByName != undefined && Quote.UpdatedByPersonId != 0">Updated:</dt>
    <dd *ngIf="Quote.UpdatedByName != undefined && Quote.UpdatedByPersonId != 0">{{ Quote.UpdatedDate | date:'d-LLL-y' }} by {{ Quote.UpdatedByName }}</dd>
    <dt *ngIf="Quote.SubmittedByPersonId != undefined && Quote.SubmittedByPersonId != 0">Submitted:</dt>
    <dd *ngIf="Quote.SubmittedByPersonId != undefined && Quote.SubmittedByPersonId != 0">{{ Quote.SubmittedDate | date:'d-LLL-y' }} by {{ Quote.SubmittedByPersonName }}</dd>
    <dt *ngIf="Quote.LastApprovedByPersonId != undefined && Quote.LastApprovedByPersonId != 0">Last Approved:</dt>
    <dd *ngIf="Quote.LastApprovedByPersonId != undefined && Quote.LastApprovedByPersonId != 0">{{ Quote.LastApprovedDate | date:'d-LLL-y' }} by {{ Quote.LastApprovedByPersonName }}</dd>
  </dl>

  <span style="flex: 1 1 auto;"></span>
  <button mat-raised-button (click)="ExportAriba()">
    <mat-icon>save_alt</mat-icon>Export Ariba Template</button>
  <button mat-raised-button (click)="AddInvoice()" [disabled]="isInvoiceButtonDisabled()">
    <mat-icon>add</mat-icon>Create FSPS Invoice
  </button>
  <button mat-raised-button color="warn" (click)="DeleteQuote()" [disabled]="DisableDelete()">Delete</button>
  <button mat-raised-button color="accent" (click)="CancelModal()">Cancel</button>
  <button mat-raised-button color="primary" (click)="SaveQuote(quoteEditForm)">Save</button>
  <button mat-raised-button color="primary" (click)="SaveQuoteAndClose(quoteEditForm)">Save &amp; Close</button>
</div>
