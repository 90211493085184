<div class="row">
  <div class="col-sm-4">
    <mat-form-field>
      <mat-label>Trial/Non-Trial Year</mat-label>
      <mat-select [required]="ValuesRequired" [(ngModel)]="Year" #year name="year" (selectionChange)="onYearSelected($event.value)" [disabled]="VendorId == null">
        <mat-option *ngIf="!ValuesRequired" [value]="null"></mat-option>
        <mat-option *ngFor="let year of Dropdowns.Years" [value]="year">{{year}}</mat-option>
        <mat-error *ngIf="year.errors?.required">
          Year is required
        </mat-error>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-sm-4">
    <mat-form-field>
      <mat-label matTooltip="Trial/Non-Trial Responsible Person">Trial/Non-Trial Investigator</mat-label>
      <mat-select [required]="ValuesRequired" [disabled]="Year == null || VendorId == null" [(ngModel)]="FieldScientistId" #fieldScientist name="field-scientist" (selectionChange)="onFieldScientistSelected($event.value)">
        <mat-option *ngIf="!ValuesRequired" [value]="null"></mat-option>
        <mat-option *ngFor="let a of Dropdowns.FieldScientists" [value]="a.Id">{{a.Name}}</mat-option>
        <mat-error *ngIf="fieldScientist.errors?.required">
          Investigator is required
        </mat-error>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-sm-4">
    <mat-form-field>
      <mat-label>Vendor/Contact</mat-label>
      <mat-select [required]="ValuesRequired" [disabled]="FieldScientistId == null || VendorId == null" [(ngModel)]="ContactId" #contact name="contact" (selectionChange)="onContactSelected($event.value)">
        <mat-option *ngIf="!ValuesRequired" [value]="null"></mat-option>
        <mat-option *ngFor="let a of Dropdowns.Contacts" [value]="a.Id">{{a.Name}}</mat-option>
        <mat-error *ngIf="contact.errors?.required">
          Contact is required
        </mat-error>
      </mat-select>
    </mat-form-field>
  </div>
</div>
