<div class="container-fluid">
  <div>
    <div class="page-header">
      <h3>Hello, world!</h3>
    </div>
    <div style="padding-left: 16px">
      <p>Welcome to the new Field Sciences Payment System website</p>
    </div>
   

    <div class="col-sm-3" style="padding: 0">
      <div *ngFor="let myLinkGroup of MyLinkGroups">
        <div class="page-header">
          <h3>{{myLinkGroup.groupdisplayName}}</h3>
        </div>
        <mat-nav-list class="quickLinks-no-scroll" style="padding: 0">
          <mat-list-item *ngFor="let myLink of myLinkGroup.quicklinks" (click)="navigateTo(myLink)">
            <a style="color: #0072CE;">{{myLink.displayName}}</a>
          </mat-list-item>
        </mat-nav-list>
      </div>
    </div>
  </div>
</div>
