export const environment = {
  production: true,
  ApplicationInsights: {
    Dev: {
      ConnectionString: "InstrumentationKey=a9c5e5e4-45a7-4947-95fe-eda57bb1af84;IngestionEndpoint=https://northcentralus-0.in.applicationinsights.azure.com/;LiveEndpoint=https://northcentralus.livediagnostics.monitor.azure.com/",
      Url: "https://portal.azure.com/#@agcompany.onmicrosoft.com/resource/subscriptions/3cee8701-d379-4262-adac-f38476ff3f26/resourceGroups/Research-FieldTrialManagementSystem-DEV/providers/Microsoft.Insights/components/ftms-ai/overview"
    },
    Qa: {
      ConnectionString: "InstrumentationKey=33898622-a5c4-49f6-8da0-25890372d1d5;IngestionEndpoint=https://northcentralus-0.in.applicationinsights.azure.com/;LiveEndpoint=https://northcentralus.livediagnostics.monitor.azure.com/",
      Url: "https://portal.azure.com/#@agcompany.onmicrosoft.com/resource/subscriptions/3cee8701-d379-4262-adac-f38476ff3f26/resourceGroups/Research-FieldTrialManagementSystem-TEST/providers/Microsoft.Insights/components/ftms-ai/overview"
    },
    Train: {
      ConnectionString: "InstrumentationKey=bcc401a2-b78a-4d29-8642-7a2433112ddb;IngestionEndpoint=https://northcentralus-0.in.applicationinsights.azure.com/;LiveEndpoint=https://northcentralus.livediagnostics.monitor.azure.com/",
      Url: "https://portal.azure.com/#@agcompany.onmicrosoft.com/resource/subscriptions/c09d2420-a96a-4215-ae78-53b993521d92/resourceGroups/Research-FieldTrialManagementSystem-TRAIN/providers/Microsoft.Insights/components/ftms-ai/overview"
    },
    Prod: {
      ConnectionString: "InstrumentationKey=6397f488-ec67-45a1-b313-fbc853701806;IngestionEndpoint=https://northcentralus-0.in.applicationinsights.azure.com/;LiveEndpoint=https://northcentralus.livediagnostics.monitor.azure.com/",
      Url: "https://portal.azure.com/#@agcompany.onmicrosoft.com/resource/subscriptions/c09d2420-a96a-4215-ae78-53b993521d92/resourceGroups/Research-FieldTrialManagementSystem-PROD/providers/Microsoft.Insights/components/ftms-ai/overview"
    }
  },
  AzureAD: {
    Authority: "https://login.microsoftonline.com/3e20ecb2-9cb0-4df1-ad7b-914e31dcdda4",
    ClientId: "ed08c800-c52a-4044-8169-2822900b281d",
    redirectUri: "/",
    postLogoutRedirectUri: "/"
  },
};
