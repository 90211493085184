import { Component, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { QuoteModalService } from "../../modals/quote-create-modal/quote-modal.service";
import { LoadingService } from "../../shared/services/loading-overlay/loading-overlay.component";
import { ContactforVendor, FieldScientistForVendor, QuoteDropdowns } from "./QuoteDropdowns";

@Component({
  selector: 'vendor-quote-filter',
  templateUrl: 'vendor-quote-filters.component.html'
})
export class VendorQuoteFilterComponent implements OnInit {
  private allFieldScientists: Array<FieldScientistForVendor> = [];
  private allContacts: Array<ContactforVendor> = [];
  public Year: number;
  public FieldScientistId: number;
  public ContactId: number;

  private _vendorId: number;
  @Input() set VendorId(value: number) {

    this._vendorId = value;
    this.Refresh();

  }
  get VendorId(): number {

    return this._vendorId;

  }

  @Input() public ValuesRequired: boolean = false;
  @Output() public ContactSelected: EventEmitter<number> = new EventEmitter<number>();
  @Output() public FieldScientistSelected: EventEmitter<number> = new EventEmitter<number>();
  @Output() public YearSelected: EventEmitter<number> = new EventEmitter<number>();

  constructor(public dialog: MatDialog,
    private loadingService: LoadingService,
    private quoteService: QuoteModalService) {
  }


  public Dropdowns = {
    Years: <Array<number>>[],
    Contacts: <Array<ContactforVendor>>[],
    FieldScientists: <Array<FieldScientistForVendor>>[],
  }

  ngOnInit(): void {
  }

  public Refresh = () => {
    if (this.VendorId == null)
      return;

    const dropdownsObservable = this.quoteService.GetQuoteDropdowns(this.VendorId);
    const loadRef = this.loadingService.StartWaiting("Fetching data");

    dropdownsObservable.subscribe(dropdowns => {
      if (dropdowns)
        this.SetDropdowns(dropdowns);

      loadRef.Stop();
    });
  }

  public SetDropdowns = (dropdowns: QuoteDropdowns) => {
    this.Dropdowns.Contacts = dropdowns.ContactsForVendor;
    this.Dropdowns.FieldScientists = dropdowns.FieldScientistsForVendor;
    this.Dropdowns.Years = dropdowns.Years;
    this.allContacts = dropdowns.ContactsForVendor;
    this.allFieldScientists = dropdowns.FieldScientistsForVendor;
  }

  public onYearSelected = (year: number) => {
    this.Dropdowns.FieldScientists = this.allFieldScientists.filter(c => c.Year == year);
    this.ContactId = null;
    this.FieldScientistId = null;
    this.YearSelected.emit(year);
  }

  public onFieldScientistSelected = (fieldScientistId: number) => {
    this.Dropdowns.Contacts = this.allContacts.filter(c => c.Associations.find(a => a.FieldScientistId == fieldScientistId && a.Year == this.Year));
    this.ContactId = null;
    this.FieldScientistSelected.emit(fieldScientistId);
  }

  public onContactSelected = (contactId: number) => {
    this.ContactSelected.emit(contactId);
  }
}
