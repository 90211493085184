import { Observable, ReplaySubject } from "rxjs";
import { ApiService } from "../shared/ApiService";
import { AppStartupInfo } from "./types/AppStartupInfo";
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppInfoService {
  private appStartupInfoSubject = new ReplaySubject<AppStartupInfo>(1);

  public constructor(public service: ApiService) {
    service.GET<AppStartupInfo>('api/v1/app/startup-info').subscribe((startupInfo) => {
      this.appStartupInfoSubject.next(startupInfo);
    });
  }

  public getAppStartupInfo(): Observable<AppStartupInfo> {
    return this.appStartupInfoSubject.asObservable();
  }
}
