
<mat-card dragAndDrop (onFileDropped)="droppedFiles($event)" [class.prevent-file-drop]="!canAdd">
  <mat-card-header class="mat-card-header-has-actions">
    <mat-card-title style="display: flex;">
      <span class="card-header-text">
        Documents
      </span>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>

    <mat-list scrollInsetShadow *ngIf="Files && Files.length > 0; else noFilesSelected">
      <mat-list-item *ngFor="let file of Files" [ngClass]="backgroundColor(file)">
        <h3 matLine [title]="file.Filename">
          <ng-container *ngIf="file.CanDownload">
            <a [href]="file.DownloadLink" target="_blank">
              {{file.Filename}}
            </a>
          </ng-container>
          <ng-container *ngIf="!file.CanDownload">
            {{file.Filename}}
          </ng-container>
        </h3>
        <div matLine>
          <mat-progress-bar *ngIf="file.IsUploading" mode="indeterminate"></mat-progress-bar>
          <ng-container *ngIf="!file.IsUploading">
            {{file.Status}}
          </ng-container>
        </div>
        <button mat-icon-button (click)="removeFile(file)" [disabled]="DisableDeleteButton()" type="button">
          <mat-icon>delete</mat-icon>
        </button>
      </mat-list-item>
    </mat-list>

    <ng-template #noFilesSelected>
      <div style="padding: 15px; text-align: center;">
        <em>No files selected. Drag-and-drop files or select Browse Files.</em>
      </div>
    </ng-template>
  </mat-card-content>
  <mat-divider inset></mat-divider>

  <div class="prevent-file-drop-ui">
    No files can be added.
  </div>
  <mat-card-actions style="display: flex;">
    <span style="flex-grow: 1; margin-left: 8px; display: flex; flex-direction: column; justify-content: center;">
      <span style="">
        {{GetOverallStatus()}}
      </span>
    </span>
    <button mat-raised-button color="accent" (click)="hiddenfileinput.click()" style="min-width: 100px;" [disabled]="!canAdd" type="button">Browse files</button>
    <input type="file" multiple style="display: none;" (change)="browsedFiles($event)" [(ngModel)]="inputValue" #hiddenfileinput />

  </mat-card-actions>

</mat-card>

