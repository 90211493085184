import { formatDate } from "@angular/common";
import { AfterViewInit, Component, Inject, Injectable, LOCALE_ID, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Data, Params, Router } from "@angular/router";
import { Observable } from "rxjs";
import { CurrentUserService } from "../../shared/CurrentUser.service";
import { AppRoles } from "../../shared/enums/AppRoles";
import { AppDateAdapter, APP_DATE_FORMATS } from "../../shared/format-datepicker";
import { FormControlHelper } from "../../shared/helpers/FormControlHelper";
import { ChangeDetectionService } from "../../shared/services/change-detection.service";
import { DialogService } from "../../shared/services/dialog.service";
import { LoadingService } from "../../shared/services/loading-overlay/loading-overlay.component";
import { InvoiceModalService } from "../invoice-modal/invoice-modal.service";
import { Invoice, InvoiceCreate, InvoiceStatus } from "../invoice-modal/types/Invoice";
import { QuoteModalService } from "../quote-create-modal/quote-modal.service";
import { Quote, QuotePaymentLineItemAssocUpdatesDto, QuoteStatus, QuoteUpdate } from "../quote-create-modal/types/Quote";
import { VendorDetailModal } from "../vendor-detail-modal/vendor-detail-modal.component";
import { VendorDetailModalService } from "../vendor-detail-modal/vendor-detail-modal.service";
import { YesNoModal } from "../yes-no-modal/yes-no-modal.component";
import { QuoteEditModalService, VendorQuoteLineItemDto } from "./quote-edit-modal.service";
import { QuoteEditModalHelper } from "./QuoteEditModalHelper";
import { QuoteEditAssocGridBudgetYear, QuoteEditAssocGridItem } from "./types/QuoteEditAssocGridItem";
import { QuoteStatusDescriptionDto } from "./types/QuoteStatusDescriptionDto";

export interface ShowQuoteEditModalData {
  /** Required */
  VendorId: number;
  QuoteId: number;
  isFromVendor?: boolean;
}

interface QuoteCloseModalResult {
  InvoiceIdToDisplay?: number;
  QuoteId: number;
}

interface QuoteStatusDef {
  id: QuoteStatus,
  label: string,
  isEnabled: { (): boolean },
  disableGrid: boolean,
  disableDocumentChanges: boolean,
  disablePurchaseRequisitionChanges: boolean,
  disablePurchaseOrderChanges: boolean
}

@Component({
  selector: 'quote-edit-modal',
  templateUrl: 'quote-edit-modal.component.html',
  styleUrls: [
    "quote-edit-modal.component.less",
  ],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class QuoteEditModalComponent implements OnInit {
  private changeDetectionService: ChangeDetectionService = null;
  public IsNewVendor: boolean = false;
  public UserIsAdmin: boolean = false;
  public UserRegionalAdminCountries: number[] = [];

  public Quote: Quote = null;

  public isFromVendor?: boolean;

  private GetQuoteStatusIsEnabled = (requireAdmin: boolean, allowableTransitionStatuses: Array<QuoteStatus>) => {
    if (!this.Quote)
      return false;

    if (requireAdmin && !this.UserAccess.IsAdminForQuote())
      return false;

    return allowableTransitionStatuses.indexOf(this.Quote.Status) >= 0;
  }

  public Dropdowns = {
    QuoteStatuses: <QuoteStatusDef[]>[
      {
        id: QuoteStatus.New,
        label: null,
        isEnabled: () => this.GetQuoteStatusIsEnabled(false, [QuoteStatus.New, QuoteStatus.ReadyForReview]),
        disableGrid: false,
        disableDocumentChanges: false,
        disablePurchaseOrderChanges: true,
        disablePurchaseRequisitionChanges: true
      },
      {
        id: QuoteStatus.ReadyForReview,
        label: null,
        isEnabled: () => {
          let isEnabled = this.GetQuoteStatusIsEnabled(false, [QuoteStatus.New, QuoteStatus.ReadyForReview, QuoteStatus.Approved])

          // dont want to allow non-admins to un-approve a quote
          if (isEnabled && this.Quote.Status > QuoteStatus.ReadyForReview && !this.UserAccess.IsAdminForQuote())
            isEnabled = false;

          if (this.SubmittedForReviewInvalid())
            isEnabled = false;
          return isEnabled;
        },
        disableGrid: true,
        disableDocumentChanges: true,
        disablePurchaseOrderChanges: true,
        disablePurchaseRequisitionChanges: true
      },
      {
        id: QuoteStatus.Approved,
        label: null,
        isEnabled: () => this.GetQuoteStatusIsEnabled(true, [QuoteStatus.ReadyForReview, QuoteStatus.Approved, QuoteStatus.PurchaseRequest]),
        disableGrid: true,
        disableDocumentChanges: true,
        disablePurchaseOrderChanges: true,
        disablePurchaseRequisitionChanges: true
      },
      {
        id: QuoteStatus.PurchaseRequest,
        label: null,
        isEnabled: () => this.GetQuoteStatusIsEnabled(true, [QuoteStatus.Approved, QuoteStatus.PurchaseRequest, QuoteStatus.PurchaseOrder, QuoteStatus.NoPurchaseOrder]),
        disableGrid: true,
        disableDocumentChanges: true,
        disablePurchaseOrderChanges: true,
        disablePurchaseRequisitionChanges: false
      },
      {
        id: QuoteStatus.PurchaseOrder,
        label: null,
        isEnabled: () => this.GetQuoteStatusIsEnabled(true, [QuoteStatus.PurchaseRequest, QuoteStatus.PurchaseOrder]),
        disableGrid: true,
        disableDocumentChanges: true,
        disablePurchaseOrderChanges: false,
        disablePurchaseRequisitionChanges: true
      },
      {
        id: QuoteStatus.NoPurchaseOrder,
        label: null,
        isEnabled: () => this.GetQuoteStatusIsEnabled(true, [QuoteStatus.PurchaseRequest, QuoteStatus.NoPurchaseOrder]),
        disableGrid: true,
        disableDocumentChanges: true,
        disablePurchaseOrderChanges: true,
        disablePurchaseRequisitionChanges: true
      }
    ]
  };

  public ActiveAgreement = {
    StartDate: "",
    EndDate: ""
  };

  public PurchaseOrderRequired: string = "";

  public UserAccess = {
    IsPaymentAdmin: false,
    PaymentAdminCountries: <number[]>[],
    IsAdminForQuote: () => {
      let isRegionalAdmin = false;
      if (this.Quote)
        isRegionalAdmin = this.UserAccess.PaymentAdminCountries.indexOf(this.Quote.VendorCountryId) >= 0;

      return this.UserAccess.IsPaymentAdmin || isRegionalAdmin;
    }
  }

  public lineItemRows: Array<QuoteEditAssocGridItem> = [];
  public budgetYears: Array<number> = [];
  private quoteEditModalHelper = new QuoteEditModalHelper();

  constructor(private dialogRef: MatDialogRef<QuoteEditModal, QuoteCloseModalResult>,
    @Inject(MAT_DIALOG_DATA) private showModalData: ShowQuoteEditModalData,
    public dialog: MatDialog,
    private loadingService: LoadingService,
    private quoteService: QuoteModalService,
    public dialogService: DialogService,
    private quoteEditModalService: QuoteEditModalService,
    private formControlHelper: FormControlHelper,
    private userService: CurrentUserService,
    private snackbar: MatSnackBar,
    private invoiceModalService: InvoiceModalService,
    private vendorDetailModal: VendorDetailModal,
    private vendorService: VendorDetailModalService,
    private router: Router,
    @Inject(LOCALE_ID) private locale_id: string) {
    this.changeDetectionService = new ChangeDetectionService(dialogService);
    this.userService.HasRole(AppRoles.PaymentAdmin).subscribe(hasRole => this.UserAccess.IsPaymentAdmin = hasRole);
    this.userService.GetPaymentAdminCountries().subscribe(paymentAdminCountries => this.UserAccess.PaymentAdminCountries = paymentAdminCountries);
  }

  private GetSelectedQuoteStatus = (): QuoteStatusDef => {
    if (!this.Quote)
      return null;
    return this.Dropdowns.QuoteStatuses.find(a => a.id == this.Quote.Status);
  }

  public isGridDisabled = (): boolean => {
    if (!this.Quote)
      return;

    return (this.Quote.Documents == null || this.Quote.Documents.length == 0) ||
      this.GetSelectedQuoteStatus().disableGrid;
  }

  private isFeatureDisabledByStatus = (predicate: { (selectedStatus: QuoteStatusDef): boolean }): boolean => {
    if (!this.Quote)
      return;
    else
      return predicate(this.GetSelectedQuoteStatus());
  }

  public isDocumentChangeDisabled = (): boolean => this.isFeatureDisabledByStatus(a => a.disableDocumentChanges);
  public isPurchaseRequisitionDisabled = (): boolean => this.isFeatureDisabledByStatus(a => a.disablePurchaseRequisitionChanges);
  public isPurchaseOrderDisabled = (): boolean => this.isFeatureDisabledByStatus(a => a.disablePurchaseOrderChanges);

  // Returns the budget year info from the line item grid, but only records tied to this quote.
  private GetQuotedBudgetYearInfo = (): Array<QuotePaymentLineItemAssocUpdatesDto> => {
    let results: Array<QuotePaymentLineItemAssocUpdatesDto> = [];

    this.lineItemRows.forEach(lineItem => {
      lineItem.BudgetYears.filter(by => this.quoteEditModalHelper.IsQuotedAmountForThisQuote(this.Quote.Id, by)).forEach(by => {
        results.push({
          AssocId: by.AssocId,
          Comment: lineItem.Comment,
          QuotedAmount: by.QuotedAmount
        });
      });
    });

    return results;
  }

  public GetTotalCommitedAmount = (): number => {
    let results = this.GetQuotedBudgetYearInfo();
    return results.length > 0 ? results.map((item) => Number(item.QuotedAmount)).reduce((acc, curr) => acc + curr, 0) : 0;;
  }

  public IsAtLeastOneDocumentRequired = (): boolean => {
    return this.GetQuotedBudgetYearInfo().length > 0;
  }

  private GetChangeDetectionData = () => {
    return {
      quote: this.Quote,
      quotedAmounts: this.GetQuotedBudgetYearInfo()
    }
  }

  public checkDocumentStatus = () => {

    return (this.Quote.Documents != null && this.Quote.Documents.length > 0) ? true : false;
  }

  ngOnInit(): void {
    const quoteObservable = this.quoteService.GetQuoteById(this.showModalData.VendorId, this.showModalData.QuoteId);
    const vendorLoadObservable = this.vendorService.GetVendor(this.showModalData.VendorId);
    const loadRef = this.loadingService.StartWaiting("Fetching data");
    this.isFromVendor = this.showModalData.isFromVendor;

    this.quoteEditModalService.GetQuoteStatusDropdownList().subscribe((dropdowns) => {
      this.Dropdowns.QuoteStatuses.forEach((item) => {
        let quoteStatus = dropdowns.filter(x => x.Status == item.id)[0];
        item.label = quoteStatus.Description;
      });
    });

    quoteObservable.subscribe(quote => {
      this.Quote = quote;
      if (quote.ActiveAgreementEndDate != undefined)
        this.ActiveAgreement.EndDate = formatDate(quote.ActiveAgreementEndDate, "d-LLL-y", this.locale_id);
      if (quote.ActiveAgreementStartDate != undefined)
        this.ActiveAgreement.StartDate = formatDate(quote.ActiveAgreementStartDate, "d-LLL-y", this.locale_id);

      this.PurchaseOrderRequired = quote.IsPurchaseOrderRequired ? "Yes" : "No";

      this.userService.HasRole(AppRoles.PaymentAdmin).subscribe(hasRole => {
        this.UserIsAdmin = this.UserIsAdmin || hasRole;
        vendorLoadObservable.subscribe((vendor) => {
          this.userService.GetPaymentAdminCountries().subscribe(countries => {
            this.UserRegionalAdminCountries = countries;
            var isRegionalAdmin = false;
            if (this.IsNewVendor)
              isRegionalAdmin = this.UserRegionalAdminCountries.length > 0;
            else
              isRegionalAdmin = this.UserRegionalAdminCountries.find(c => c == vendor.CountryId) != null;

            this.UserIsAdmin = this.UserIsAdmin || isRegionalAdmin;
          });
        });
      });

      this.quoteEditModalService.GetQuotePaymentLineItems(quote.VendorId, quote.Id, quote.ContactId, quote.FieldScientistId, quote.Year).subscribe(dtos => {

        loadRef.Stop();

        let grouped: Array<QuoteEditAssocGridItem> = [];
        let uniqueBudgetYears: Array<number> = [];

        dtos.forEach((lineItemDto) => {
          var dto: VendorQuoteLineItemDto = lineItemDto;
          dto.Assocs.forEach(assoc => {

            let budgetYear: QuoteEditAssocGridBudgetYear = {
              AssocId: assoc.Id,
              BudgetYear: assoc.BudgetYear,
              QuotedAmount: assoc.QuotedAmount,
              IsLocked: assoc.QuoteId !== undefined && assoc.QuoteId !== quote.Id,
              QuoteId: assoc.QuoteId,
              QuoteNumber: assoc.QuoteNumber
            }

            if (uniqueBudgetYears.indexOf(assoc.BudgetYear) < 0)
              uniqueBudgetYears.push(assoc.BudgetYear);

            var existing = grouped.find(a => a.ObjectiveName == assoc.ObjectiveName && a.LineItemId == dto.PaymentLineItem.Id);

            if (existing != null) {
              existing.BudgetYears.push(budgetYear);
            }
            else {
              existing = {
                CurrencyName: dto.PaymentLineItem.CurrencyName,
                LineItemId: dto.PaymentLineItem.Id,
                Number: dto.PaymentLineItem.Number,
                ObjectiveName: assoc.ObjectiveName,
                ShortTitle: dto.PaymentLineItem.ShortTitle,
                Status: dto.PaymentLineItem.Status,
                IsCommercial: dto.PaymentLineItem.IsCommercial,
                IsExternal: dto.PaymentLineItem.IsExternal,
                BudgetYears: [budgetYear],
                Comment: null,
                ItemType: dto.PaymentLineItem.ItemType,
                SourceSystemLink: dto.PaymentLineItem.SourceSystemLink
              };
              grouped.push(existing);
            }

            // set the row's comment
            if (existing.Comment == null && assoc.QuoteId == quote.Id)
              existing.Comment = assoc.Comment;

            existing[`by_${budgetYear.BudgetYear}`] = budgetYear;

          })
        });

        uniqueBudgetYears.sort();

        this.lineItemRows = grouped;
        this.budgetYears = uniqueBudgetYears;
        this.changeDetectionService.SetOriginalValue(this.GetChangeDetectionData());
      });
    });
  }

  public SaveQuoteAndClose = (form: NgForm) => this.Save(form, true);
  public SaveQuote = (form: NgForm) => this.Save(form, false);

  private Save = (form: NgForm, closeOnSave: boolean) => {
    if (!this.formControlHelper.CheckFormIsValid(form))
      return;

    const loadingRef = this.loadingService.StartWaiting("Updating Quote");

    const updateData: QuoteUpdate = {
      ContactId: this.Quote.ContactId,
      FieldScientistId: this.Quote.FieldScientistId,
      Year: this.Quote.Year,
      PurchaseRequisitionNumber: this.Quote.PurchaseRequisitionNumber,
      PurchaseOrderNumber: this.Quote.PurchaseOrderNumber,
      PurchaseOrderStartDate: this.Quote.PurchaseOrderStartDate,
      PurchaseOrderEndDate: this.Quote.PurchaseOrderEndDate,
      Comments: this.Quote.Comments,
      Status: this.Quote.Status,
      VendorAssignedQuoteNumber: this.Quote.VendorAssignedQuoteNumber,
      PaymentLineItemAssocUpdates: this.GetQuotedBudgetYearInfo(),
      DocumentIds: this.Quote.Documents.map(doc => doc.Id)
    }

    const apiResponse = this.quoteService.UpdateQuote(this.Quote.VendorId, this.Quote.Id, updateData);

    apiResponse.subscribe((result: Quote) => {
      loadingRef.Stop();

      this.Quote = result;
      this.changeDetectionService.SetOriginalValue(this.GetChangeDetectionData());

      if (closeOnSave) {
        this.dialogRef.close({
          QuoteId: result.Id
        });
      }
    }, (error) => {
      loadingRef.Stop();
    });
  }

  private SubmittedForReviewInvalid = (): boolean => {
    const quoteBudgetYearInfo = this.GetQuotedBudgetYearInfo();

    if (quoteBudgetYearInfo.length == 0 || quoteBudgetYearInfo.every(info => info.QuotedAmount <= 0))
      return true;

    return false;
  }

  public CancelModal = async () => {
    let shouldLeave = await this.CheckForChangesAndPrompt();
    if (!shouldLeave)
      return;

    this.dialogRef.close(undefined);
  }

  public DisableDelete = () => {
    if (this.Quote?.CanBeDeleted == null || !this.Quote.CanBeDeleted || this.changeDetectionService.HasChanges(this.GetChangeDetectionData()))
      return true;
    return false;
  }

  public DeleteQuote = async () => {
    let shouldLeave = await this.CheckForChangesAndPrompt();
    if (!shouldLeave)
      return;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      headerTitle: "Delete Quote", message: "Are you sure you want to delete this Quote?",
      yesText: "Yes",
      noText: "No"
    };
    const yesNoDialog = this.dialog.open(YesNoModal, dialogConfig);


    yesNoDialog.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        const loadingRef = this.loadingService.StartWaiting("Deleting Quote");
        this.quoteEditModalService.DeleteQuote(this.Quote.VendorId, this.Quote.Id).subscribe(async (result) => {
          loadingRef.Stop();

          this.snackbar.open("Quote deleted successfully.", null, {
            duration: 5000,
          });
          this.dialogRef.close({
            QuoteId: null
          });

        },
          (error) => {
            loadingRef.Stop();
          });
      }
    });
  }

  public AddInvoice = async () => {
    const loadingRef = this.loadingService.StartWaiting("Creating Invoice");
    var createData = <InvoiceCreate>({
      InvoiceStatus: InvoiceStatus.New,
      Year: this.Quote.Year,
      PurchaseOrderNumber: this.Quote.Status == QuoteStatus.PurchaseOrder ? this.Quote.PurchaseOrderNumber : null,
      PurchaseRequisitionNumber: this.Quote.Status == QuoteStatus.NoPurchaseOrder ? this.Quote.PurchaseRequisitionNumber : ''
    });

    var apiResponse = this.invoiceModalService.CreateInvoice(this.Quote.VendorId, createData);
    apiResponse.subscribe((result: Invoice) => {
      loadingRef.Stop();
      // Close modal
      this.dialogRef.close({
        QuoteId: this.Quote.Id,
        InvoiceIdToDisplay: result.Id
      });
    }, (error) => {
      this.snackbar.open("There was a problem creating this invoice.", null, {
        duration: 5000,
      });
      loadingRef.Stop();
    });
  }

  public isInvoiceButtonDisabled = (): boolean => {
    if (this.Quote == null)
      return true;

    if (this.Quote.Status < QuoteStatus.PurchaseOrder)
      return true;

    if (!this.Quote.VendorActive)
      return true;

    if (this.changeDetectionService.HasChanges(this.GetChangeDetectionData()))
      return true;

    if (!this.UserIsAdmin)
      return true;

    return false
  }

  private async CheckForChangesAndPrompt(): Promise<boolean> {
    if (this.changeDetectionService.HasChanges(this.GetChangeDetectionData())) {
      let shouldLeave = await this.changeDetectionService.HasChangeDialog();
      if (!shouldLeave)
        return false;
    }
    return true;
  }

  editVendor = (vendorId: number) => {
    if (!this.isFromVendor) {
      this.vendorDetailModal.ShowModal({
        vendorId: vendorId,
        isFromQuote: true
      });
    }

  }

  public ExportAriba = () => {
    const urlTree = this.router.createUrlTree([`api/v1/vendors/${this.Quote.VendorId}/quotes/${this.Quote.Id}/ariba-export`], {});
    const fileDownloadLink = window.location.origin + urlTree.toString();
    window.open(fileDownloadLink, "_self");
  }
}

@Injectable()
export class QuoteEditModal {
  constructor(@Inject(MatDialog) private dialog: MatDialog) {
  }

  ShowStandaloneModal(data: Data, params: Params): Observable<any> {
    var modalParams: ShowQuoteEditModalData =
    {
      VendorId: Number(params.get('vendorId')),
      QuoteId: Number(params.get('quoteId'))
    };

    return this.ShowModal(modalParams);
  }

  public ShowModal =
    /** Displays the Quote modal. Returns the Quote id when the user closes the modal, or undefined if no Quote was created
     * @returns {Observable<number | undefined>} The Quote ID or undefined if no vendor was created.
     */
    (data: ShowQuoteEditModalData): Observable<QuoteCloseModalResult | undefined> => {
      const ref = this.dialog.open(QuoteEditModalComponent, {
        data: data,
        width: "1500px",
        disableClose: true,
        autoFocus: false,
      });

      return ref.afterClosed();
    }
}
