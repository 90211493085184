import { Component, Input, OnInit } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { ActivatedRoute, Router } from "@angular/router";
import { catchError, Observable, of, Subscription } from "rxjs";
import { CurrentUserService } from "../../shared/CurrentUser.service";
import { GridDisplaySettings } from "../../shared/GridDisplaySettings";
import { IFilterCategoryDef, IFilterDefWithDateRange, ISelectedFilterDef, ISelectedFilterDefWithDateRange, SelectOption } from "../../shared/interfaces/IFilterDef";
import { PagedResults } from "../../shared/interfaces/PagedResults";
import { LoadingService } from "../../shared/services/loading-overlay/loading-overlay.component";
import * as paginator from '../../shared/services/PaginatorService';
import { QuickLinksService } from "../../shared/services/quickLinks.service";
import { SearchType } from "../../shared/services/search-storage.service";
import { TypeaheadItem } from "../typeahead/typeahead.component";

export interface GenericSearchPaginationSettings {
  IsDescending: boolean,
  PageIndex: number,
  SortColumn: string,
  ItemsPerPage: number,
}

@Component({
  selector: 'generic-search-page',
  templateUrl: 'generic-search-page.component.html'
})
export class GenericSearchPageComponent<TRow, TSearchCriteria extends GridDisplaySettings> {

  @Input() public PageTitle: string;
  @Input() public filters: IFilterCategoryDef<TSearchCriteria>[] = [];
  public selectedFilters: ISelectedFilterDef<TSearchCriteria>[] = [];

  public Options = {
    CreateRow: <{ (): Observable<boolean> }>null,
    EditRow: <{ (row: TRow): Observable<boolean> }>null,
    SearchForRows: <{ (searchCriteria: TSearchCriteria): Observable<PagedResults<TRow>> }>null,
    SearchHistoryType: <SearchType>null,
  };

  navigationExtras: any;
  public data: TRow[] = null;
  private searchSubscription: Subscription = null;
  selectedRow: TRow = null;
  pageIndex: number = 0;
  public itemsPerPage = paginator.itemsPerPageDefault();
  public pageSizeOptions = paginator.pageSizeOptions();
  totalCount: number = 0;
  isSortDescending: boolean = false;
  isLoading: boolean = false;
  sortColumn: string = "";
  public CollapseSearch: boolean = false;

  constructor(private router: Router, private loadingService: LoadingService, private currentUserService: CurrentUserService, private quicklinksService: QuickLinksService) {
    this.navigationExtras = this.router.getCurrentNavigation().extras.state;
  }

  public InitializeFilters = () => {
    if (!this.navigationExtras)
      return;

    for (const quicklinkProperty in this.navigationExtras) {
      this.filters.forEach(filterCategory => {
        filterCategory.filters.forEach(filter => {
          if (filter.searchParameterProperty && quicklinkProperty == filter.searchParameterProperty.toString()) {
            var navParam = this.navigationExtras[quicklinkProperty]
            switch (filter.inputType) {
              case "text":
                this.selectedFilters.push({ filter: filter, searchValue: navParam });
                break;
              case "autocomplete":
                let autocomplete = (filter.options as Array<TypeaheadItem<any>>).filter(option => navParam.includes(option.Item));
                this.selectedFilters.push({ filter: filter, searchValue: autocomplete.map(option => option.Item) });
                break;
              case "selectSingle":
                let selectSingle = (filter.options as Array<SelectOption<any>>).find(option => option.value == navParam);
                this.selectedFilters.push({ filter: filter, searchValue: selectSingle ? selectSingle.value : null });
                break;
              case "selectMulti":
                var selectedOptions = (filter.options as Array<SelectOption<any>>).filter(option => navParam.includes(option.value));
                this.selectedFilters.push({ filter: filter, searchValue: selectedOptions.map(option => option.value) });
                break;
              case "none":
                this.selectedFilters.push({ filter: filter, searchValue: filter.defaultValue });
                break;
            }
          }
          else {
            var dateRangeFilter = filter as IFilterDefWithDateRange<TSearchCriteria>;
            var filterInUse = this.selectedFilters.some(f => f.filter == filter);
            var propertyMatchesDateParameter = (dateRangeFilter.EndDateSearchParameterProperty && quicklinkProperty == dateRangeFilter.EndDateSearchParameterProperty.toString()) || (dateRangeFilter.StartDateSearchParameterProperty && quicklinkProperty == dateRangeFilter.StartDateSearchParameterProperty.toString());

            if (!filterInUse && propertyMatchesDateParameter) {
              this.selectedFilters.push({
                filter: filter,
                EndDateSearchValue: this.navigationExtras[dateRangeFilter.EndDateSearchParameterProperty],
                StartDateSearchValue: this.navigationExtras[dateRangeFilter.StartDateSearchParameterProperty]
              } as ISelectedFilterDefWithDateRange<TSearchCriteria>)
            }
          }
        });
      })
    }

    this.updateItems();
  }

  private subscriptionsToClose: Subscription[] = [];
  ngOnDestroy(): void {
    [this.searchSubscription, ...this.subscriptionsToClose].forEach(s => {
      if (s != null && !s.closed) {
        s.unsubscribe();
      }
    });
  }

  public GetCurrentPageSettings = () : GenericSearchPaginationSettings => {
    return {
      IsDescending: this.isSortDescending,
      PageIndex: this.pageIndex,
      SortColumn: this.sortColumn,
      ItemsPerPage: this.itemsPerPage
    };
  }

  updateItems = () => {
    this.isLoading = true;

    this.sortColumn = this.sortColumn.toLowerCase();
    this.data = [];

    if (this.searchSubscription != null && this.searchSubscription.closed == false) {
      this.searchSubscription.unsubscribe();
    }

    this.searchSubscription = this.Options.SearchForRows(this.GetSearchParameters(this.GetCurrentPageSettings()))
      .pipe(
        catchError(err => of([]))
      )
      .subscribe((ps: PagedResults<TRow>) => {
        if (ps) {
          this.data = ps.Rows;
          this.totalCount = ps.TotalCount;
        }
        this.isLoading = false;
      });
  }

  public ToggleSearchSection = () => {
    this.CollapseSearch = !this.CollapseSearch;
  }

  public OnSearchButtonClick = () => {
    this.pageIndex = 0;
    this.updateItems();
  }

  setSelection = (row: TRow) => {
    this.selectedRow = row;
  }

  create = () => {
    this.Options.CreateRow().subscribe(shouldRefresh => {
      if (shouldRefresh)
        this.updateItems();
    });
  }

  edit = (row: TRow) => {
    this.Options.EditRow(row).subscribe(shouldRefresh => {
      if (shouldRefresh)
        this.updateItems();
    });
  }

  public GetSearchParameters = (page: GenericSearchPaginationSettings): TSearchCriteria => {

    var parameters: TSearchCriteria = <any>{
      IsDescending: page.IsDescending,
      ItemsPerPage: page.ItemsPerPage,
      OrderBy: page.SortColumn,
      Page: page.PageIndex,
    };

    this.selectedFilters.forEach(filter => {
      if (filter.filter.searchParameterProperty) {
        (<any>parameters)[filter.filter.searchParameterProperty] = filter.searchValue;
      }
      else {
        var selectedDateRangeFilter = filter as ISelectedFilterDefWithDateRange<TSearchCriteria>;
        var dateRangeFilter = selectedDateRangeFilter.filter as IFilterDefWithDateRange<TSearchCriteria>;
        (<any>parameters)[dateRangeFilter.StartDateSearchParameterProperty] = selectedDateRangeFilter.StartDateSearchValue;
        (<any>parameters)[dateRangeFilter.EndDateSearchParameterProperty] = selectedDateRangeFilter.EndDateSearchValue;
      }
    });

    return parameters;
  }

  public onPageChanged = (e: PageEvent) => {
    this.itemsPerPage = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.updateItems();
    this.selectedRow = undefined;
  }

  public onSortChange = (sorter: MatSort): void => {
    this.sortColumn = sorter.active;
    if (sorter.direction === 'asc')
      this.isSortDescending = false;
    else this.isSortDescending = true;
    this.updateItems();
  }
}
