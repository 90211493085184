import { NativeDateAdapter } from "@angular/material/core";
import { MatDateFormats } from "@angular/material/core";

// Custom date format helper function, formats to d-m-y
// for Angular Material datepicker, uses NativeDateAdapter from  "@angular/material"
export class AppDateAdapter extends NativeDateAdapter {
    format(date: Date, displayFormat: Object): string {
        if (displayFormat === "input") { // the displayed date within the datepicker
            return date.toLocaleDateString('en-GB', { // use en-GB locale because it uses the d-m-y format as default
                day: 'numeric', month: 'short', year: 'numeric' // format the month using the short description. i.e. 'Jul', 'Aug', 'Sep', etc
            }).replace(/ /g, '-'); // convert spaces with displayed date to a dash
        }
        return date.toDateString();
    }
}
export const APP_DATE_FORMATS: MatDateFormats = {
    parse: {
        dateInput: { month: "short", year: "numeric", day: "numeric" },
    },
    display: {
        dateInput: "input",
        monthYearLabel: { year: "numeric", month: "numeric" },  // This is the date format in which calendar displays the month-year label
        dateA11yLabel: { year: "numeric", month: "long", day: "numeric" }, 
        monthYearA11yLabel: { year: "numeric", month: "long" },
    }
};