<div class="container">
  <h1>Missing role(s)</h1>

  <ul>
    <li *ngFor="let role of missingRoleNames">{{role}}</li>
  </ul>
  <p style="margin-left: 15px;">
    To request access please email:
    <a href="mailto:DL-FTMSAdmins@corteva.com">DL - FTMS Admins</a>.
    Please include the Role name and justification in the request.
  </p>
</div>
