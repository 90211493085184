<h1 mat-dialog-title>
  <ng-container *ngIf="Invoice">

    <span>
      Edit Invoice {{Invoice.InvoiceNumber}} -
      <a *ngIf="!isFromVendor" class="vendor-link" (click)="editVendor(Invoice.VendorId)">{{Invoice.VendorName}} ({{Invoice.VendorCode}}) </a>
      <span style="display:inline" *ngIf="isFromVendor"> {{Invoice.VendorName}} ({{Invoice.VendorCode}})</span>
      <span class="sub-title">
        <ng-container *ngIf="isPurchaseOrderInvoice">
          Purchase Order: {{Invoice.PurchaseOrderNumber}}
        </ng-container>
        <ng-container *ngIf="isPurchaseRequestInvoice">
          Purchase Request: {{Invoice.PurchaseRequisitionNumber}}
        </ng-container>
      </span>
    </span>
  </ng-container>
</h1>

<div mat-dialog-content #modalContainer scrollInsetShadow>
  <form name="invoice-edit-form" #invoiceEditForm="ngForm" style="padding-bottom: 15px;">

    <ng-container *ngIf="Invoice">

      <div class="row">
        <div class="col-sm-9">

          <div class="row">
            <div class="col-sm-6">
              <div class="row">
                <ng-container *ngTemplateOutlet="vendorInvoiceNumber; context: cols.sm8"></ng-container>
                <ng-container *ngTemplateOutlet="invoiceStatus; context: cols.sm4"></ng-container>
              </div>
              <div class="row">
                <ng-container *ngTemplateOutlet="vendorInvoiceDate; context: cols.sm6"></ng-container>
                <ng-container *ngTemplateOutlet="dueDate; context: cols.sm6"></ng-container>
              </div>
              <div class="row">
                <ng-container *ngTemplateOutlet="checkNumber; context: cols.sm6"></ng-container>
                <ng-container *ngTemplateOutlet="paidDate; context: cols.sm6"></ng-container>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="row">
                <ng-container *ngTemplateOutlet="timeline; context: cols.sm6"></ng-container>
                <ng-container *ngTemplateOutlet="timelineAgreement; context: cols.sm6"></ng-container>
              </div>
              <div class="row">
                <ng-container *ngTemplateOutlet="poNumber; context: cols.sm6"></ng-container>
                <ng-container *ngTemplateOutlet="prNumber; context: cols.sm6"></ng-container>
              </div>
              <div class="row">
                <ng-container *ngTemplateOutlet="poEnd; context: cols.sm6"></ng-container>
                <ng-container *ngTemplateOutlet="agreementEnd; context: cols.sm6"></ng-container>
              </div>
              <div class="row">
                <ng-container *ngTemplateOutlet="paymentPlan; context: cols.sm6"></ng-container>
                <ng-container *ngTemplateOutlet="poRequired; context: cols.sm6"></ng-container>
              </div>
            </div>
          </div>

        </div>
        <div class="col-sm-3">
          <document-picker [documents]="Invoice.Documents" [canAdd]="!isDocumentChangeDisabled() && UserIsAdmin" [canRemove]="!isDocumentChangeDisabled() && UserIsAdmin" [isAtLeastOneRequired]="IsAtLeastOneDocumentRequired()"></document-picker>
        </div>
      </div>

      <invoice-payment-line-item-assoc [(data)]="AssocData" [invoiceNumber]="Invoice.InvoiceNumber" [invoiceId]="Invoice.Id" [isDisabled]="isDocumentChangeDisabled() || !UserIsAdmin"></invoice-payment-line-item-assoc>
    </ng-container>

    <ng-template #vendorInvoiceNumber let-col>
      <div [class]="col">
        <mat-form-field>
          <mat-label>Vendor Invoice Number</mat-label>
          <input matInput [(ngModel)]="Invoice.VendorAssignedInvoiceNumber" name="invoiceNum" [disabled]="!isNewStatus() || !UserIsAdmin"/>
        </mat-form-field>
      </div>
    </ng-template>
    <ng-template #vendorInvoiceDate let-col>
      <div [class]="col">
        <mat-form-field>
          <mat-label>Vendor Invoice Date</mat-label>
          <input matInput [(ngModel)]="Invoice.VendorInvoiceDate" #vendorInvoiceDateCtl name="vendorInvoiceDate"
                 [matDatepicker]="vendorInvoiceDateDatepicker" [disabled]="!isNewStatus() || !UserIsAdmin" />
          <mat-datepicker-toggle matSuffix [for]="vendorInvoiceDateDatepicker"></mat-datepicker-toggle>
          <mat-datepicker #vendorInvoiceDateDatepicker></mat-datepicker>
        </mat-form-field>
      </div>
    </ng-template>
    <ng-template #dueDate let-col>
      <div [class]="col">
        <mat-form-field>
          <mat-label>Due Date</mat-label>
          <input matInput [(ngModel)]="Invoice.DueDate" #dueDateCtl name="dueDate"
                 [matDatepicker]="dueDateDatepicker" [disabled]="!isNewStatus() || !UserIsAdmin" />
          <mat-datepicker-toggle matSuffix [for]="dueDateDatepicker"></mat-datepicker-toggle>
          <mat-datepicker #dueDateDatepicker></mat-datepicker>
        </mat-form-field>
      </div>
    </ng-template>
    <ng-template #poNumber let-col>
      <div [class]="col">
        <mat-form-field>
          <mat-label>Purchase Order</mat-label>
          <input matInput [(ngModel)]="Invoice.PurchaseOrderNumber" #po name="po" disabled />
        </mat-form-field>
      </div>
    </ng-template>
    <ng-template #prNumber let-col>
      <div [class]="col">
        <mat-form-field>
          <mat-label>Purchase Request</mat-label>
          <input matInput [(ngModel)]="Invoice.PurchaseRequisitionNumber" #pr name="pr" disabled />
        </mat-form-field>
      </div>
    </ng-template>
    <ng-template #invoiceStatus let-col>
      <div [class]="col">
        <mat-form-field>
          <mat-label>Status</mat-label>
          <mat-select required [(ngModel)]="Invoice.InvoiceStatus" #quoteStatus name="InvoiceStatus" [disabled]="!UserIsAdmin">
            <mat-option *ngFor="let status of Dropdowns.InvoiceStatuses" [value]="status.id" [disabled]="!status.isEnabled()">{{status.label}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-template>
    <ng-template #checkNumber let-col>
      <div [class]="col">
        <mat-form-field>
          <mat-label>Check Number</mat-label>
          <input matInput [(ngModel)]="Invoice.PaymentCheck" name="checkNum" [disabled]="!isComplete() || !UserIsAdmin" />
        </mat-form-field>
      </div>
    </ng-template>
    <ng-template #paidDate let-col>
      <div [class]="col">
        <mat-form-field>
          <mat-label>Paid</mat-label>
          <input matInput [(ngModel)]="Invoice.PaymentDate" #paidDateCtl name="paidDate"
                 [matDatepicker]="paidDateDatepicker" [disabled]="!isComplete() || !UserIsAdmin" />
          <mat-datepicker-toggle matSuffix [for]="paidDateDatepicker"></mat-datepicker-toggle>
          <mat-datepicker #paidDateDatepicker></mat-datepicker>
        </mat-form-field>
      </div>
    </ng-template>

    <ng-template #timeline let-col>
      <div [class]="col">
        <mat-form-field>
          <mat-label>Timeline (Calc)</mat-label>
          <input matInput [(ngModel)]="TimelineCalc" name="timelineCalc" disabled />
        </mat-form-field>
      </div>
    </ng-template>
    <ng-template #timelineAgreement let-col>
      <div [class]="col">
        <mat-form-field>
          <mat-label>Timeline (Agreement)</mat-label>
          <input matInput [(ngModel)]="Invoice.AgreementTimeline" name="timelineAgreement" disabled />
        </mat-form-field>
      </div>
    </ng-template>
    <ng-template #paymentPlan let-col>
      <div [class]="col">
        <mat-form-field>
          <mat-label>Payment Plan (Agreement)</mat-label>
          <input matInput [(ngModel)]="Invoice.AgreementPaymentPlan" name="paymentPlan" disabled />
        </mat-form-field>
      </div>
    </ng-template>
    <ng-template #poRequired let-col>
      <div [class]="col">
        <mat-form-field>
          <mat-label>Purchase Order Required</mat-label>
          <input matInput [ngModel]="Invoice.VendorPurchaseOrderRequired | YesNo" name="poRequired" disabled />
        </mat-form-field>
      </div>
    </ng-template>
    <ng-template #poEnd let-col>
      <div [class]="col">
        <mat-form-field>
          <mat-label>PO End</mat-label>
          <input matInput *ngIf="Invoice.QuotePurchaseOrderEndDates?.length == 1" name="poEnd" value="{{ PoEnd | date:'d-LLL-y' }}" disabled />
          <input matInput *ngIf="Invoice.QuotePurchaseOrderEndDates?.length != 1" name="poEnd" value="{{ PoEnd }}" disabled />
        </mat-form-field>
      </div>
    </ng-template>
    <ng-template #agreementEnd let-col>
      <div [class]="col">
        <mat-form-field>
          <mat-label>Active Agreement End</mat-label>
          <input matInput name="agreementEnd" value="{{ Invoice.AgreementEndDate | date:'d-LLL-y' }}" disabled />
        </mat-form-field>
      </div>
    </ng-template>
  </form>
</div>

<div mat-dialog-actions style="display: flex">
  <dl class="dl-horizontal modal-audit-info" *ngIf="Invoice">
    <dt *ngIf="Invoice.CreatedByName != undefined && Invoice.CreatedByPersonId != 0">Created:</dt>
    <dd *ngIf="Invoice.CreatedByName != undefined && Invoice.CreatedByPersonId != 0">{{ Invoice.CreatedDate | date:'d-LLL-y' }} by {{ Invoice.CreatedByName }}</dd>
    <dt *ngIf="Invoice.UpdatedByName != undefined && Invoice.UpdatedByPersonId != 0">Updated:</dt>
    <dd *ngIf="Invoice.UpdatedByName != undefined && Invoice.UpdatedByPersonId != 0">{{ Invoice.UpdatedDate | date:'d-LLL-y' }} by {{ Invoice.UpdatedByName }}</dd>
  </dl>

  <span style="flex: 1 1 auto;"></span>

  <button mat-raised-button color="warn" (click)="DeleteInvoice()" [disabled]="DisableDelete()">Delete</button>
  <button mat-raised-button color="accent" (click)="CancelModal()">Cancel</button>
  <button mat-raised-button color="primary" (click)="Save(invoiceEditForm)">Save</button>
  <button mat-raised-button color="primary" (click)="SaveAndClose(invoiceEditForm)">Save &amp; Close</button>
</div>
