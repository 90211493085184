import { MsalInterceptorConfiguration } from "@azure/msal-angular";
import { InteractionType } from "@azure/msal-browser";

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  // unprotected APIs
  let hosts = [
    "localhost:5002",
    "ftmspaymentsdev.azurewebsites.net",
    "ftmspaymentstest.azurewebsites.net",
    "ftmspaymentstrain.azurewebsites.net",
    "ftmspaymentsprod.azurewebsites.net",
    "fsps-dev.corteva.com",
    "fsps-test.corteva.com",
    "fsps-train.corteva.com",
    "fsps.corteva.com"
  ];

  hosts.map(host => `https://${host}/api/v1/app/startup-info`)
    .forEach(baseResourceUrl => {
      protectedResourceMap.set(baseResourceUrl, null);
    });

  // Protected APIs
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read', 'mail.send']);

  hosts.map(host => `https://${host}/api/*`)
    .forEach(baseResourceUrl => {
    protectedResourceMap.set(baseResourceUrl, [
      'ed08c800-c52a-4044-8169-2822900b281d/.default',
    ]);
  });

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}
