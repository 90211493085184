import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { DocumentPickerService } from "./document-picker.service";
import { DocumentMetadataDto } from "./DocumentMetadataDto";

interface FileItem {
  File: File;
  Filename: string;
  Status: string;
  IsUploading: boolean;
  IsError: boolean;
  CanDownload: boolean;
  Metadata: DocumentMetadataDto;
  DownloadLink?: string;
}

@Component({
  selector: 'document-picker',
  templateUrl: 'document-picker.component.html',
  styleUrls: ["document-picker.component.css"]
})
export class DocumentPickerComponent {
  public Files: Array<FileItem> = [];
  private _documents: Array<DocumentMetadataDto>;
  public inputValue;

  @Input() public canAdd: boolean = true;
  @Input() public canRemove: boolean = true;
  @Input() public isAtLeastOneRequired: boolean = false;
  @Input() public set documents(docs: Array<DocumentMetadataDto>) {

    this._documents = docs;

    if (docs == null)
      this.Files = [];
    else {
      var filesToRemove = this.Files.filter(f => docs.indexOf(f.Metadata) < 0);
      var filesToAdd = docs.filter(d => !this.doesFilesListContainMetadata(d));

      filesToRemove.forEach(f => {
        this.Files.splice(this.Files.indexOf(f), 1);
      });

      filesToAdd.forEach(f => {
        this.Files.push({
          CanDownload: true,
          File: null,
          Filename: f.Filename,
          IsError: false,
          IsUploading: false,
          Metadata: f,
          Status: "Uploaded",
          DownloadLink: this.GenerateDownloadLink(f)
        });
      });
    }
  }

  @Output() public documentsChange = new EventEmitter<Array<DocumentMetadataDto>>();

  private doesFilesListContainMetadata = (metadata: DocumentMetadataDto) : boolean => {
    return this.Files.findIndex(f => f.Metadata == metadata) >= 0;
  }

  constructor(private documentPickerService: DocumentPickerService, private router: Router) {

  }

  public DisableDeleteButton = (): boolean => {
    if (this.canRemove == false)
      return true;
    if (this.isAtLeastOneRequired && this.Files.length <= 1)
      return true;

    return false;
  }

  public droppedFiles = (fileList: FileList) => {
    if (this.canAdd == false)
      return;

    this.addFiles(fileList);
  }

  public browsedFiles = (evt: Event) => {
    const fileList: FileList = (evt.target as HTMLInputElement).files;
    this.addFiles(fileList);
  }

  public removeFile = (file: FileItem) => {
    const index = this.Files.indexOf(file);
    if (index >= 0) {
      this.Files.splice(index, 1);

      var metadataIndex = this._documents.indexOf(file.Metadata);
      if (metadataIndex >= 0) {
        this._documents.splice(metadataIndex, 1);
        this.documentsChange.emit(this._documents);
      }
    }
  }

  public backgroundColor = (file: FileItem) => {
    if (file.IsError)
      return 'bg-danger';
    else if (file.IsUploading)
      return 'bg-info';
    else
      return '';
  }

  private addFiles = (fileList: FileList) => {
    if (fileList != null) {
      for (let i = 0; i < fileList.length; i++) {
        const file: File = fileList[i];

        // Don't include files with the same filename
        if (this.Files.some(f => f.Filename === file.name))
          continue;

        const fileToAdd: FileItem = {
          File: file,
          Filename: file.name,
          Status: "Uploading",
          IsUploading: true,
          IsError: false,
          CanDownload: false,
          Metadata: null
        }

        this.Files.push(fileToAdd);

        this.documentPickerService.UploadFile(file).subscribe(fileResult => {
          fileToAdd.IsUploading = false;
          fileToAdd.Status = "Uploaded";
          fileToAdd.Metadata = fileResult;
          fileToAdd.CanDownload = true;
          fileToAdd.DownloadLink = this.GenerateDownloadLink(fileResult);

          this._documents.push(fileResult);
          this.documentsChange.emit(this._documents);
        }, err => {
          fileToAdd.IsUploading = false;
          fileToAdd.Status = "Error uploading";
          fileToAdd.IsError = true;
          console.log(err);
        });
      }
    }

    // clear input value to always trigger change event
    // removing and attempting to re-add same file via file browser bypasses change event
    this.inputValue = undefined;
  }

  private GenerateDownloadLink = (file: DocumentMetadataDto): string => {
    const urlTree = this.router.createUrlTree([`api/v1/documents/${file.Id}/download`], {});

    return window.location.origin + urlTree.toString();
  }

  public GetOverallStatus = (): string => {
    var uploadCount = this.Files.filter(a => a.IsUploading).length;
    var errorCount = this.Files.filter(a => a.IsError).length;
    var totalCount = this.Files.length;

    let msg: string = "";
    if (uploadCount > 0)
      msg += `Uploading ${uploadCount} files.`

    if (errorCount > 0)
      msg += `${errorCount} errors`;

    if (uploadCount == 0 && errorCount == 0)
      msg = `${totalCount} files`;

    return msg;
  }
}
