import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { ApiService } from "../../shared/ApiService";
import { PagedResults } from "../../shared/interfaces/PagedResults";
import { AgreementSearchDropdowns } from "./types/AgreementSearchDropdowns";
import { AgreementSearchParameters } from "./types/AgreementSearchParameters";
import { AgreementSearchRow } from "./types/AgreementSearchRow";

@Injectable()
export class AgreementSearchService {
  constructor(private apiService: ApiService) {

  }

  public SearchAgreements = (searchParameters: AgreementSearchParameters): Observable<PagedResults<AgreementSearchRow>> => {
    return this.apiService.POST<AgreementSearchParameters, PagedResults<AgreementSearchRow>>(searchParameters, "api/v1/agreements-search");
  }

  public GetDropdowns = (): Observable<AgreementSearchDropdowns> => {
    return this.apiService.GET<AgreementSearchDropdowns>("api/v1/agreement-search-meta");
  }
}
