import { Directive, Inject, HostListener, ElementRef, OnInit, HostBinding, OnDestroy } from "@angular/core";
import { Observable, Subscription, Subject, merge } from "rxjs";
import { debounceTime } from "rxjs/operators";


/**
 * When added to a scroll container, adds a subtle inset shadow on the top or bottom to indicate that there
 * is more content to scroll to up or down.
 * 
 * Checks when the user scrolls, as well as when content changes.
 * */
@Directive({
  selector: '[scrollInsetShadow]'
})
export class ScrollInsetShadowDirective implements OnInit, OnDestroy {
  private Sensitivity: number = 5;
  private subscription: Subscription;
  private scrollObservable: Subject<void> = new Subject<void>();

  private scrollSettings = {
    isAtTop: true,
    isAtBottom: true
  }

  @HostListener("scroll") onScroll(e: Event): void {
    this.scrollObservable.next();
  }

  ngOnInit() {
    this.calculateScroll();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private calculateScroll = () => {

    let element: HTMLElement = this.el.nativeElement;

    var currentScrollDown = element.scrollTop;
    var totalHeight = element.scrollHeight;
    var visibleHeight = element.clientHeight;

    let isAtTop = currentScrollDown < this.Sensitivity;
    let isAtBottom = totalHeight - visibleHeight - currentScrollDown < this.Sensitivity;

    this.scrollSettings.isAtTop = isAtTop;
    this.scrollSettings.isAtBottom = isAtBottom;
  }

  @HostBinding("class.scroll-offset-shadow-container") get isEnabled() { return true; }
  @HostBinding("class.scroll-offset-shadow-top-shadow") get canScrollUp() { return !this.scrollSettings.isAtTop; }
  @HostBinding("class.scroll-offset-shadow-bottom-shadow") get canScrollDown() { return !this.scrollSettings.isAtBottom; }

  constructor(@Inject(ElementRef) private el: ElementRef<HTMLElement>) {

    const observerable$ = new Observable<number>(observer => {
      // Callback function to execute when mutations are observed
      // this can and will be called very often
      const callback = () => {
        observer.next(this.el.nativeElement.offsetHeight);
      };

      // Create an observer instance linked to the callback function
      const elementObserver = new MutationObserver(callback);

      // Options for the observer (which mutations to observe)
      const config = { attributes: true, childList: true, subtree: true };

      // Start observing the target node for configured mutations
      elementObserver.observe(this.el.nativeElement, config);
    });

    this.subscription = merge(observerable$, this.scrollObservable)
      .pipe(
        debounceTime(50),//wait until 50 milliseconds have lapsed since the observable was last sent
      )
      .subscribe((newValues => {
        this.calculateScroll();
      }));
  }
}
