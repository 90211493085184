import { Inject, Injectable } from "@angular/core";
import * as _ from "lodash";
import { DialogService } from "./dialog.service";


@Injectable()
export class ChangeDetectionService {
    private OriginalValue: any = null;
    private setNoButtonAsDefault: boolean = true;

    constructor(private dialogService: DialogService) { }

    public SetOriginalValue<T>(obj: T) {
        this.OriginalValue = _.cloneDeep(obj);
    }

    public HasChanges<T>(obj: T) {
        return !_.isEqual(this.OriginalValue, obj)
    }

    public HasChangeDialog = (): Promise<boolean> => {
        return this.dialogService.confirmUnsavedChanges(this.setNoButtonAsDefault).toPromise();
    }
}
