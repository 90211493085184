import { PossibleInvoicePaymentLineItems } from "../types/PossibleInvoicePaymentLineItems"
import { Trial as InvoiceAssocTrial } from "../payment-line-item-assoc/invoice-payment-line-item-assoc.component";
import { PaymentLineItemInvoiceAssocUpdates } from "../types/PaymentLineItemInvoiceAssocUpdates";

export let MapApiToAssoc = (possiblePaymentLineItems: PossibleInvoicePaymentLineItems[], invoiceId: number): Array<InvoiceAssocTrial> => {

  let assocData: Array<InvoiceAssocTrial> = [];

  possiblePaymentLineItems.forEach(paymentLineItem => {

    var itemToAdd: InvoiceAssocTrial = {
      BudgetYears: [],
      Currency: paymentLineItem.PaymentLineItem.CurrencyName,
      IsCommercial: paymentLineItem.PaymentLineItem.IsCommercial,
      Status: paymentLineItem.PaymentLineItem.Status,
      Title: paymentLineItem.PaymentLineItem.ShortTitle,
      TrialNumber: paymentLineItem.PaymentLineItem.Number,
      RestrictionStatus: paymentLineItem.PaymentLineItem.RestrictionStatus,
      SourceSystemLink: paymentLineItem.PaymentLineItem.SourceSystemLink,
      ItemType: paymentLineItem.PaymentLineItem.ItemType
    };

    paymentLineItem.VendorPaymentLineItemAssocDtos.forEach(vendorAssoc => {
      var budgetYear = itemToAdd.BudgetYears.find(a => a.Year == vendorAssoc.BudgetYear);
      if (budgetYear === undefined) {
        budgetYear = {
          Objectives: [],
          Year: vendorAssoc.BudgetYear
        };
        itemToAdd.BudgetYears.push(budgetYear);
      }

      var objective = {
        Invoices: [],
        ObjectiveName: vendorAssoc.ObjectiveName,
        QuotedAmount: vendorAssoc.QuotedAmount,
        PaymentLineItemVendorAssocId: vendorAssoc.Id,
        Currency: paymentLineItem.PaymentLineItem.CurrencyName
      };

      budgetYear.Objectives.push(objective);
    });

    // now add in any invoice assoc records
    paymentLineItem.PaymentLineItemInvoiceAssocDtos.forEach(invoiceAssoc => {
      itemToAdd.BudgetYears.forEach(budgetYear => {
        budgetYear.Objectives.forEach(objective => {
          if (invoiceAssoc.PaymentLineItemVendorAssocId == objective.PaymentLineItemVendorAssocId) {
            var invoiceLookup = paymentLineItem.LookupInvoice.find(a => a.InvoiceId == invoiceAssoc.InvoiceId);
            objective.Invoices.push({
              Amount: invoiceAssoc.InvoiceAmount,
              Editable: invoiceAssoc.InvoiceId == invoiceId,
              InvoiceId: invoiceAssoc.InvoiceId,
              Name: invoiceLookup != null ? invoiceLookup.InvoiceNumber : "-unknown-",
              PaymentLineItemInvoiceAssocId: invoiceAssoc.Id,
              IsCurrentInvoice: invoiceAssoc.InvoiceId == invoiceId
            });
          }
        })
      })
    })

    assocData.push(itemToAdd);
  });

  return assocData.sort((a, b) => (a.ItemType + a.TrialNumber) < (b.ItemType + b.TrialNumber) ? -1 : (a.ItemType + a.TrialNumber) > (b.ItemType + b.TrialNumber) ? 1 : 0);
}

export let MapAssocToUpdates = (assocData: Array<InvoiceAssocTrial>, invoiceId: number): Array<PaymentLineItemInvoiceAssocUpdates> => {
  let updates: Array<PaymentLineItemInvoiceAssocUpdates> = [];

  assocData.forEach(assoc => {
    assoc.BudgetYears.forEach(budgetYear => {
      budgetYear.Objectives.forEach(objective => {
        objective.Invoices.filter(invoice => invoice.InvoiceId === invoiceId).forEach(invoice => {
          updates.push({
            InvoiceAmount: invoice.Amount,
            PaymentLineItemVendorAssocId: objective.PaymentLineItemVendorAssocId,
            PaymentLineItemInvoiceAssocId: invoice.PaymentLineItemInvoiceAssocId
          })
        })
      })
    })
  })

  return updates;
}
