import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { LoadingService } from "../../shared/services/loading-overlay/loading-overlay.component";
import { InvoiceDropdowns, PurchaseOrderForVendor, PurchaseRequisitionForVendor } from "./InvoiceDropdowns";

@Component({
  selector: 'invoice-filter',
  templateUrl: 'invoice-filters.component.html'
})
export class InvoiceFilterComponent implements OnInit {
  private allPurchaseOrderNumbers: Array<PurchaseOrderForVendor> = [];
  private allPurchaseRequisitionNumbers: Array<PurchaseRequisitionForVendor> = [];
  public Year: number;
  public PurchaseOrderNumber: string;
  public PurchaseRequisitionNumber: string;

  private _vendorId: number;
  @Input() set VendorId(value: number) {

    this._vendorId = value;
    this.Refresh();
  }
  get VendorId(): number {
    return this._vendorId;
  }

  @Input() public ValuesRequired: boolean = false;
  @Output() public PurchaseRequisitionSelected: EventEmitter<string> = new EventEmitter<string>();
  @Output() public PurchaseOrderSelected: EventEmitter<string> = new EventEmitter<string>();
  @Output() public YearSelected: EventEmitter<number> = new EventEmitter<number>();

  constructor(public dialog: MatDialog,
    private loadingService: LoadingService) {
  }


  public Dropdowns = {
    Years: <Array<number>>[],
    PurchaseOrders: <Array<PurchaseOrderForVendor>>[],
    PurchaseRequisitions: <Array<PurchaseRequisitionForVendor>>[],
  }

  ngOnInit(): void {
    this.Refresh();
  }

  public Refresh = () => {
    if (this.VendorId == null)
      return;

    const loadRef = this.loadingService.StartWaiting("Fetching data");

    loadRef.Stop();
  }

  public SetDropdowns = (dropdowns: InvoiceDropdowns) => {
    this.Dropdowns.PurchaseOrders = dropdowns.PurchaseOrdersForVendor;
    this.Dropdowns.PurchaseRequisitions = dropdowns.PurchaseRequisitionsForVendor;
    this.Dropdowns.Years = dropdowns.Years;
    this.allPurchaseOrderNumbers = dropdowns.PurchaseOrdersForVendor;
    this.allPurchaseRequisitionNumbers = dropdowns.PurchaseRequisitionsForVendor;
  }

  public onYearSelected = (year: number) => {
    this.PurchaseOrderNumber = null;
    this.PurchaseRequisitionNumber = null;
    this.YearSelected.emit(year);
  }

  public onPurchaseOrderNumberSelected = (poNumber: string) => {
    this.PurchaseOrderSelected.emit(poNumber);
  }

  public onPurchaseRequisitionSelected = (prNumber: string) => {
    this.PurchaseRequisitionSelected.emit(prNumber);
  }
}
