import { Component, OnInit } from "@angular/core";
import { AppMonitoringService } from "../../AppMonitoringService";
import { AppInfoService } from "../../singletons/app-info.service";

@Component({
  selector: 'corteva-footer',
  templateUrl: 'footer.component.html',
  styleUrls: [
    "footer.component.less"
  ]
})
export class AppFooterComponent implements OnInit {
  public Version: string;
  public Copyright: string;
  public ShowTechInfo: boolean = false;
  public AppInsightsUrl: string = "";

  constructor(private service: AppInfoService, private appMonitoringService: AppMonitoringService) {
  }

  ngOnInit(): void {
    this.setVersionInfo();
    this.Copyright = 'TM ,®, SM Trademarks of DuPont, Dow AgroSciences or Pioneer, and their affiliated companies or their respective owners. © ' + new Date().getFullYear() + ' Corteva';
    this.appMonitoringService.GetEnvironmentSpecificAppInsightsConfig().subscribe(c => {
      this.AppInsightsUrl = c.Url;
    });
  }

  setVersionInfo = () => {
    this.service.getAppStartupInfo().subscribe(info => {
      this.Version = info.BuildVersion;
    });
  }

  toggleTechInfo = () => {
    this.ShowTechInfo = !this.ShowTechInfo;
  }
}
