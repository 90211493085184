import { SelectionModel } from "@angular/cdk/collections";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { GetInvoiceStatusDescription } from "../../../modals/invoice-modal/types/Invoice";
import { InvoiceSearchRow } from "../types/InvoiceSearchRow";

@Component({
  selector: 'invoice-search-results',
  templateUrl: 'invoice-search-results-grid.component.html'
})
export class InvoiceSearchResultsGridComponent {
  public selection = new SelectionModel<InvoiceSearchRow>(false);

  @Input() rows: InvoiceSearchRow;

  @Output() public onEdit = new EventEmitter<InvoiceSearchRow>();

  @Output() public sortChanged = new EventEmitter<MatSort>();

  public Columns: Array<"count" | keyof InvoiceSearchRow> = [
    "InvoiceNumber",
    "InvoiceYear",
    "VendorInvoiceDate",
    "PurchaseOrder",
    "PurchaseRequest",
    "count", // the only non-sortable column
    "TotalInvoiced",
    "InvoiceStatus",
    "CheckNumber",
    "PaidDate",
    "VendorName",
    "VendorCode",
    "VendorCountry",
  ];

  public doubleClick = (row: InvoiceSearchRow) => {
    if (!this.selection.isSelected(row))
      this.selection.toggle(row);

    this.onEdit.next(row);
  }

  onSortChange = (evt: MatSort) => {
    this.sortChanged.next(evt);
  }

  public GetInvoiceStatusDescription = GetInvoiceStatusDescription;
}
