import { Directive, Output, EventEmitter, HostBinding, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[dragAndDrop]'
})
export class FileDragAndDropDirective {

  @Output() onFileDropped = new EventEmitter<any>();
  @HostBinding('class.drag-and-drop-dragover') public get IsDragOver() {
    return this._isDraggedOver;
  };

  private _isDraggedOver: boolean;

  // Prevents excess adding-and-removing of classes if you are dragging
  // over child elements
  private counter = 0;

  //Dragover listener, when something is dragged over our host element
  @HostListener('dragenter', ['$event']) onDragEnter(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this._isDraggedOver = true;
    this.counter++;
    this.setBodyClass(true);
  };

  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  };

  //Dragleave listener, when something is dragged away from our host element
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    if (--this.counter === 0) {
      this._isDraggedOver = false;
      this.setBodyClass(false);
    }
  }

  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this._isDraggedOver = false;
    this.counter = 0;
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.onFileDropped.emit(files)
    }

    this.setBodyClass(false);
  }

  private setBodyClass = (allowDrop: boolean) => {

    const className = "file-drag-drop_allow-drop";

    var body = document.getElementsByTagName("body")[0];
    var bodyAllowDrop = body.classList.contains(className);

    if (bodyAllowDrop == true && allowDrop == false) {
      body.classList.remove(className);
    }
    else if (bodyAllowDrop == false && allowDrop == true) {
      body.classList.add(className);
    }
  }

  constructor() { }
}

// This prevents the default behavior of opening a drag-and-dropped file in a new tab (i.e. plain text files)
// or staging the file in the browser for download (i.e. excel, pdf). Applied site-wide in app.component.html
@Directive({
  selector: '[dragAndDropGuard]'
})
export class FileDragAndDropGuardDirective {

  //Dragover listener, when something is dragged over our host element
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    evt.dataTransfer.effectAllowed = "none";
    evt.dataTransfer.dropEffect = "none";
  };

  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    evt.dataTransfer.effectAllowed = "none";
    evt.dataTransfer.dropEffect = "none";
  }

  constructor() { }
}
