<div class="row">
  <div class="col-sm-4">
    <mat-form-field>
      <mat-label>Invoice Year</mat-label>
      <mat-select [required]="ValuesRequired" [(ngModel)]="Year" #year name="year" (selectionChange)="onYearSelected($event.value)" [disabled]="VendorId == null">
        <mat-option *ngIf="!ValuesRequired" [value]="null"></mat-option>
        <mat-option *ngFor="let year of Dropdowns.Years" [value]="year">{{year}}</mat-option>
        <mat-error *ngIf="year.errors?.required">
          Year is required
        </mat-error>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-sm-4">
    <mat-form-field>
      <mat-label>PO</mat-label>
      <mat-select [required]="ValuesRequired" [disabled]="PurchaseRequisitionNumber != null || Year == null || VendorId == null" [(ngModel)]="PurchaseOrderNumber" #purchaseOrderNumber name="purchase-order-number" (selectionChange)="onPurchaseOrderNumberSelected($event.value)">
        <mat-option *ngIf="!ValuesRequired" [value]="null"></mat-option>
        <mat-option *ngFor="let a of Dropdowns.PurchaseOrders" [value]="a.Number">{{a.Number}}</mat-option>
        <mat-error *ngIf="purchaseOrderNumber.errors?.required">
          Purchase Order is required
        </mat-error>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-sm-4">
    <mat-form-field>
      <mat-label>PR (NOPO)</mat-label>
      <mat-select [required]="ValuesRequired" [disabled]="PurchaseOrderNumber != null || VendorId == null || Year == null" [(ngModel)]="PurchaseRequisitionNumber" #purchaseReqNumber name="purchase-req-number" (selectionChange)="onPurchaseRequisitionSelected($event.value)">
        <mat-option *ngIf="!ValuesRequired" [value]="null"></mat-option>
        <mat-option *ngFor="let a of Dropdowns.PurchaseRequisitions" [value]="a.Number">{{a.Number}}</mat-option>
        <mat-error *ngIf="purchaseReqNumber.errors?.required">
          Purchase Requisition is required
        </mat-error>
      </mat-select>
    </mat-form-field>
  </div>
</div>
