import { AfterViewInit, Component, ViewChild } from "@angular/core";
import { map, of } from "rxjs";
import { SearchType } from "../../shared/services/search-storage.service";
import { GenericSearchPageComponent } from "../generic-search-page/generic-search-page.component";
import { AgreementSearchService } from "./agreement-search.service";
import { AgreementSearchParameters } from "./types/AgreementSearchParameters";
import { AgreementSearchRow } from "./types/AgreementSearchRow";
import { filterDefinitions } from "./agreement-search-filters";
import { IFilterCategoryDef } from "../../shared/interfaces/IFilterDef";
import { LoadingService } from "../../shared/services/loading-overlay/loading-overlay.component";
import { VendorAgreementModal, ShowVendorAgreementModalData } from "../../modals/vendor-agreement-modal/vendor-agreement-modal.component";

@Component({
  selector: 'agreement-search',
  templateUrl: 'agreement-search.component.html'
})
export class AgreementSearchComponent implements AfterViewInit {
  @ViewChild(GenericSearchPageComponent, { static: true }) public SearchPageComponent: GenericSearchPageComponent<AgreementSearchRow, AgreementSearchParameters>;

  public SearchHistoryType: SearchType = SearchType.Agreements;

  public filterCategories: IFilterCategoryDef<AgreementSearchParameters>[] = filterDefinitions.filterCategories;

  public agreementData: AgreementSearchRow[] = [];

  constructor(private searchService: AgreementSearchService, private loadingService: LoadingService, private agreementModal: VendorAgreementModal) {

  }

  ngAfterViewInit() {
    this.SearchPageComponent.Options.SearchForRows = (searchParameters) => {
      return this.searchService.SearchAgreements(searchParameters);
    }

    this.SearchPageComponent.Options.EditRow = (row) => {
      var modalParams: ShowVendorAgreementModalData =
      {
        Id: row.AgreementId,
        VendorId: row.VendorId,
        CanActivate: row.CanActivate
      };
      return this.agreementModal.ShowModal(modalParams).pipe(
        map(a => a !== undefined)
      );
    };

    this.SearchPageComponent.Options.SearchHistoryType = SearchType.Agreements;

    const loadRef = this.loadingService.StartWaiting("Loading Options...");
    this.searchService.GetDropdowns().subscribe(response => {
      filterDefinitions.mapFiltersFromApi(response);
      this.SearchPageComponent.InitializeFilters();

      loadRef.Stop();
    });
  }
}
