import { Component, Inject, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AppRoles, GetRoleName } from "../../shared/enums/AppRoles";

@Component({
  selector: 'no-role-access',
  templateUrl: 'no-role-access.component.html'
})
export class NoRoleAccessComponent implements OnInit {
  missingRoleNames: string[] = [];

  constructor(@Inject(ActivatedRoute) private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        let missingRoles: string[] | string = params.roles;

        if (!Array.isArray(missingRoles)) {
          missingRoles = [missingRoles];
        }

        this.missingRoleNames = missingRoles.map(Number).map(GetRoleName);
      });
  }
}
