import { Component, OnInit } from '@angular/core';
import { AppMonitoringService } from './AppMonitoringService';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'app';

  constructor(private appMonitoringService: AppMonitoringService) {

  }

  ngOnInit(): void {

    // Prevent allowing users from dragging files into the window unless
    // they are dragging it onto a control that specifically allows it
    ["dragover", "drop"].forEach(eventName => {
      window.addEventListener(eventName, (e: DragEvent) => {

        var allowDrop = document.getElementsByTagName("body")[0].classList.contains("file-drag-drop_allow-drop");

        if (allowDrop == false) {
          e && e.preventDefault();
        }
      });
    });
  }
}
