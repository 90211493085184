export interface AppSettings {
  apiUrl: string
}

export class SettingsService {
  public GetSettings = (): AppSettings => {
    return {
      apiUrl: document.getElementsByTagName("base")[0].href
    }
  }
}
