import { Injectable } from "@angular/core";
import { AbstractControl, FormGroup, NgForm } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";

export const DefaultInvalidFormMessage: string = "There are validation errors. Please fix the highlighted fields and try again.";

@Injectable()
export class FormControlHelper {
  constructor(private snackbar: MatSnackBar) {
  }

  public GetControls = (formGroup: FormGroup): Array<AbstractControl> => {
    if (!formGroup || !formGroup.controls)
      return [];

    var controls: Array<AbstractControl> = [];
    for (const ctrlKey in formGroup.controls) {
      var control = formGroup.controls[ctrlKey];
      if (control instanceof FormGroup) {
        controls = controls.concat(this.GetControls(control));
      }
      else {
        controls.push(control);
      }
    }

    return controls;
  }

  /**
   * Checks if all controls that are part of the specified form are valid, or if any have
   * any validation errors (such as required). If any do have errors, then a snackbar message
   * is displayed with the specified invalidMessage.
   * @param form
   * @param invalidMessage
   */
  public CheckFormIsValid = (form: NgForm, invalidMessage: string = DefaultInvalidFormMessage): boolean => {
    let hasErrors = false;

    var controls: Array<AbstractControl> = this.GetControls(form.form);
    for (const ctrl of controls) {
      if (ctrl.errors) {
        ctrl.markAllAsTouched();
        hasErrors = true;
      }
    }

    if (hasErrors) {
      this.snackbar.open(invalidMessage, null, {
        duration: 5000,
      });
    }

    return !hasErrors;
  }
}
