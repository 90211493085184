import { Inject, Injectable } from "@angular/core";
import { IFilterDef, ISelectedFilterDef } from "../interfaces/IFilterDef";
import { SearchStorageService, SearchType } from "../services/search-storage.service";

@Injectable()
export class SearchHelper {
  constructor(
    @Inject(SearchStorageService) private searchStorageService: SearchStorageService) { }

  public getLatestFilters = async (searchType: SearchType, filterNames: Array<string>) => {
    const filtersToAdd: Array<SearchItem> = [];
    const response = await this.searchStorageService.getLastSearch(searchType);
    const existing = JSON.parse(response.SearchJson);

    if (!existing)
      return;

    for (const prop in existing) {
      const value = existing[prop];
      const isBoolean: boolean = value === false || value === true;

      if (value === "" || (value == null && !isBoolean) || (Array.isArray(value) && value.length === 0))
        continue;

      const filter = filterNames.find(e => e.replace(" ", "") === prop);
      if (filter) {
        const f: SearchItem = {
          name: prop,
          value: value
        }
        filtersToAdd.push(f);
      }
    }

    return filtersToAdd;
  }

  public getLatestFiltersV2 = async <TSearchParameters>(searchType: SearchType, filters: IFilterDef<TSearchParameters>[]): Promise<ISelectedFilterDef<TSearchParameters>[]> => {
    const filtersToAdd: Array<ISelectedFilterDef<TSearchParameters>> = [];
    const response = await this.searchStorageService.getLastSearch(searchType);
    if (!response)
      return;
    const existing = JSON.parse(response.SearchJson);
    if (!existing)
      return;

    for (const prop in existing) {
      const value = existing[prop];
      const isBoolean: boolean = value === false || value === true;

      // don't restore any filter that was left blank by the user. This could be a bool, array, or string value
      if (value === "" || (value == null && !isBoolean) || (Array.isArray(value) && value.length === 0))
        continue;

      const filter = filters.find(e => e.name === prop);
      if (filter && filter.disabled !== false) {
        const f: ISelectedFilterDef<TSearchParameters> = {
          filter: filter,
          searchValue: value
        };
        filtersToAdd.push(f);
      }
    }

    return filtersToAdd;
  }

  public setLatestFilters = (searchType: SearchType, parametersToCache: any) => {
    this.searchStorageService.storeSearch(searchType, JSON.stringify(parametersToCache));
  }

  public setLatestFiltersV2 = <TSearchParameters>(searchType: SearchType, selectedFilters: ISelectedFilterDef<TSearchParameters>[]) => {

    var parametersToCache: any = {};

    selectedFilters.forEach(selectedFilter => {
      parametersToCache[selectedFilter.filter.name] = selectedFilter.searchValue;
    });
   this.searchStorageService.storeSearch(searchType, JSON.stringify(parametersToCache));
  }
}

export interface SearchItem {
  name: string;
  value?: any;
}
