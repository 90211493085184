import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { CurrentUserService } from "../../shared/CurrentUser.service";
import { INavCategory } from "../../shared/interfaces/INavCategory";
import { INavItem } from "../../shared/interfaces/INavItem";
import { AppInfoService } from "../../singletons/app-info.service";

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html'
})

export class AppHeaderComponent implements OnInit {

  public UserDisplayName: string = "";
  public mainMenu: INavCategory[] = [];
  public environmentName: string = "";

  @ViewChild("profilePicture") public profilePicture: ElementRef<HTMLImageElement>;

  constructor(public service: AppInfoService, private userService: CurrentUserService, private router: Router) {
  }

  ngOnInit(): void {
    let mainItems: INavItem[] = [];
    mainItems.push({ name: "Home Screen", url: "/home" });
    this.mainMenu.push({ name: 'Home', items: mainItems });
    this.mainMenu.push({ name: 'Vendors', url: "/vendors" });
    this.mainMenu.push({ name: 'Quotes', url: '/quotes' });
    this.mainMenu.push({ name: 'Invoices', url: '/invoices' });
    this.mainMenu.push({ name: 'Agreements', url: '/agreements' });

    this.service.getAppStartupInfo().subscribe(info => {
      if (info)
        this.environmentName = info.EnvironmentName;
    });

    this.userService.IsLoggedIn().subscribe(isLoggedIn => {
      if (isLoggedIn === true) {
        this.UserDisplayName = this.userService.GetRawUser().name;

        try {
          this.userService.GetProfilePictureIcon().subscribe(pic => {
            this.profilePicture.nativeElement.setAttribute("src", pic);
          });
        } catch { };
      }
    });
  }

  public viewDiagProfile = () => {
    this.router.navigate(["/diagnostic-profile"])
  }
}
