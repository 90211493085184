import { QuoteEditAssocGridBudgetYear } from "./types/QuoteEditAssocGridItem";

export class QuoteEditModalHelper {

  public IsQuotedAmountFilledIn = (quotedAmount: string | number): boolean => {
    return quotedAmount !== null && quotedAmount !== undefined && <any>(quotedAmount) !== "";
  }

  public IsQuotedAmountForThisQuote = (quoteId: number, by: QuoteEditAssocGridBudgetYear) => {
    // If the quoted amount is blank, then we don't want to include the value at all
    if (!this.IsQuotedAmountFilledIn(by.QuotedAmount))
      return false;

    // If the quoted amount is filled in, then we only want to include it on this quote
    // if the quote ID matches the quote ID of the assoc record, or if the quote ID is
    // not filled in (which will happen if it was just added to this quote)
    if (by.QuoteId == quoteId || by.QuoteId === undefined)
      return true;
    else
      return false;
  }
}
