import { AfterViewInit, Component, ViewChild } from "@angular/core";
import { map } from "rxjs";
import { InvoiceEditModal } from "../../modals/invoice-modal/edit/invoice-edit-modal.component";
import { IFilterCategoryDef } from "../../shared/interfaces/IFilterDef";
import { LoadingService } from "../../shared/services/loading-overlay/loading-overlay.component";
import { SearchType } from "../../shared/services/search-storage.service";
import { GenericSearchPageComponent } from "../generic-search-page/generic-search-page.component";
import { filterDefinitions } from "./invoice-search-filters";
import { InvoiceSearchService } from "./invoice-search.service";
import { InvoiceSearchParameters } from "./types/InvoiceSearchParameters";
import { InvoiceSearchRow } from "./types/InvoiceSearchRow";

@Component({
  selector: 'invoice-search',
  templateUrl: 'invoice-search.component.html'
})
export class InvoiceSearchComponent implements AfterViewInit {

  @ViewChild(GenericSearchPageComponent, { static: true }) public SearchPageComponent: GenericSearchPageComponent<InvoiceSearchRow, InvoiceSearchParameters>;

  public SearchHistoryType: SearchType = SearchType.Invoices;

  public filterCategories: IFilterCategoryDef<InvoiceSearchParameters>[] = filterDefinitions.filterCategories;

  public rows: InvoiceSearchRow[] = [];

  constructor(private invoiceSearchService: InvoiceSearchService, private loadingService: LoadingService, private invoiceEditModal: InvoiceEditModal) {

  }

  ngAfterViewInit() {
    this.SearchPageComponent.Options.SearchForRows = (searchParameters) => {
      return this.invoiceSearchService.Search(searchParameters);
    }

    this.SearchPageComponent.Options.EditRow = (row) => {
      return this.invoiceEditModal.ShowModal({
        InvoiceId: row.InvoiceId
      }).pipe(
        map(result => result !== undefined)
      );
    }

    this.SearchPageComponent.Options.SearchHistoryType = SearchType.Invoices;

    const loadRef = this.loadingService.StartWaiting("Loading Options...");
    this.invoiceSearchService.GetInvoiceSearchFilters().subscribe(response => {
      filterDefinitions.mapFiltersFromApi(response);
      this.SearchPageComponent.InitializeFilters();

      loadRef.Stop();
    });
  }
}
