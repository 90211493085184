import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CurrentUserService } from '../../shared/CurrentUser.service';
import { map } from 'rxjs';
import { GetRoleName } from '../../shared/enums/AppRoles';
import { ApiService } from '../../shared/ApiService';
import { Country } from '../../shared/interfaces/Country';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

type ProfileType = {
  givenName?: string,
  surname?: string,
  userPrincipalName?: string,
  id?: string
};

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html'
})
export class ProfileComponent implements OnInit {
  profile!: ProfileType;
  tokenRoleClaims: Array<string>;
  roles: Array<string>;
  tokenIssuedAt: Date;
  tokenValidEnd: Date;
  tokenValidStart: Date;
  protectedApiResponse: string = "Loading...";
  adminCountries: Country[];

  constructor(
    private http: HttpClient,
    private userService: CurrentUserService,
    private apiService: ApiService
  ) { }

  ngOnInit() {
    this.getProfile();
    var user = this.userService.GetRawUser();
    let claims: any = user.idTokenClaims;
    this.tokenRoleClaims = claims.roles;

    this.userService.GetAppRoles().pipe(
      map(roles => roles.map(GetRoleName)
    )).subscribe(roles => {
      this.roles = roles;
    });

    this.tokenIssuedAt = claims.iat == null ? null : new Date(claims.iat * 1000);
    this.tokenValidEnd = claims.exp == null ? null : new Date(claims.exp * 1000);
    this.tokenValidStart = claims.nbf == null ? null : new Date(claims.nbf * 1000);

    this.testApi();

    this.populateCountryAdminList();
  }

  testApi = () => {

    this.apiService.GET<any>("api/v1/app/protected-api-heartbeat").subscribe(result => {
      this.protectedApiResponse = result.msg;
    }, err => {
      this.protectedApiResponse = "Error calling API. Please check logs";
    });
  }

  populateCountryAdminList = () => {
    this.apiService.GET<Country[]>("api/v1/countries").subscribe(countries => {
      this.userService.GetPaymentAdminCountries().subscribe(userCountries => {
        this.adminCountries = countries.filter(c => userCountries.indexOf(c.CountryId) >= 0);
      });
    });
  }

  getProfile() {
    this.http.get(GRAPH_ENDPOINT)
      .subscribe(profile => {
        this.profile = profile;
      });
  }
}
