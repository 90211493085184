<generic-search-page PageTitle="Vendors" [filters]="filterCategories">

  <div class="pull-right" page-controls>
    <button mat-raised-button name="Create" (click)="create()" [disabled]="!UserIsAdmin">
      <mat-icon>add</mat-icon>
      <b>Create Vendor</b>
    </button>
    <button mat-raised-button name="ExcelExport" (click)="downloadLinkHref()" style="margin-left:10px">
      <mat-icon>save_alt</mat-icon>
      <b>Download to Excel</b>
    </button>
  </div>

  <vendor-results-grid results-grid [vendors]="SearchPageComponent?.data" (onEdit)="SearchPageComponent?.edit($event)" (sortChanged)="SearchPageComponent?.onSortChange($event)"></vendor-results-grid>
</generic-search-page>
