import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Injectable } from "@angular/core";
import { AppRoles } from "../shared/enums/AppRoles";
import { CurrentUserService } from "../shared/CurrentUser.service";
import { filter } from 'rxjs';

@Injectable()
export class RoleAuthGuard implements CanActivate {
  constructor(private router: Router, private userService: CurrentUserService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    let roles = route.data.roles as Array<AppRoles>;
    return new Promise((resolve, reject) => {

      if (roles === undefined || roles === null || roles.length == 0) {
        resolve(true);
        return;
      }

      this.userService.IsLoggedIn()
        .pipe(filter(a => a === true))
        .subscribe(isLoggedIn => {
          this.userService.GetAppRoles().subscribe(appRoles => {
            const hasAllRoles: boolean = roles.every(role => appRoles.indexOf(role) >= 0);
            if (hasAllRoles)
              resolve(true);
            else {
              const missingRoles = roles.filter(role => appRoles.indexOf(role) < 0);
              const tree: UrlTree = this.router.createUrlTree(["no-role-access"], { queryParams: { roles: missingRoles }, queryParamsHandling: 'merge' });
              resolve(tree);
            }
          });
        });
    });
  }
}
