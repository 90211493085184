import { AgreementType } from "../../modals/vendor-agreement-modal/types/VendorAgreement";
import { IFilterCategoryDef, IFilterDef, IFilterDefWithDateRange, IFilterDefWithOptions, IFilterDefWithTypeahead, SelectOption } from "../../shared/interfaces/IFilterDef";
import { TypeaheadItem } from "../typeahead/typeahead.component";
import { AgreementSearchDropdowns } from "./types/AgreementSearchDropdowns";
import { AgreementSearchParameters } from "./types/AgreementSearchParameters";

var namedFilters = {
  VendorName: <IFilterDef<AgreementSearchParameters>>{ name: "Vendor Name", inputType: "text", hintText: "Executes a 'contains' search", searchParameterProperty: "VendorName" },
  VendorCode: <IFilterDef<AgreementSearchParameters>>{ name: "Vendor Code", inputType: "text", hintText: "Executes a 'contains' search", searchParameterProperty: "VendorCode" },
  Countries: <IFilterDefWithTypeahead<number, AgreementSearchParameters>>{ name: "Vendor Country", inputType: "autocomplete", options: [], searchParameterProperty: "VendorCountry" },

  AgreementYear: <IFilterDefWithOptions<number, AgreementSearchParameters>>{ name: "Agreement Year", inputType: "selectSingle", options: [], searchParameterProperty: "AgreementYear" },
  AgreementNumber: <IFilterDef<AgreementSearchParameters>>{ name: "Agreement Number", inputType: "text", searchParameterProperty: "AgreementNumber" },
  AgreementType: <IFilterDefWithOptions<AgreementType, AgreementSearchParameters>>{ name: "Agreement Type", inputType: "selectSingle", options: [], searchParameterProperty: "AgreementType" },
  AgreementEndDate: <IFilterDefWithDateRange<AgreementSearchParameters>>{ name: "Agreement End Date", inputType: "dateRange", options: [], EndDateSearchParameterProperty: "AgreementEndDateRangeEnd", StartDateSearchParameterProperty: "AgreementEndDateRangeStart" },
  AgreementActive: <IFilterDefWithOptions<boolean, AgreementSearchParameters>>{ name: "Agreement Active", inputType: "selectSingle", options: [], searchParameterProperty: "AgreementActive" },
}

var filterCategories: IFilterCategoryDef<AgreementSearchParameters>[] = [
  {
    categoryName: "Vendor",
    filters: [
      namedFilters.VendorName,
      namedFilters.VendorCode,
      namedFilters.Countries
    ]
  },
  {
    categoryName: "Agreement",
    filters: [
      namedFilters.AgreementYear,
      namedFilters.AgreementNumber,
      namedFilters.AgreementType,
      namedFilters.AgreementEndDate,
      namedFilters.AgreementActive
    ]
  }
];

var filters: IFilterDef<AgreementSearchParameters>[] = [
  namedFilters.VendorName,
  namedFilters.VendorCode,
  namedFilters.Countries
]

export var filterDefinitions = {
  namedFilters: namedFilters,
  filterCategories: filterCategories,
  filters: filters,
  mapFiltersFromApi: (response: AgreementSearchDropdowns) => {

    if (!response)
      return;

    namedFilters.Countries.options = response.AgreementCountries.map(a => <TypeaheadItem<number>>{
      DisplayText: a.Name,
      Item: a.Id
    });
    namedFilters.AgreementYear.options = response.AgreementYears.map(a => <SelectOption<number>>{ display: a.toString(), value: a });
    namedFilters.AgreementType.options = response.AgreementTypes.map(a => <SelectOption<number>>{ display: a.Description, value: a.Id });
    namedFilters.AgreementActive.options = [{ display: "Yes", value: true }, { display: "No", value: false }];
  }
}
