import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'loading-overlay',
    templateUrl: 'loading-overlay.component.html'
})
export class LoadingOverlayComponent implements OnInit {
    public Message = "";
    constructor(@Inject(MAT_DIALOG_DATA) data) {
        this.Message = data.Message;
    }

    ngOnInit(): void {
    }
}

@Injectable()
export class LoadingService {
    private nextId = 1;

    private refs: Array<LoadingRefInternal> = [];
    private dialogRef: MatDialogRef<any, any> = null;

    constructor(@Inject(MatDialog) private dialog: MatDialog) {

    }

    private UpdateDisplay = () => {
        if (this.refs.length > 0) {
            const message = this.refs[this.refs.length - 1].Message;
            if (this.dialogRef === null) {
                this.dialogRef = this.dialog.open(LoadingOverlayComponent, {
                    data: { Message: message },
                    disableClose: true
                });
            }
            else
                if (this.dialogRef.componentInstance) // prevents console log errors when user hits Browser back button
                    this.dialogRef.componentInstance.Message = message;

        }
        else if (this.refs.length === 0 && this.dialogRef !== null) {
            this.dialogRef.close();
            this.dialogRef = null;
        }
    }

    public StartWaiting = (message: string = ""): LoadingRef => {
        const resp: LoadingRefInternal = {
            Id: this.nextId++,
            Message: message,
            Stop: () => {
                const toRemove = this.refs.filter(a => a.Id === resp.Id)[0];
                const index = this.refs.indexOf(toRemove);
                if (index >= 0) {
                    this.refs.splice(index, 1);
                }

                this.UpdateDisplay();
            }
        };

        this.refs.push(resp);
        this.UpdateDisplay();

        return resp;
    }
}

export interface LoadingRef {
    Stop: { (): void };
}

interface LoadingRefInternal extends LoadingRef {
    Id: number;
    Message: string;
}
