import { Component, Inject, Injectable } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'message-modal',
    templateUrl: 'message-modal.component.html'
})

@Injectable()
export class MessageModal {
    headerTitle: string = "";
    message: string = "";
    okText: string = "Ok";


    constructor(@Inject(MatDialogRef) public dialogRef: MatDialogRef<MessageModal>,
        @Inject(MAT_DIALOG_DATA) data) {
        this.headerTitle = data.headerTitle;
        this.message = data.message;
        this.okText = data.okText != null ? data.okText : "Ok";
    }

    onOkClick(): void {
        this.dialogRef.close();
    }
}