import { Type } from "@angular/core";
import { Routes } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { NoRoleAccessComponent } from "./auth/no-role-access/no-role-access.component";
import { RoleAuthGuard } from "./auth/RoleAuthGuard";
import { AgreementSearchComponent } from "./components/agreement-search/agreement-search.component";
import { DirectModalLinkComponent, DirectModalLinkComponentData } from "./components/direct-modal-link/direct-modal-link.component";
import { IModalComponent } from "./components/direct-modal-link/Types/IModalComponent";
import { HomeComponent } from "./components/home/home.component";
import { ProfileComponent } from "./components/profile/profile.component";
import { QuicklinkRedirectComponent } from "./components/quicklink-redirect-component/quicklink-redirect.component";
import { QuoteSearchComponent } from "./components/quote-search/quote-search.component";
import { VendorSearch } from "./components/vendor-search/vendor-search.component";
import { VendorDetailModal } from "./modals/vendor-detail-modal/vendor-detail-modal.component";
import { InvoiceEditModal } from "./modals/invoice-modal/edit/invoice-edit-modal.component";
import { AppRoles } from "./shared/enums/AppRoles";
import { InvoiceSearchComponent } from "./components/invoice-search/invoice-search.component";
import { QuoteEditModal } from "./modals/quote-edit-modal/quote-edit-modal.component";

const LoggedInAndRoleAuthGuard: any[] = [MsalGuard, RoleAuthGuard];

export interface RouteData {
  roles?: AppRoles[];
  modal?: Type<IModalComponent>;
}

export const appRoutes: Routes = [
  {
    path: 'diagnostic-profile',
    component: ProfileComponent,
    canActivate: LoggedInAndRoleAuthGuard,
  },
  {
    path: 'no-role-access',
    component: NoRoleAccessComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: LoggedInAndRoleAuthGuard,
    pathMatch: 'full',
    data: <RouteData>{ roles: [AppRoles.User] }
  },
  {
    path: 'vendors',
    component: VendorSearch,
    canActivate: LoggedInAndRoleAuthGuard,
    pathMatch: "full",
    data: <RouteData>{ roles: [AppRoles.User] }
  },
  {
    path: 'my-vendors',
    component: QuicklinkRedirectComponent,
    canActivate: LoggedInAndRoleAuthGuard,
    pathMatch: "full",
    data: <RouteData>{ roles: [AppRoles.User] }
  },
  {
    path: 'vendor/:id',
    component: DirectModalLinkComponent,
    canActivate: LoggedInAndRoleAuthGuard,
    pathMatch: "full",
    data: <RouteData>{
      roles: [AppRoles.User],
      modal: VendorDetailModal
    }
  },
  {
    path: 'invoices/:invoiceId',
    component: DirectModalLinkComponent,
    canActivate: LoggedInAndRoleAuthGuard,
    data: <DirectModalLinkComponentData>{
      modal: InvoiceEditModal
    }
  },
  {
    path: 'invoices',
    component: InvoiceSearchComponent,
    canActivate: LoggedInAndRoleAuthGuard,
    pathMatch: "full",
    data: <RouteData>{ roles: [AppRoles.User] }
  },
  {
    path: 'quotes',
    component: QuoteSearchComponent,
    canActivate: LoggedInAndRoleAuthGuard,
    pathMatch: "full",
    data: <RouteData>{ roles: [AppRoles.User] }
  },
  {
    path: 'vendors/:vendorId/quotes/:quoteId',
    component: DirectModalLinkComponent,
    canActivate: LoggedInAndRoleAuthGuard,
    data: <DirectModalLinkComponentData>{
      modal: QuoteEditModal
    }
  },
  {
    path: 'my-quotes/field-scientist',
    component: QuicklinkRedirectComponent,
    canActivate: LoggedInAndRoleAuthGuard,
    pathMatch: "full",
    data: <RouteData>{ roles: [AppRoles.User] }
  },
  {
    path: 'my-quotes/regional-payment-admin',
    component: QuicklinkRedirectComponent,
    canActivate: LoggedInAndRoleAuthGuard,
    pathMatch: "full",
    data: <RouteData>{ roles: [AppRoles.User] }
  },
  {
    path: 'agreements',
    component: AgreementSearchComponent,
    canActivate: LoggedInAndRoleAuthGuard,
    pathMatch: "full",
    data: <RouteData>{ roles: [AppRoles.User] }
  },
  {
    path: 'my-agreements',
    component: QuicklinkRedirectComponent,
    canActivate: LoggedInAndRoleAuthGuard,
    pathMatch: "full",
    data: <RouteData>{ roles: [AppRoles.User] }
  },
  {
    path: 'my-invoices/regional-payment-admin',
    component: QuicklinkRedirectComponent,
    canActivate: LoggedInAndRoleAuthGuard,
    pathMatch: "full",
    data: <RouteData>{ roles: [AppRoles.User] }
  },
  {
    path: 'my-invoices/field-scientist',
    component: QuicklinkRedirectComponent,
    canActivate: LoggedInAndRoleAuthGuard,
    pathMatch: "full",
    data: <RouteData>{ roles: [AppRoles.User] }
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: LoggedInAndRoleAuthGuard,
    pathMatch: 'full',
    data: <RouteData>{ roles: [AppRoles.User] }
  },
]
