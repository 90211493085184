import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "../../shared/ApiService";
import { DocumentMetadataDto } from "./DocumentMetadataDto";

@Injectable()
export class DocumentPickerService {

  constructor(private apiService: ApiService) {

  }

  public UploadFile = (file: File): Observable<DocumentMetadataDto> => {
    let formData: FormData = new FormData();
    formData.append("file", file, file.name);
    let headers = new Headers();

    headers.append("Content-type", "multipart/form-data");
    headers.append("Accept", "application/json");

    return this.apiService.POST(formData, "api/v1/documents");
  }
}
