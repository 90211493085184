import { Component, OnDestroy, HostBinding, Optional, Self, Inject, ElementRef, Input, ViewChild, EventEmitter, Output } from "@angular/core";
import { FormControl, NgControl, ControlValueAccessor } from "@angular/forms";
import { startWith, map, tap } from "rxjs";
import { Observable, Subject, merge } from "rxjs";
import { FocusMonitor } from "@angular/cdk/a11y";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MatFormFieldControl } from "@angular/material/form-field";
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";

/*
 * Typeahead usage and documentation
 * 
 * This "wraps" the Angular Material Autocomplete component and Angular Material Chip components,
 * and removes some of the complexity of having to manually wire up the filtering
 * and events necessary to get it to work.
 * 
 * Usage is meant to be simple. All you need to do is pass in a list of
 * TypeaheadItem<T>, and bind to the [(ngModel)] to get the selected item(s).
 * 
 * Usage:
 *    Single:
 *      <ftms-typeahead [items]="someList" [(ngModel)]="selectedItem"></ftms-typeahead>
 *    Single with an async list:
 *      <ftms-typeahead [items]="someList | async" [(ngModel)]="selectedItem"></ftms-typeahead>
 *    Multiple:
 *      <ftms-typeahead [items]="someList" [(ngModel)]="selectedItem" multiple></ftms-typeahead>
 *      
 * Other notes and characteristics:
 *  * If you set the selected value to something that isn't in the list of items, then the
 *    selected item will be set to undefined automatically
 *  * If you update the list of items, the typeahead component will handle those updates. If an
 *    item was previously selected and is not in the new list, then it will be set to undefined
 * 
 * Using the TypeaheadItem<T>
 *      The TypeaheadItem<T> represents an item in the typeahead dropdown list. It contains two properties:
 *        1. DisplayText, which is used for the display, as well as for filtering
 *        2. Item, which can be any object or value that you want to be set to the ngModel when an
 *           item is selected.
 */

export interface TypeaheadItem<T> {
    DisplayText: string;
    Item: T;
}

@Component({
    templateUrl: 'typeahead.component.html',
    providers: [
        {
            provide: MatFormFieldControl,
            useExisting: TypeaheadComponent
        },
    ],
    selector: 'ftms-typeahead:not([multiple])'
})
export class TypeaheadComponent<T> implements MatFormFieldControl<TypeaheadItem<T>>, ControlValueAccessor, OnDestroy
{
    // Used to create a unique id for each typeahead. Required by MatFormFieldControl
    static nextId = 0;
    @HostBinding() id: string = `ftms-typeahead-${TypeaheadComponent.nextId++}`;
    controlType: string = "ftms-typeahead";

    @Output() optionSelected = new EventEmitter<T>();

    // used to tell MatFormField that the control was updated
    stateChanges: Subject<void> = new Subject<void>();

    // This is the raw value of the input box, which could be a typeahead item, undefined, or a search term
    private _rawValue: undefined | string | TypeaheadItem<T>;

    private _setSelectedItem = (item: undefined | TypeaheadItem<T>) => {
        var isDifferent = this._rawValue !== item;
        this._rawValue = item;

        if (this.onChangeFn)
            this.onChangeFn(item === undefined ? undefined : item.Item);
        this.stateChanges.next();

        if(isDifferent)
            this.optionSelected.emit(item == undefined ? undefined : item.Item);
    }

    // All possible items in the dropdown
    private _itemList: Array<TypeaheadItem<T>> | undefined = undefined;

    // Keep track of the filtered list of items, based on the user's search query
    public FilteredList: Observable<Array<TypeaheadItem<T>>>;

    // Represents the input field, used for subscribing to value changes for searching the list
    public inputControl: FormControl = new FormControl();

    /* ControlValueAccessor, which allows using NgModel with this*/
    private onChangeFn: (newValue: T) => void;
    public onTouchedFn: () => any = () => { };
    writeValue(obj: any): void {
        this.value = obj;
    }
    registerOnChange(fn: any): void {
        this.onChangeFn = fn
    }
    registerOnTouched(fn: any): void {
        this.onTouchedFn = fn;
    }

    private _getItemFromList = (item: TypeaheadItem<T> | T | any): TypeaheadItem<T> | undefined => {
	    if (this._itemList == undefined || item === undefined || item === null)
            return undefined;

        // first, search to see if the item is a Typeahead Item
        let result = this._itemList.find(a => a.Item === item.Item);

        if (result !== undefined)
            return result;

        // If not, then search if it is an "item" on one of the values. Returns undefined if not.
        return this._itemList.find(a => a.Item === item);
    }

    private isTypeaheadItem = (s: TypeaheadItem<T> | any): s is TypeaheadItem<T> => {
        return s !== null && s !== undefined && s.DisplayText !== undefined && s.Item !== undefined;
    }

    private isString = (s: any): s is string => typeof (s) === "string";

    private _getDisplayText = (): string => {
        if (this._rawValue === undefined || this._rawValue === null || this.isString(this._rawValue))
            return this._rawValue as string;

        if (this.isTypeaheadItem(this._rawValue))
            return this._rawValue.DisplayText;

        // This should never happen
        return undefined;
    }

    public getFullList = () => {
        if (this.ItemList == null)
            return [];
        else
            return this.ItemList.slice();
    }

    @Input("items")
    set ItemList(items: Array<TypeaheadItem<T>>) {
        this._itemList = items;

        // check if the selected item is in the list still. If not, then clear the selected value
        if (this._rawValue !== undefined && this._getItemFromList(this._rawValue) === undefined) {
	        this._setSelectedItem(undefined);
			this.inputControl.setValue(null);
        }

        // make it so that the dropdown list is auto-filtered to what is already typed in the search box.
        let initialSearch = this._getDisplayText();
        if (initialSearch === null || initialSearch === undefined)
            initialSearch = "";

        // set up auto-filtering on the list when the user types
        this.FilteredList = this.inputControl.valueChanges.pipe(
            startWith(this.inputControl.value),
            map(item => item === null ? this.inputControl.value : item),
            map(item => item ? this.FilterList(item) : this.getFullList())
        );
    }
    get ItemList(): Array<TypeaheadItem<T>> {
        return this._itemList;
    }


    // Keep track of the raw text value (which could be a search term) in the typeahead
    set textValue(s: string | TypeaheadItem<T>) {
        if (this._rawValue === null && s === null || this._rawValue === s)
            return;

        if (this.isString(s)) {
            this._setSelectedItem(undefined);
            this._rawValue = s;
        }
        else if (s !== null && s !== undefined) {
            // s is a TypeaheadItem or an item from the TypeaheadItem object.
            // check that it is in the list of items in the dropdown

            let newValue = this._getItemFromList(s);
            this._setSelectedItem(newValue);
        }
        else if (s === undefined) {
            this._setSelectedItem(undefined);
        }
    }

    // Used for the input field. This can contain the selected item, or the search text
    get textValue(): TypeaheadItem<T> | string {
        return this._rawValue;
    }

    // The selected value of this control. This should either be undefined or the selected TypeaheadItem
    get value(): TypeaheadItem<T> | null {
        if (this.isTypeaheadItem(this._rawValue))
            return this._rawValue;
        else
            return undefined;
    }

    set value(selectedItem: TypeaheadItem<T> | null) {
        this.textValue = selectedItem;
    }

    get empty(): boolean {
        return this.value == null;
    }

    @HostBinding('class.floating')
    get shouldLabelFloat(): boolean {
        let hasNoText = this._rawValue === null || this._rawValue === undefined || this._rawValue === "";
        return this.focused || !this.empty || !hasNoText;
    }

    // These are required for MatFormFieldControl
    placeholder: string;
    focused: boolean;

    private _required: boolean = false;
    @Input()
    get required() {
        return this._required;
    }
    set required(req) {
        this._required = !!req;
        this.stateChanges.next();
    }

    disabled: boolean;
    get errorState() {
        if (this.ngControl && this.ngControl.control && this.ngControl.control.touched)
            return this.ngControl.control.errors !== null;
        else
            return false;
    }

    get errors() {
        if (this.ngControl && this.ngControl.control && this.ngControl.control.touched)
            return this.ngControl.control.errors;
        else
            return null;
    }

    autofilled?: boolean;

    @HostBinding('attr.aria-describedby') describedBy = '';

    setDescribedByIds(ids: string[]): void {
        this.describedBy = ids.join(' ');
    }
    onContainerClick(event: MouseEvent): void { }

    private clearTypeahead = (): void => {
	    this.value = undefined;
	    this.ngControl.control.markAsTouched();
    }

    constructor(@Optional() @Self() @Inject(NgControl) public ngControl: NgControl,
        @Inject(ElementRef) private elRef: ElementRef<HTMLElement>,
        @Inject(FocusMonitor) private focusMonitor: FocusMonitor) {
        focusMonitor.monitor(elRef.nativeElement, true).subscribe(origin => {
            this.focused = !!origin;
            this.stateChanges.next();
        })

        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    ngOnDestroy() {
        this.stateChanges.complete();
        this.focusMonitor.stopMonitoring(this.elRef.nativeElement);
    }

    // Handles filtering the list of items to display in the dropdown list
    public FilterList = (searchValue: string | TypeaheadItem<T>): Array<TypeaheadItem<T>> => {
        if (this.ItemList == null)
            return [];
        if (this.isString(searchValue)) {
            // for now, we will always do a case-insenstive search against the list, but this
            // could be added as a configuration setting to this component
            return this.ItemList.filter(item => this._compareItems(item, searchValue));
        }
        else if (this.isTypeaheadItem(searchValue)) {
            return [searchValue];
        }
        else
            return [];
    }

    private _compareItems = (item: TypeaheadItem<T>, query: string): boolean => {
	    if (!item.DisplayText)
		    return false;
        return item.DisplayText.toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) >= 0;
    }

    public DisplayItem = (item: TypeaheadItem<T> | null): string => {
        if (item === null || item === undefined)
            return "";
        else
            return item.DisplayText;
    }
}

@Component({
    templateUrl: 'typeahead-multi.component.html',
    providers: [
        {
            provide: MatFormFieldControl,
            useExisting: TypeaheadMultiComponent
        },
    ],
    selector: 'ftms-typeahead[multiple]'
})
export class TypeaheadMultiComponent<T> implements MatFormFieldControl<Array<TypeaheadItem<T>>>, ControlValueAccessor, OnDestroy {

    @ViewChild("autocompleteTrigger", { static: true }) autocompleteTrigger: MatAutocompleteTrigger;
    // Used to create a unique id for each typeahead. Required by MatFormFieldControl
    static nextId = 0;
    @HostBinding() id: string = `ftms-typeahead-multi-${TypeaheadComponent.nextId++}`;
    controlType: string = "ftms-typeahead-multi";
    separatorKeyCodes: number[] = [ENTER, COMMA];

    // used to tell MatFormField that the control was updated
    stateChanges: Subject<void> = new Subject<void>();

    // Represents the input field, used for subscribing to value changes for searching the list
    public inputControl: FormControl = new FormControl();

    // All possible items in the dropdown
    private _itemList: Array<TypeaheadItem<T>> | undefined = undefined;

    // Keep track of the filtered list of items, based on the user's search query
    public FilteredList: Observable<Array<TypeaheadItem<T>>>;

    ngOnDestroy(): void {
        this.stateChanges.complete();
        this.focusMonitor.stopMonitoring(this.elRef.nativeElement);
    }

    /* ControlValueAccessor, which allows using NgModel with this*/
    private onChangeFn: (newValue: Array<T>) => void;
    writeValue(obj: any): void {
        this.value = obj;
    }
    registerOnChange(fn: any): void {
        this.onChangeFn = fn;
    }
    registerOnTouched(fn: any): void { }
    
    private _getItemFromList = (item: TypeaheadItem<T> | T | any): TypeaheadItem<T> | undefined => {
        if (this._itemList == undefined || item === undefined || item === null)
            return undefined;

        // first, search to see if the item is a Typeahead Item
        let result = this._itemList.find(a => a === item);

        if (result !== undefined)
            return result;

        // If not, then search if it is an "item" on one of the values. Returns undefined if not.
        return this._itemList.find(a => a.Item === item);
    }

    // The selected value of this control. This should either be undefined or the selected TypeaheadItem
    get value(): TypeaheadItem<T>[] | undefined {
        if (this.SelectedItems)
            return this.SelectedItems;
        else
            return undefined;
    }

    set value(selectedItems: TypeaheadItem<T>[] | undefined) {

        if (selectedItems !== null && selectedItems !== undefined) {
            if (selectedItems.length === undefined)
                selectedItems = [<any>selectedItems];

            // validate that all items exist in the dropdown, and get their full objects
            this.SelectedItems = selectedItems.map(a => this._getItemFromList(a)).filter(a => a !== undefined);
            if (this.SelectedItems.length !== selectedItems.length)
                this._notifyOfChangedValue();
        }
    }

    placeholder: string;
    focused: boolean;
    get empty(): boolean {
        return this.SelectedItems == null || this.SelectedItems.length === 0;
    }
    @HostBinding('class.floating')
    get shouldLabelFloat(): boolean {
        let inputValue = this.inputControl.value;
        let hasNoText = inputValue === null || inputValue === undefined || inputValue === "";
        return this.focused || !this.empty || !hasNoText;
    }

    private _required: boolean = false;
    @Input()
    get required() {
        return this._required;
    }
    set required(req) {
        this._required = !!req;
        this.stateChanges.next();
    }

    disabled: boolean;
    errorState: boolean;
    autofilled?: boolean;

    @HostBinding('attr.aria-describedby') describedBy = '';

    setDescribedByIds(ids: string[]): void {
        this.describedBy = ids.join(' ');
    }
    onContainerClick(event: MouseEvent): void { }

    public SelectedItems: Array<TypeaheadItem<T>>;


    @Input("items")
    set ItemList(items: Array<TypeaheadItem<T>>) {
        this._itemList = items;

        // check if the selected item is in the list still. If not, then clear the selected value
        if (this.SelectedItems != null) {
            let oldLength = this.SelectedItems.length;
            // validate that all items exist in the dropdown, and get their full objects
            this.SelectedItems = this.SelectedItems.map(a => this._getItemFromList(a)).filter(a => a !== undefined);
            if (oldLength !== this.SelectedItems.length)
                this._notifyOfChangedValue();
        }

        let initialSearch = "";

        // set up auto-filtering on the list when the user types
        this.FilteredList = merge(this.manualValueChanges, this.inputControl.valueChanges).pipe(
            startWith(initialSearch),
            map(item => item ? this.FilterList(item) : this._getUnselectedItems().slice())
        );
    }

    autocompleteOpened = () => {
        this.manualValueChanges.next("");
    }

    private manualValueChanges = new Subject<string>();
    get ItemList(): Array<TypeaheadItem<T>> {
        return this._itemList;
    }

    private isString = (s: any): s is string => typeof (s) === "string";

    private isTypeaheadItem = (s: TypeaheadItem<T> | any): s is TypeaheadItem<T> => {
        return s !== null && s !== undefined && s.DisplayText !== undefined && s.Item !== undefined;
    }

    private _getUnselectedItems = (): Array<TypeaheadItem<T>> => {
        if (this.ItemList == null)
            return [];
        else if (this.SelectedItems == null)
            return this.ItemList;
        else
            return this.ItemList.filter(a => this.SelectedItems.indexOf(a) === -1);
    }

    // Handles filtering the list of items to display in the dropdown list
    public FilterList = (searchValue: string | TypeaheadItem<T>): Array<TypeaheadItem<T>> => {
        if (this.ItemList == null)
            return [];

        // filter out items that are already selected
        let fullList = this._getUnselectedItems();
        if (this.isString(searchValue)) {
            // for now, we will always do a case-insenstive search against the list, but this
            // could be added as a configuration setting to this component
            return fullList.filter(item => this._compareItems(item, searchValue));
        }
        else if (this.isTypeaheadItem(searchValue)) {
            return [searchValue];
        }
        else
            return [];
    }

    private _compareItems = (item: TypeaheadItem<T>, query: string): boolean => {
	    if (!item.DisplayText)
		    return false;
        return item.DisplayText.toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) >= 0;
    }

    constructor(@Optional() @Self() @Inject(NgControl) public ngControl: NgControl,
        @Inject(ElementRef) private elRef: ElementRef<HTMLElement>,
        @Inject(FocusMonitor) private focusMonitor: FocusMonitor) {
        focusMonitor.monitor(elRef.nativeElement, true).subscribe(origin => {
            this.focused = !!origin;
            this.stateChanges.next();
        })

        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    public DisplayItem = (item: TypeaheadItem<T> | null): string => {
        if (item === null || item === undefined)
            return "";
        else
            return item.DisplayText;
    }

    add = (event: MatChipInputEvent) => {
        // this is called when a user types text, then hits ENTER or COMMA. In other words,
        // this will just add the text value of whatever was typed to the chip list, but we
        // only want to allow users to add items using the typeahead feature, so we can ignore this
    }

    autocompleteSelect = (event: MatAutocompleteSelectedEvent) => {
        if (this.SelectedItems == null)
            this.SelectedItems = [event.option.value];
        else
            this.SelectedItems.push(event.option.value);

        this._notifyOfChangedValue();
        this.inputControl.setValue("");

        setTimeout(() => {

            // this has to be in a setTimeout because the panel is going to be
            // closed after the item is selected.
            this.autocompleteTrigger.openPanel();
            this.manualValueChanges.next("");
        }, 5);
    }

    _notifyOfChangedValue = () => {
        if (this.onChangeFn)
            this.onChangeFn(this.SelectedItems.map(a => a.Item));
        this.stateChanges.next();
    }

    remove = (item: TypeaheadItem<T>) => {
        const index = this.SelectedItems.indexOf(item);

        if (index >= 0) {
            this.SelectedItems.splice(index, 1);
            this._notifyOfChangedValue();
        }
        this.manualValueChanges.next("");
    }
}
