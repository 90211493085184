import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { VendorAgreementDropdowns } from "./types/VendorAgreementDropdowns";
import { VendorAgreement, VendorAgreementCreate, VendorAgreementUpdate } from "./types/VendorAgreement";
import { ApiService, FtmsHttpParams } from "../../shared/ApiService";

@Injectable()
export class VendorAgreementModalService {

  constructor(private apiService: ApiService) {
    }

    public GetVendorAgreementDropdowns = (): Observable<VendorAgreementDropdowns> => {
        return this.apiService.GET<VendorAgreementDropdowns>('api/v1/vendor-agreements-meta');
    }

    public GetAgreementById = (vendorId: number, id: number): Observable<VendorAgreement> => {
        return this.apiService.GET<VendorAgreement>(`api/v1/vendors/${vendorId}/agreements/${id}`, this.getNotFoundHandler());
    }

    public GetAgreementsByVendorId = (vendorId: number): Observable<Array<VendorAgreement>> => {
        return this.apiService.GET<Array<VendorAgreement>>(`api/v1/vendors/${vendorId}/agreements`, this.getNotFoundHandler());
    }

    public CreateAgreement = (vendorId: number, createData: VendorAgreementCreate): Observable<VendorAgreement> => {
        return this.apiService.POST<VendorAgreementCreate, VendorAgreement>(createData, `api/v1/vendors/${vendorId}/agreements`);
    }

    public UpdateAgreement = (vendorId: number, agreementId: number, updateData: VendorAgreementUpdate): Observable<VendorAgreement> => {
        return this.apiService.PUT<VendorAgreementUpdate, VendorAgreement>(updateData, `api/v1/vendors/${vendorId}/agreements/${agreementId}`);
    }
            
    private getNotFoundHandler = () => {
        return new FtmsHttpParams().WithOnNotFoundHandler(() => {
	        return {
		        continueExecutingErrorHandlers: false
	        };
        });
    }
}
