import { Component, Inject, Injectable, OnInit } from "@angular/core";
import { AbstractControl, FormGroup, NgForm } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Data, Params } from "@angular/router";
import { Observable, of } from "rxjs";
import { ChangeDetectionService } from "../../shared/services/change-detection.service";
import { DialogService } from "../../shared/services/dialog.service";
import { LoadingService } from "../../shared/services/loading-overlay/loading-overlay.component";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { AppDateAdapter, APP_DATE_FORMATS } from "../../shared/format-datepicker";
import { ContactforVendor, FieldScientistForVendor, QuoteDropdowns } from "./types/QuoteDropdowns";
import { QuoteModalService } from "./quote-modal.service";
import { Quote, QuoteCreate, QuoteStatus } from "./types/Quote";

export interface ShowQuoteModalData {
  /** Required */
  VendorId: number;
}

@Component({
  selector: 'quote-create-modal',
  templateUrl: 'quote-create-modal.component.html',
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class QuoteCreateModalComponent implements OnInit {
  private changeDetectionService: ChangeDetectionService = null;

  public Dropdowns = {
    Years: <Array<number>>[],
    Contacts: <Array<ContactforVendor>>[],
    FieldScientists: <Array<FieldScientistForVendor>>[],
  }

  public Quote = {
    Id: <number>undefined,
    VendorId: <number>undefined,
    Year: <number>undefined,
    QuoteNumber: <string>undefined,
    QuoteNumberSequence: <number>undefined,
    FieldScientistId: <number>undefined,
    FieldScientistName: <string>undefined,
    ContactId: <number>undefined,
    ContactName: <string>undefined,
    PurchaseRequisitionNumber: <string>undefined,
    PurchaseOrderNumber: <string>undefined,
    PurchaseOrderStartDate: <Date>undefined,
    PurchaseOrderEndDate: <Date>undefined,
    CreatedByPersonId: <number>undefined,
    CreatedDate: <Date>undefined,
    UpdatedByPersonId: <number>undefined,
    UpdatedDate: <Date>undefined,
  };

  constructor(@Inject(MatDialogRef) private dialogRef: MatDialogRef<QuoteCreateModal, number>,
    @Inject(MAT_DIALOG_DATA) private showModalData: ShowQuoteModalData,
    @Inject(MatDialog) public dialog: MatDialog,
    @Inject(MatSnackBar) private snackbar: MatSnackBar,
    @Inject(LoadingService) private loadingService: LoadingService,
    @Inject(QuoteModalService) private quoteService: QuoteModalService,
    @Inject(DialogService) public dialogService: DialogService) {
    this.changeDetectionService = new ChangeDetectionService(dialogService);
  }

  ngOnInit(): void {
    this.Quote.VendorId = this.showModalData.VendorId;
  }

  public SaveQuote = (form: NgForm) => {
    if (!this.CheckFormIsValid(form))
      return;

    const loadingRef = this.loadingService.StartWaiting("Creating new Quote");

    const createData: QuoteCreate = {
      ContactId: this.Quote.ContactId,
      FieldScientistId: this.Quote.FieldScientistId,
      Year: this.Quote.Year,
      PurchaseRequisitionNumber: this.Quote.PurchaseRequisitionNumber,
      PurchaseOrderNumber: this.Quote.PurchaseOrderNumber,
      PurchaseOrderStartDate: this.Quote.PurchaseOrderStartDate,
      PurchaseOrderEndDate: this.Quote.PurchaseOrderEndDate,
      Comments: null,
      Status: QuoteStatus.New,
      VendorAssignedQuoteNumber: null,
      PaymentLineItemAssocUpdates: [],
      DocumentIds: []
    }

    const apiResponse = this.quoteService.CreateQuote(this.Quote.VendorId, createData);

    apiResponse.subscribe((result: Quote) => {
      loadingRef.Stop();
      // Close modal
      this.dialogRef.close(result.Id);
    }, (error) => {
      loadingRef.Stop();
    });
  }

  private GetControls = (formGroup: FormGroup): Array<AbstractControl> => {
    if (!formGroup || !formGroup.controls)
      return [];

    var controls: Array<AbstractControl> = [];
    for (const ctrlKey in formGroup.controls) {
      var control = formGroup.controls[ctrlKey];
      if (control instanceof FormGroup) {
        controls = controls.concat(this.GetControls(control));
      }
      else {
        controls.push(control);
      }
    }

    return controls;
  }

  public CheckFormIsValid = (form: NgForm): boolean => {
    let hasErrors = false;

    var controls: Array<AbstractControl> = this.GetControls(form.form);
    for (const ctrl of controls) {
      if (ctrl.errors) {
        ctrl.markAllAsTouched();
        hasErrors = true;
      }
    }

    if (hasErrors) {
      this.snackbar.open("There are validation errors. Please fix the highlighted fields and try again.", null, {
        duration: 5000,
      });
    }

    return !hasErrors;
  }

  public CancelModal = async () => {
    let shouldLeave = await this.CheckForChangesAndPrompt();
    if (!shouldLeave)
      return;

    this.dialogRef.close(undefined);
  }

  private async CheckForChangesAndPrompt(): Promise<boolean> {
    if (this.changeDetectionService.HasChanges(this.Quote)) {
      let shouldLeave = await this.changeDetectionService.HasChangeDialog();
      if (!shouldLeave)
        return false;
    }
    return true;
  }
}

@Injectable()
export class QuoteCreateModal {
  constructor(@Inject(MatDialog) private dialog: MatDialog) {
  }

  ShowStandaloneModal(data: Data, params: Params): Observable<any> {
    var modalParams: ShowQuoteModalData =
    {
      VendorId: Number(params.get('vendorId'))
    };

    return this.ShowModal(modalParams);
  }

  public ShowModal =
    /** Displays the Quote modal. Returns the Quote id when the user closes the modal, or undefined if no Quote was created
     * @returns {Observable<number | undefined>} The Quote ID or undefined if no vendor was created.
     */
    (data: ShowQuoteModalData): Observable<number | undefined> => {
      const ref = this.dialog.open(QuoteCreateModalComponent, {
        data: data,
        width: "800px",
        disableClose: true,
        autoFocus: false,
      });

      return ref.afterClosed();
    }
}
