import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject, Subject, ReplaySubject, combineLatest, takeUntil } from "rxjs";
import { map, filter } from 'rxjs';
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from "@azure/msal-browser";
import { HttpClient } from "@angular/common/http";
import { AppInfoService } from "../singletons/app-info.service";
import { AppRoles } from "./enums/AppRoles";
import { ApiService, FtmsHttpParams } from "./ApiService";

@Injectable()
export class CurrentUserService {
  private readonly _destroying$ = new Subject<void>();

  private loggedIn = new BehaviorSubject<boolean>(null);
  private personSubject = new ReplaySubject<DbPerson>();

  constructor(private authService: MsalService, broadcastService: MsalBroadcastService, private http: HttpClient, private appInfoService: AppInfoService, private apiService: ApiService) {

    broadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe((result: EventMessage) => {
        console.log("MSAL Event Subscription", result);
        const payload = result.payload as AuthenticationResult;
        authService.instance.setActiveAccount(payload.account);
      });

    broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        console.log("MSAL Interaction Subscription");
        this.setLoginDisplay();
      });
  }

  setLoginDisplay = () => {
    var isLoggedIn = this.authService.instance.getAllAccounts().length > 0;
    this.loggedIn.next(isLoggedIn);

    if (isLoggedIn) {
      this.apiService.GET<DbPerson>("api/v1/people/current").subscribe(p => {
        this.personSubject.next(p);
      });
    }
    else {
      this.personSubject.next(null);
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  public IsLoggedIn = (): Observable<boolean> => {
    return this.loggedIn.asObservable();
  }

  public IsLoggedInCurrently = (): boolean => {
    return this.authService.instance.getAllAccounts().length > 0;
  }

  public GetProfilePictureIcon = (): Observable<string> => {
    const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me/photos/96x96/$value';

    return this.http.get(GRAPH_ENDPOINT, {
      responseType: "blob",
      params: new FtmsHttpParams().WithOnErrorHandler(() => {
        // This is important because not everyone has a profile picture. If they
        // do not, then it will generate a 404 error, causing the error popup.
        return {
          continueExecutingErrorHandlers: false
        }
      })
    }).pipe(
      map(d => window.URL.createObjectURL(d))
    );
  }

  public GetRoleClaims = (): Array<string> => {
    var claims: any = this.GetRawUser().idTokenClaims;
    return claims.roles;
  }

  public GetAppRoles = (): Observable<Array<AppRoles>> => {
    var dbRoles = this.personSubject.asObservable().pipe(
      map(p => <AppRoles[]>p.UserRoles)
    );

    var tokenRoles = this.appInfoService.getAppStartupInfo()
      .pipe(
        filter(a => a != null),
        map(startupInfo => {

          let mapping = new Map<AppRoles, string>();
          mapping.set(AppRoles.User, startupInfo.UserClaimsRoleName);
          mapping.set(AppRoles.PaymentAdmin, "PaymentAdmin");

          let appRoles: Array<AppRoles> = [];
          let roleClaims = this.GetRoleClaims();

          for (let roleMapping of mapping) {
            if (roleClaims.indexOf(roleMapping[1]) >= 0)
              appRoles.push(roleMapping[0]);
          }

          return appRoles;
        })
    );

    return combineLatest(dbRoles, tokenRoles, (dbRole, tokenRole) => {
      return [...dbRole, ...tokenRole];
    });
  }

  public GetPaymentAdminCountries = (): Observable<number[]> => {
    return this.personSubject.asObservable().pipe(
      map(p => p.PaymentAdminCountries)
    );
  }

  public HasRole = (role: AppRoles): Observable<boolean> => {
    return this.GetAppRoles().pipe(map(roles => {
      return roles.indexOf(role) > -1;
    }));
  }

  public GetRawUser = () => {
    return this.authService.instance.getActiveAccount();
  }

  public GetApiUser = (): Observable<DbPerson> => {
    return this.personSubject.asObservable();
  }
}

export interface DbPerson {
  Id: number;
  UserRoles: Array<number>;
  PaymentAdminCountries: Array<number>;

  FirstName: string;
  LastName: string;
  EmailAddress: string;
  Username: string;
  Active: boolean;
}
