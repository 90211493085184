<h1 mat-dialog-title>
  <ng-container>
    <span *ngIf="IsNewAgreement">Create Vendor Agreement</span>
    <span *ngIf="!IsNewAgreement">Edit Vendor Agreement</span>
  </ng-container>

  <div class="pull-right" matTooltip="{{activeToggleTooltip()}}">
    <span>{{Agreement.Active ? "Active  " : "Inactive  "}}</span>
    <mat-slide-toggle color="accent" [disabled]="!Agreement.Id || !CanActivate" [checked]="Agreement.Active" (change)="ToggleActive(agreementEditForm)">
    </mat-slide-toggle>
  </div>
</h1>

<div mat-dialog-content #modalContainer scrollInsetShadow>
  <form name="agreement-edit-form" #agreementEditForm="ngForm" style="padding-bottom: 15px;">
    <div class="row">
      <div class="col-sm-6">
        <mat-form-field>
          <input matInput required name="agreement-number" #agreementNumber="ngModel" placeholder="Agreement Number" maxlength="50" [(ngModel)]="Agreement.AgreementNumber">
          <mat-error *ngIf="agreementNumber.errors?.required">
            Agreement Number is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm-6">
        <mat-form-field>
          <mat-label>Type</mat-label>
          <mat-select required [(ngModel)]="Agreement.AgreementType" #agreementType name="agreement-type">
            <mat-option *ngFor="let a of Dropdowns.AgreementTypes" [value]="a.Id">{{a.Description}}</mat-option>
            <mat-error *ngIf="agreementType.errors?.required">
              Type is required
            </mat-error>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <mat-form-field style="margin-bottom: 10px;">
          <mat-label>Effective Start Date</mat-label>
          <input #startDatePickerModel="ngModel" matInput required (click)="startDate.open()"
                 [matDatepicker]="startDate" [(ngModel)]="Agreement.EffectiveStartDate" name="startDate">
          <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
          <mat-datepicker #startDate></mat-datepicker>
          <mat-error *ngIf="startDatePickerModel.errors?.required">
            Start Date is required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field style="margin-bottom: 10px;">
          <mat-label>Effective End Date</mat-label>
          <input #endDatePickerModel="ngModel" matInput required (click)="endDate.open()" [min]="Agreement.EffectiveStartDate"
                 [matDatepicker]="endDate" [(ngModel)]="Agreement.EffectiveEndDate" name="endDate">
          <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
          <mat-datepicker #endDate></mat-datepicker>
          <mat-error *ngIf="endDatePickerModel.errors?.required">
            End Date is required
          </mat-error>
          <mat-error *ngIf="endDatePickerModel.errors?.matDatepickerMin">
            End Date must be after Start Date
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <mat-form-field>
          <mat-label>Payment Plan</mat-label>
          <mat-select [(ngModel)]="Agreement.PaymentPlan" #paymentPlan name="payment-plan">
            <mat-option *ngFor="let a of Dropdowns.PaymentPlans" [value]="a.Id">{{a.Description}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field>
          <input matInput numbersOnly name="timeline" #timeline="ngModel" placeholder="Timeline (Days)" [(ngModel)]="Agreement.Timeline">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <mat-form-field>
          <textarea matInput placeholder="Comments" [(ngModel)]="Agreement.Comments" maxlength="512" name="comments"></textarea>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>

<div mat-dialog-actions style="display: flex">
  <div *ngIf="!IsNewAgreement">
    <dl class="dl-horizontal modal-audit-info">
      <dt *ngIf="Agreement.CreatedByName != undefined && Agreement.CreatedByPersonId != 0">Created:</dt>
      <dd *ngIf="Agreement.CreatedByName != undefined && Agreement.CreatedByPersonId != 0">{{ Agreement.CreatedDate | date:'d-LLL-y' }} by {{ Agreement.CreatedByName }}</dd>
      <dt *ngIf="Agreement.UpdatedByName != undefined && Agreement.UpdatedByPersonId != 0">Updated:</dt>
      <dd *ngIf="Agreement.UpdatedByName != undefined && Agreement.UpdatedByPersonId != 0">{{ Agreement.UpdatedDate | date:'d-LLL-y' }} by {{ Agreement.UpdatedByName }}</dd>
    </dl>
  </div>

  <span style="flex: 1 1 auto;"></span>
  <button mat-raised-button color="accent" (click)="CancelModal()">Cancel</button>
  <button mat-raised-button color="primary" (click)="SaveAgreement(agreementEditForm, false)">Save</button>
  <button mat-raised-button color="primary" (click)="SaveAgreement(agreementEditForm, true)">Save &amp; Close</button>
</div>
