import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { ApiService } from "../../shared/ApiService";
import { PagedResults } from "../../shared/interfaces/PagedResults";
import { QuoteSearchDropdowns } from "./types/QuoteSearchDropdowns";
import { QuoteSearchParameters } from "./types/QuoteSearchParameters";
import { QuoteSearchRow } from "./types/QuoteSearchRow";

@Injectable()
export class QuoteSearchService {
  constructor(private apiService: ApiService) {

  }

  public SearchQuotes = (searchParameters: QuoteSearchParameters): Observable<PagedResults<QuoteSearchRow>> => {
    return this.apiService.POST<QuoteSearchParameters, PagedResults<QuoteSearchRow>>(searchParameters, "api/v1/quotes-search");
  }

  public GetDropdowns = (): Observable<QuoteSearchDropdowns> => {
    return this.apiService.GET<QuoteSearchDropdowns>("api/v1/quote-search-meta");
  }
}
