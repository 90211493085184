<div class="row">
    <h1 mat-dialog-title style="margin: 0 0 10px">
        <span>{{DialogTitle}}</span>
    </h1>
</div>

<div mat-dialog-content #modalContainer>
    <div class="sticky-vendor-search">
        <div class="row">
            <div class="col-sm-4">
                <mat-form-field>
                    <input matInput #vendorSearchInput type="text" placeholder="Vendor" [(ngModel)]="searchValueVendor" (keyup.enter)="Search()" appAutoFocus>
                    <button mat-button *ngIf="searchValueVendor" matSuffix mat-icon-button aria-label="Clear" (click)="ClearVendorSearch()">
                        <mat-icon>close</mat-icon>
                    </button>
                    <button mat-button matSuffix mat-icon-button (click)="Search()">
                        <mat-icon>search</mat-icon>
                    </button>
                    <mat-hint>Wildcard search on Vendor</mat-hint>
                </mat-form-field>
            </div>
            <div class="col-sm-4">
                <mat-form-field>
                    <input matInput #contactSearchInput type="text" placeholder="Contact" [(ngModel)]="searchValueContact" (keyup.enter)="Search()">
                    <button mat-button *ngIf="searchValueContact" matSuffix mat-icon-button aria-label="Clear" (click)="ClearContactSearch()">
                        <mat-icon>close</mat-icon>
                    </button>
                    <button mat-button matSuffix mat-icon-button (click)="Search()">
                        <mat-icon>search</mat-icon>
                    </button>
                    <mat-hint>Wildcard search on Contract</mat-hint>
                </mat-form-field>
            </div>
            <div class="col-sm-4">
                <mat-form-field>
                    <mat-label>Country</mat-label>
                    <ftms-typeahead #country name="country" [(ngModel)]="SelectedCountryId" [items]="Dropdowns.Countries" (optionSelected)="Search()"></ftms-typeahead>
                </mat-form-field>
            </div>
        </div>
        <mat-divider style="margin-top: 10px; margin-bottom: 5px;"></mat-divider>
        <div class="row">
            <div class="col-sm-6">
                <mat-label style="color: rgba(0,0,0,.54); margin-left: 10px;"><b>Vendors</b></mat-label>
            </div>
            <div class="col-sm-6">
                <mat-label style="color: rgba(0,0,0,.54);"><b>Contacts</b></mat-label>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-12">
            <div class="mat-spinner" *ngIf="isLoading">
                <mat-spinner></mat-spinner>
            </div>
            <div *ngIf="!isLoading && (searchResults == null || searchResults.length == 0)" style="text-align: center; padding-top: 30px;">
                <em>No results</em>
            </div>
            <div *ngFor="let vendor of searchResults">
                <mat-card style="margin-top: 10px; padding: 5px 10px 5px 10px;">
                    <mat-card-content>
                        <div class="row vendor-card-header">
                            <div class="col-sm-12" style="padding: 0">
                                <span class="col-sm-3">{{vendor.VendorCode}}</span>
                                <span class="col-sm-2">Agreement - {{vendor.AgreementRequired | YesNo }}</span>
                                <span class="col-sm-3">
                                    End Date -
                                    <i *ngIf="!vendor.IsAgreementStatusOkay" class="fa fa-exclamation-circle" style="color: red"></i>
                                    <span *ngIf="vendor.AgreementRequired" [style]="!vendor.IsAgreementStatusOkay ? 'color:red' : ''">
                                        {{ vendor.AgreementEndDate | date:'dd-LLL-yy' | defaultIfEmpty:'Empty' }}
                                    </span>
                                    <span *ngIf="!vendor.AgreementRequired">
                                        N/A
                                    </span>
                                </span>

                                <span class="col-sm-3">
                                    <ng-container *ngIf="!vendor.IsAgreementStatusOkay">
                                        <i *ngIf="!vendor.IsAgreementStatusOkay" class="fa fa-exclamation-circle text-warning"></i>
                                        No valid/active agreement
                                    </ng-container>
                                    <ng-container *ngIf="vendor.DoesAgreementExpireSoon && vendor.IsAgreementStatusOkay">
                                        <i *ngIf="vendor.DoesAgreementExpireSoon && vendor.IsAgreementStatusOkay" [matTooltip]="vendor.DoesAgreementExpireSoon ? AgreementExpiresSoonMessage : undefined" class="fa fa-exclamation-circle text-warning"></i>
                                        Expires within 90 days
                                    </ng-container>
                                </span>

                                <button [disabled]="checkAgreementStatus && !vendor.IsAgreementStatusOkay"
                                        [matTooltip]="vendor.DoesAgreementExpireSoon ? AgreementExpiresSoonMessage : undefined"
                                        mat-icon-button class="col-sm-1 pull-right" (click)="AddVendor(vendor)">
                                    <mat-icon>add</mat-icon>
                                </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6 auto-ellipsis">
                                <b matTooltip="Vendor - {{vendor.VendorName}}">{{vendor.VendorName}}</b>
                            </div>
                            <div class="col-sm-6 auto-ellipsis">
                                <b matTooltip="Contact - {{vendor.ContactName}}">{{vendor.ContactName}}</b>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-6 address-wrapper">
                                <div>{{vendor.VendorAddress1}}</div>
                                <div *ngIf="vendor.VendorAddress2">{{vendor.VendorAddress2}}</div>
                                <div *ngIf="vendor.VendorAddress3">{{vendor.VendorAddress3}}</div>
                                <div>{{vendor.VendorCity}}, {{vendor.VendorStateSubdivision}} {{vendor.VendorZip}}</div>
                            </div>
                            <div class="col-sm-6 address-wrapper">
                                <div>{{vendor.ContactAddress1}}</div>
                                <div *ngIf="vendor.ContactAddress2">{{vendor.ContactAddress2}}</div>
                                <div *ngIf="vendor.ContactAddress3">{{vendor.ContactAddress3}}</div>
                                <div>{{vendor.ContactCity}}, {{vendor.ContactStateSubdivision}} {{vendor.ContactZip}}</div>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</div>

<div mat-dialog-actions style="display: flex">
    <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageIndex" (page)="onPageChanged($event)" [length]="totalCount"></mat-paginator>
    <span style="flex: 1 1 auto;"></span>
    <button mat-raised-button color="accent" (click)="Cancel()">Cancel</button>
</div>
