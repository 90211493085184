import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AppInfoService } from './singletons/app-info.service';
import { ApiService } from './shared/ApiService';
import { AppMonitoringService } from './AppMonitoringService';
import { SettingsService } from './utility/SettingsService';
import { MsalBroadcastService, MsalGuard, MsalInterceptor, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { MSALGuardConfigFactory } from './auth/MSALGuardConfigFactory';
import { MSALInstanceFactory } from './auth/MSALInstanceFactory';
import { MSALInterceptorConfigFactory } from './auth/MSALInterceptorConfigFactory';
import { appRoutes } from './app-routing.module';
import { CurrentUserService } from './shared/CurrentUser.service';
import { RoleAuthGuard } from './auth/RoleAuthGuard';
import { LoadingOverlayComponent } from "./shared/services/loading-overlay/loading-overlay.component";
import { SearchHelper } from './shared/helpers/SearchHelper';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormControlHelper } from './shared/helpers/FormControlHelper';
import { ErrorHandlerService, HttpErrorInterceptor } from './modals/error-modal/error-handler.service';
import { components, componentServices, directives, modalComponents, modals, pipes } from './app-module-imports';
import { AngularMaterialModuleImports } from './app-material-module-imports';

@NgModule({
  declarations: [
    ...pipes,
    ...components,
    ...directives,
    ...modalComponents
  ],
  entryComponents: [
    LoadingOverlayComponent,
    ...modalComponents
    ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ...AngularMaterialModuleImports,
    RouterModule.forRoot(
      appRoutes
    ),
  ],
  providers: [
    AppInfoService,
    ApiService,
    AppMonitoringService,
    SettingsService,
    CurrentUserService,
    ErrorHandlerService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    RoleAuthGuard,
    SearchHelper,
    FormControlHelper,
    ...modals,
    ...componentServices
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
