<div [style.display]="CollapseSearch ? 'block' : 'none'" class="collapsed-container">
  <button mat-mini-fab class="expand-collapse-btn" matTooltip="Expand Search Criteria" (click)="ToggleSearchSection()">
    <mat-icon>unfold_more</mat-icon>
  </button>
</div>
<div class="container-fluid">
  <div class="page-header">
    <ng-content select="[page-controls]"></ng-content>

    <h3 [style.margin-left]="CollapseSearch ? '50px' : '0px'">{{PageTitle}}</h3>
  </div>
  <div class="row">
    <div class="col-md-3" [style.display]="CollapseSearch ? 'none' : 'block'">
      <filter-card [SearchHistoryType]="Options.SearchHistoryType" [(selectedFilters)]="selectedFilters" [filterCategories]="filters"
                   (OnCollapseSearchToggled)="ToggleSearchSection()"
                   (OnSearchClicked)="OnSearchButtonClick()"></filter-card>
    </div>
    <div [ngClass]="{'col-md-12': CollapseSearch, 'col-md-9': !CollapseSearch}">
      <div class="mat-elevation-z8">
        <div class="data-table-container">
          <ng-content select="[results-grid]"></ng-content>

          <div class="mat-spinner" *ngIf="isLoading">
            <mat-spinner></mat-spinner>
          </div>

          <div *ngIf="!isLoading && (data == null || data.length == 0)" style="text-align: center; padding-top: 30px;">
            <em>No results found. Use the Search Criteria box above to perform a new search.</em>
          </div>
        </div>

        <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageIndex" (page)="onPageChanged($event)" [length]="totalCount"></mat-paginator>
      </div>
    </div>
  </div>
</div>
