import { VendorSearchParameters } from './types/VendorSearchParameters';
import { Observable } from 'rxjs';
import { VendorSearchRow } from './types/VendorSearchRow';
import { Inject, Injectable } from '@angular/core';
import { VendorSearchDropdowns } from './types/VendorSearchDropdowns';
import { ApiService } from '../../shared/ApiService';
import { PagedResults } from '../../shared/interfaces/PagedResults';

@Injectable()
export class VendorSearchService {
  constructor(@Inject(ApiService) private apiService: ApiService) {
  }

  public SearchVendors = (searchParameters: VendorSearchParameters): Observable<PagedResults<VendorSearchRow>> => {
    return this.apiService.POST<VendorSearchParameters, PagedResults<VendorSearchRow>>(searchParameters, "api/v1/vendorsearch");
  }

  public GetDropdowns = (): Observable<VendorSearchDropdowns> => {
    return this.apiService.GET<VendorSearchDropdowns>("api/v1/vendor-search-meta");
  }

  public GetExcelDownloadUrl = (params: VendorSearchParameters): string => {
    var queryParams = this.apiService.convertToQueryUrl(params);

    //encodeURIComponent
    return this.apiService.GetApiUrl(`api/vendor/export?${queryParams}`);
  }
}
